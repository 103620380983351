import React from 'react';
import {Link} from 'react-router-dom';
import {Logo} from '../../Constants/Images';
import LoginForm from '../../Components/LoginForm';

function Login(props) {
  return (
    <div className="auth-page-wrapper">
      <div className="auth-page-logo text-center">
        <Link to="/">
          <img src={Logo} alt="" />
        </Link>
      </div>
      <div className="auth-page-container">
        <div className="auth-logo">
          {/* <img src={Logo} alt="" /> */}
          <h3>Enter Phone Number</h3>
        </div>

        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
