import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row, Form, Button, Alert } from "react-bootstrap";

import { updateRegion } from "../../../Store/Actions/CauseActions-Admin";
import { getAllCategories } from "../../../Store/Actions/FrontActions";
import { appendFormData } from "../../../Utils/Helpers";

class EditRegion extends Component {
    state = {
        data: {
            name: "",
            category_data: {},
            isUpdating: true,
        },
        categories: {},
        errors: {},
        saving: false,
        alertData: {
            show: false,
            type: "",
            message: "",
        },
    };

    UNSAFE_componentWillMount = () => {
        const { show, toggleModal, categories } = this.props;

        this.props.getAllCategories();

        const regData = { ...this.props.region };
        regData.category_data = regData.category_data ? JSON.parse(regData.category_data) : "";

        this.setState({
            show,
            toggleModal,
            categories,
            data: regData,
        });
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        const { categories } = props;

        this.setState({ categories });
    };

    onChange = (e) => {
        const data = { ...this.state.data };
        data[e.target.name] = e.target.value;

        this.setState({
            data,
        });
    };

    onCateChange = (e) => {
        const { data } = this.state;
        const category_data = { ...data.category_data };

        category_data[`${e.target.name}`] = e.target.value;

        this.setState({
            data: {
                ...this.state.data,
                category_data,
            },
        });
    };

    validateData = () => {
        const { data, categories } = this.state;
        const errors = {};

        if (!data.name || data.name === "") {
            errors.name = "Please enter Name.";
        }

        const cateCount = Object.entries(categories).length;

        let percentageCount = 0;

        if (cateCount > 0) {
            for (let i = 0; i < cateCount; i++) {
                if (
                    !data.category_data[`cate_${categories[i]["id"]}`] ||
                    data.category_data[`cate_${categories[i]["id"]}`] === ""
                ) {
                    errors[`cate_${categories[i]["id"]}`] = `Please enter percentage for ${categories[i]["name"]}`;
                } else {
                    percentageCount = percentageCount + parseInt(data.category_data[`cate_${categories[i]["id"]}`]);
                }
            }
        }

        if (percentageCount !== 100) {
            errors[`percentage_count`] = "Sum of the all category should equal to 100.";
        }

        console.log("Errors :", errors);

        return errors;
    };

    saveRegion = () => {
        const { data } = this.state;
        const errors = this.validateData();

        if (Object.entries(errors).length === 0) {
            this.setState({ errors, saving: true });

            const formData = appendFormData(data);
            formData.set("category_data", JSON.stringify(data.category_data));

            this.props
                .updateRegion(formData)
                .then((res) => {
                    const regData = { ...res.region };
                    regData.category_data = JSON.parse(regData.category_data);

                    this.setState({
                        data: regData,
                        saving: false,
                        alertData: {
                            show: true,
                            type: res.type === "error" ? "danger" : res.type,
                            message: res.message,
                        },
                    });
                })
                .catch((err) => {
                    this.setState({
                        saving: false,
                        alertData: {
                            show: true,
                            type: "danger",
                            message: err.message,
                        },
                    });
                    console.log(err);
                });
        } else {
            this.setState({
                errors,
            });
        }
    };

    hideErrMsg = () => {
        this.setState({
            alertData: {
                show: false,
                type: "",
                message: "",
            },
        });
    };

    render() {
        const { data, errors, show, toggleModal, alertData, saving, categories } = this.state;

        return (
            <Modal className="medium" show={show} onHide={() => toggleModal("edit_region_modal", false)}>
                <Modal.Header>
                    <Modal.Title>Edit Region</Modal.Title>
                    <span
                        className="close as-link"
                        onClick={() => {
                            toggleModal("edit_region_modal", false);
                        }}
                    >
                        x
                    </span>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    {alertData && alertData.show && (
                        <Alert variant={alertData.type} onClose={() => this.hideErrMsg()} dismissible>
                            {alertData.message}
                        </Alert>
                    )}
                    <div className="modal-form-wrapper">
                        <Row>
                            <Col>
                                <Form.Group className="form-group" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        placeholder=""
                                        value={data.name}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {errors.name && <span className="errorMsg">{errors.name}</span>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="form-group mb-2 mt-2" controlId="name">
                            <Form.Label>Percentages to Share</Form.Label>
                        </Form.Group>

                        {Object.entries(categories).length > 0 && (
                            <Row>
                                {categories.map((item, index) => (
                                    <Col md={6} key={`region_category_${item.id}`}>
                                        <Form.Group className="form-group" controlId="name">
                                            <Form.Label>{item.name}</Form.Label>
                                            <Form.Control
                                                name={`cate_${item.id}`}
                                                type="text"
                                                placeholder="eg. 10"
                                                value={
                                                    data.category_data && data.category_data[`cate_${item.id}`]
                                                        ? data.category_data[`cate_${item.id}`]
                                                        : ""
                                                }
                                                onChange={(e) => this.onCateChange(e)}
                                            />
                                            {errors[`cate_${item.id}`] && (
                                                <span className="errorMsg">{errors[`cate_${item.id}`]}</span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                ))}
                            </Row>
                        )}

                        {errors.percentage_count && <span className="errorMsg">{errors.percentage_count}</span>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-start pt-2">
                    <Button
                        onClick={() => {
                            if (!saving) {
                                this.saveRegion();
                            }
                        }}
                    >
                        {!saving && "Save"}
                        {saving && <i className="fas fa-spinner fa-spin" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateProps = (state) => ({
    categories: state.allCauseCategories,
});

export default connect(mapStateProps, { updateRegion, getAllCategories })(EditRegion);
