/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';

import {
  Breadcrumb,
  AddCauseCategory,
  EditCauseCategory,
} from '../../Components';
import {
  getCategories,
  removeCategory,
} from '../../Store/Actions/CauseActions-Admin';
import {updateUrl} from '../../Utils/Helpers';

const CauseCategories = props => {
  const page = props.match.params.page ? props.match.params.page : 1;
  const {categories, getCategories, removeCategory} = props;

  const [states, setState] = useState({
    bulk_action_id: [],
    listLoading: false,
    alertData: {},
  });

  const [categoryList, setCategories] = useState([]);

  const toggleModal = (state, value) => {
    setState({
      ...states,
      [state]: value,
    });
  };

  const hideErrMsg = () => {
    setState({
      ...states,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  const fetchCategories = page => {
    setState({
      ...states,
      listLoading: true,
    });

    getCategories(page)
      .then(res => {
        const newState = {
          ...states,
          listLoading: false,
        };
        if (res.type === 'success') {
          const newUrl =
            parseInt(res.pagging.current) === 1
              ? `/admin/categories`
              : `/admin/categories/page/${page}`;

          updateUrl(newUrl, props.history);
          newState.pagging = res.pagging;
          newState.page = res.pagging.current;
        }
        setState(newState);
      })
      .catch(err => {
        console.log(err);
        setState({
          ...states,
          listLoading: false,
          alertData: {
            show: false,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const deleteCategory = id => {
    const formData = new FormData();
    formData.append('id', id);
    removeCategory(formData)
      .then(res => {
        setState({
          ...states,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        setState({
          ...states,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const toggleAllBulk = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      categories.forEach(item => {
        if (bulk_action_id.indexOf(`${item.id}`) === -1) {
          bulk_action_id.push(`${item.id}`);
        }
      });
    } else {
      bulk_action_id = [];
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };
  const toggleBulkItems = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      if (bulk_action_id.indexOf(`${e.target.value}`) === -1) {
        bulk_action_id.push(`${e.target.value}`);
      }
    } else {
      bulk_action_id = bulk_action_id.filter(
        (item, index) => `${item}` !== `${e.target.value}`,
      );
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const enableEditModal = category => {
    setState({
      ...states,
      edit_category_modal: true,
      edit_category: category,
    });
  };

  const _renderItems = category => {
    return (
      <tr key={`categorys_key_${category.id}`}>
        <td align="center">
          {/* <input
            type="checkbox"
            name="bulk_action[]"
            value={category.id}
            checked={
              bulk_action_id.indexOf(`${category.id}`) !== -1 ? true : false
            }
            onChange={e => toggleBulkItems(e)}
          /> */}
          &nbsp;
        </td>
        <td>{category.name}</td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span
              className="actionIcon"
              onClick={() => enableEditModal(category)}>
              <i className="fas fa-pen" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => deleteCategory(category.id)}>
              <i className="fas fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (page) {
      fetchCategories(page);
    }
  }, [page]);

  useEffect(() => {
    if (Object.entries(categories).length > 0) {
      setCategories(categories);
    } else {
      setCategories([]);
    }
  }, [categories]);

  const {
    add_category_modal,
    edit_category_modal,
    edit_category,
    bulk_action_id,
    pagging,
    alertData,
    listLoading,
  } = states;

  return (
    <div className="admin-body">
      <div className="container">
        {/* <Breadcrumb title="Cause Categories" /> */}

        <div className="body-card">
          <div className="heading">
            <h2>Categories</h2>
            <div className="filter-options-wrapper">
              <span
                className="btn btn-primary btn-small add-new-btn"
                onClick={() => {
                  toggleModal('add_category_modal', true);
                }}>
                Add New
              </span>
            </div>
          </div>

          <div className="body-content">
            <div className="listing-tables mt-0">
              {alertData && alertData.show && (
                <div className="alerts">
                  <Alert
                    variant={alertData.type}
                    onClose={() => hideErrMsg()}
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}
              <table className="table table-inverse table-responsive">
                <thead className="thead-inverse">
                  <tr>
                    <th width="10" className="text-center">
                      {/* <input
                        type="checkbox"
                        id="chkAll"
                        onChange={e => {
                          toggleAllBulk(e);
                        }}
                      /> */}
                      &nbsp;
                    </th>
                    <th>Name</th>

                    {/* <th width="150">Date</th> */}
                    <th width="130" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listLoading && (
                    <tr>
                      <td align="center" colSpan={3}>
                        <span className="fas fa-spinner fa-spin" />
                      </td>
                    </tr>
                  )}
                  {!listLoading &&
                    Object.entries(categoryList).length === 0 && (
                      <tr>
                        <td align="center" colSpan={3}>
                          No Categories Available.
                        </td>
                      </tr>
                    )}
                  {!listLoading &&
                    Object.entries(categoryList).length > 0 &&
                    categoryList.map(category => _renderItems(category))}
                </tbody>
              </table>

              {pagging && (
                <div className="pagging-wrapper">
                  {pagging.current && pagging.pages && pagging.totalItems && (
                    <div className="pagging-text">
                      <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                    </div>
                  )}

                  <div className="pagging-btns">
                    {pagging.previous && (
                      <span
                        className="btn btn-small"
                        onClick={() => fetchCategories(pagging.previous)}>
                        Previous
                      </span>
                    )}
                    {pagging.next && (
                      <span
                        className="btn btn-small next-btn"
                        onClick={() => fetchCategories(pagging.next)}>
                        Next
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>

            {add_category_modal && (
              <AddCauseCategory
                show={add_category_modal}
                toggleModal={(modal, modalState) =>
                  toggleModal(modal, modalState)
                }
              />
            )}
            {edit_category_modal && (
              <EditCauseCategory
                show={edit_category_modal}
                user={edit_category}
                toggleModal={(modal, modalState) =>
                  toggleModal(modal, modalState)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  categories: state.causeCategory,
});

export default connect(mapStateProps, {getCategories, removeCategory})(
  CauseCategories,
);
