/* eslint-disable no-useless-computed-key */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Col,
  Modal,
  Row,
  Form,
  Button,
  Alert,
  FormControl,
} from 'react-bootstrap';

import {SocialIcons} from '../../Components';

import {
  getAllCategories,
  getAllRegions,
} from '../../Store/Actions/FrontActions';
import {updateAdminCause} from '../../Store/Actions/CauseActions-Admin';
import {updateUserCause} from '../../Store/Actions/FrontActions';
import {appendFormData} from '../../Utils/Helpers';
import {DefaultVideo} from '../../Constants/Images';

const cuaseStatusArray = ['Approved', 'Inactive', 'Unapproved'];

class EditCause extends Component {
  state = {
    data: {},
    errors: {},
    saving: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    show: false,
    categories: {},
  };

  UNSAFE_componentWillMount = () => {
    const {show, toggleModal, categories, regions, isAdmin, usStates} =
      this.props;

    this.getCategories();

    this.setState(
      {
        show,
        toggleModal,
        categories,
        regions,
        usStates,
      },
      () => {
        let tempCause = {...this.props.cause};
        tempCause = this.filterCause(tempCause);
        tempCause.isAdmin = isAdmin ? isAdmin : false;

        this.setState({
          data: tempCause,
        });
      },
    );
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {categories, regions, usStates} = props;

    this.setState({
      categories,
      regions,
      usStates,
    });
  };

  filterCause = cause => {
    const regionName = this.getRegion(cause.state);

    cause.region = regionName.region.name;
    cause.socials = cause.socials ? JSON.parse(cause.socials) : cause.socials;
    cause.category = cause.category.id;

    return cause;
  };

  getCategories = () => {
    this.props
      .getAllCategories()
      .then(res => {})
      .catch(err => {
        console.log('Err Res', err);
      });
  };

  onChange = e => {
    const tempdata = {...this.state.data};
    tempdata[e.target.name] = e.target.value;

    this.setState({
      data: tempdata,
    });
  };

  onStateChange = e => {
    const {data} = this.state;
    const value = e.target.value;

    const stateData = this.getRegion(value);
    const region = stateData.region.name;

    this.setState({
      data: {
        ...data,
        ['state']: value,
        ['region']: region,
      },
    });
  };

  getRegion = stateId => {
    const {usStates} = this.state;

    const state = usStates.filter(item => item.id === stateId);

    return state ? state[0] : {};
  };

  setSocials = socials => {
    this.setState({
      data: {
        ...this.state.data,
        socials,
      },
    });
  };

  validateData = () => {
    const {data} = this.state;
    const errors = {};

    if (!data.title || data.title === '') {
      errors.title = 'Please enter title.';
    }
    if (!data.category || data.category === '') {
      errors.category = 'Please select Category.';
    }
    if (!data.state || data.state === '') {
      errors.state = 'Please select State.';
    }
    // if (!data.justify_cause || data.justify_cause === '') {
    //   errors.justify_cause = 'Please justify your cause.';
    // }
    if (!data.explain_impact || data.explain_impact === '') {
      errors.explain_impact = 'Please explain the impact.';
    }
    if (!data.fund_amount || data.fund_amount === '') {
      errors.fund_amount = 'Please enter the amount.';
    }

    return errors;
  };

  saveCuase = () => {
    const {data} = this.state;
    const errors = this.validateData();

    if (Object.entries(errors).length === 0) {
      this.setState({errors, saving: true});
      const formData = appendFormData(data);
      formData.append('socials', JSON.stringify(data.socials));

      if (!data.isAdmin) {
        this.props
          .updateUserCause(formData)
          .then(res => {
            const tempCause = this.filterCause(res.cause);

            this.setState({
              saving: false,
              data: tempCause,
              alertData: {
                show: true,
                type: res.type === 'error' ? 'danger' : res.type,
                message: res.message,
              },
            });
          })
          .catch(err => {
            this.setState({
              saving: false,
              alertData: {
                show: true,
                type: 'danger',
                message: err.message,
              },
            });
          });
      } else {
        this.adminCauseUpdate(formData);
      }
    } else {
      this.setState({
        errors,
      });
    }
  };

  adminCauseUpdate = formData => {
    // const { data } = this.state;
    this.props
      .updateAdminCause(formData)
      .then(res => {
        const tempCause = this.filterCause(res.cause);

        this.setState({
          saving: false,
          data: tempCause,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        this.setState({
          saving: false,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });

    this.props.closeModal();
  };

  onCoverPick = e => {
    const filePath = e.target.files[0];
    const objectUrl = URL.createObjectURL(filePath);

    const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];

    if (imgTypes.includes(filePath.type)) {
      console.log(filePath.size);
      if (filePath.size <= 1000000) {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: filePath,
            cover_img: objectUrl,
          },
          errors: {
            ...this.state.errors,
            cover_img_file: '',
          },
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            cover_img_file: 'Image size must be less then 1MB.',
          },
        });
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          cover_img_file: 'Please select valid Image.',
        },
      });
    }
  };
  removeGalleryImg = () => {
    this.setState({
      data: {
        ...this.state.data,
        cover_img_file: '',
        remove_cover: 'yes',
        cover_img: '',
      },
    });
  };

  onMediaPick = e => {
    const filePath = e.target.files[0];
    console.log('File :', filePath);

    const mediaTypes = ['video/mp4'];

    if (mediaTypes.includes(filePath.type)) {
      if (filePath.size <= 5000000) {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: filePath,
            media_name: filePath.name,
          },
          errors: {
            ...this.state.errors,
            media_file: '',
          },
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            media_file: 'Video size must be less then 5MB.',
          },
        });
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          media_file: 'Please select valid Video File.',
        },
      });
    }
  };
  removeMedia = () => {
    this.setState({
      data: {
        ...this.state.data,
        media_file: '',
        remove_media: 'yes',
        media_name: '',
      },
    });
  };

  videoThumb = videoFile => {
    let thumbUrl = '';

    if (
      videoFile &&
      videoFile.thumbnails &&
      Object.entries(videoFile.thumbnails).length > 0
    ) {
      const thumbnails = videoFile.thumbnails;
      if (thumbnails && thumbnails.high && thumbnails.high.url !== '') {
        thumbUrl = thumbnails.high.url;
      } else if (
        thumbnails &&
        thumbnails.medium &&
        thumbnails.medium.url !== ''
      ) {
        thumbUrl = thumbnails.medium.url;
      } else if (
        thumbnails &&
        thumbnails.default &&
        thumbnails.default.url !== ''
      ) {
        thumbUrl = thumbnails.default.url;
      } else {
        thumbUrl = DefaultVideo;
      }
    } else {
      thumbUrl = DefaultVideo;
    }

    return thumbUrl;
  };

  render() {
    const {data, errors, alertData, saving, show, categories, usStates} =
      this.state;

    return (
      <Modal className="medium" show={show} onHide={() => this.closeModal()}>
        <Modal.Header>
          <Modal.Title>Update Nomination</Modal.Title>
          <span className="close as-link" onClick={() => this.closeModal()}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            {alertData && alertData.show && (
              <Alert
                variant={alertData.type}
                onClose={() => this.hideErrMsg()}
                dismissible>
                {alertData.message}
              </Alert>
            )}

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder=""
                    value={data.title ? data.title : ''}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.title && (
                    <span className="errorMsg">{errors.title}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row></Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Recipient</Form.Label>
                  <Form.Control
                    name="reciever_name_org"
                    type="text"
                    placeholder="Organization or person's name"
                    value={data.reciever_name_org ? data.reciever_name_org : ''}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.reciever_name_org && (
                    <span className="errorMsg">{errors.reciever_name_org}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Recipient Email</Form.Label>
                  <Form.Control
                    name="reciever_email"
                    type="text"
                    placeholder="Email"
                    value={data.reciever_email ? data.reciever_email : ''}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.reciever_email && (
                    <span className="errorMsg">{errors.reciever_email}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="category">
                  <label>Category</label>
                  <div className="select-dropdown">
                    <select
                      name="category"
                      value={data.category ? data.category : ''}
                      onChange={e => this.onChange(e)}
                      className="form-control">
                      <option value="">Choose</option>
                      {Object.entries(categories).length > 0 &&
                        categories.map((item, index) => (
                          <option
                            value={item.id}
                            key={`category_index_${index}`}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.category && (
                    <span className="errorMsg">{errors.category}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>State</label>

                  <div className="select-dropdown">
                    <select
                      name="state"
                      value={data.state ? data.state : ''}
                      onChange={e => this.onStateChange(e)}
                      className="form-control">
                      <option value="">Choose</option>
                      {Object.entries(usStates).length > 0 &&
                        usStates.map((item, index) => (
                          <option value={item.id} key={`state_index_${index}`}>
                            {item.name}
                          </option>
                        ))}
                    </select>

                    <Form.Control
                      name="region"
                      type="hidden"
                      placeholder=""
                      value={data.region ? data.region : ''}
                    />
                  </div>
                  {errors.state && (
                    <span className="errorMsg">{errors.state}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>
                    How does this align with our Foundations’ mission?
                  </label>
                  <FormControl
                    name="justify_cause"
                    as="textarea"
                    row="5"
                    value={data.justify_cause}
                    onChange={e => this.onChange(e)}
                  />

                  {errors.justify_cause && (
                    <span className="errorMsg">{errors.justify_cause}</span>
                  )}
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>Share Details</label>
                  <FormControl
                    name="explain_impact"
                    as="textarea"
                    row="5"
                    value={data.explain_impact}
                    onChange={e => this.onChange(e)}
                  />

                  {errors.explain_impact && (
                    <span className="errorMsg">{errors.explain_impact}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>Funding Goal</label>
                  <div className="inputWithIcon">
                    <i className="fas fa-dollar-sign" />
                    <Form.Control
                      name="fund_amount"
                      type="text"
                      placeholder=""
                      value={data.fund_amount}
                      onChange={e => this.onChange(e)}
                    />
                  </div>
                  {errors.fund_amount && (
                    <span className="errorMsg">{errors.fund_amount}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>Delivery Instructions</label>
                  <FormControl
                    name="transfer_info"
                    as="textarea"
                    row="5"
                    placeholder="How should funds be delivered?"
                    value={data.transfer_info}
                    onChange={e => this.onChange(e)}
                  />

                  {errors.transfer_info && (
                    <span className="errorMsg">{errors.transfer_info}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  {/* <label>Add Media (optional)</label> */}
                  <Form.Group className="form-group" controlId="state">
                    <Row className="mt-1">
                      <Col>
                        <div className="file-field-wrapper">
                          <span className="btn btn-primary">
                            <i className="fas fa-camera" /> Add Cover
                            <input
                              type="file"
                              name="cover_img_file"
                              onChange={e => {
                                this.onCoverPick(e);
                              }}
                            />
                          </span>
                        </div>
                        {data.cover_img && (
                          <div className="fileSelected">
                            <div className="">
                              <span
                                className="remove_img"
                                onClick={() => this.removeGalleryImg()}>
                                <i className="fas fa-times" />
                              </span>
                              <img src={data.cover_img} alt="" />
                            </div>
                          </div>
                        )}
                        {errors.cover_img_file && (
                          <span className="errorMsg">
                            {errors.cover_img_file}
                          </span>
                        )}
                      </Col>
                      <Col>
                        <span className="btn btn-primary file-field-wrapper">
                          <i className="fas fa-video" /> Add Media
                          <input
                            type="file"
                            name="media_file"
                            onChange={e => {
                              this.onMediaPick(e);
                            }}
                          />
                        </span>
                        {errors.media_file && (
                          <span className="errorMsg">{errors.media_file}</span>
                        )}
                        {console.log('data.media_file :', data.media_file)}
                        {data.media_file && (
                          <div className="fileSelected">
                            <div className="">
                              <span
                                className="remove_img"
                                onClick={() => this.removeMedia()}>
                                <i className="fas fa-times" />
                              </span>
                              <img
                                src={this.videoThumb(data.media_file)}
                                alt=""
                              />
                              <span className="fileSelected">
                                {data.media_file.name}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="socials">
                  <label>How can people learn more about your cause?</label>
                  <SocialIcons
                    icons={data.socials}
                    updateIcons={icons => this.setSocials(icons)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {data.isAdmin && (
              <Row>
                <Col>
                  <Form.Group className="form-group" controlId="socials">
                    <label>Status</label>
                    <div className="select-dropdown">
                      <select
                        name="status"
                        value={data.status ? data.status : ''}
                        onChange={e => this.onChange(e)}
                        className="form-control">
                        <option value="">Choose</option>
                        {Object.entries(cuaseStatusArray).length > 0 &&
                          cuaseStatusArray.map((item, index) => (
                            <option key={`state_index_${index}`}>{item}</option>
                          ))}
                      </select>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Button
                  onClick={() => {
                    if (!saving) {
                      this.saveCuase();
                    }
                  }}>
                  {!saving && 'Save'}
                  {saving && <i className="fas fa-spinner fa-spin" />}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        {/* <Modal.Footer className="justify-content-start">
                    <Button
                        onClick={() => {
                            if (!saving) {
                                this.saveUserDetails();
                            }
                        }}
                    >
                        {!saving && "Save"}
                        {saving && <i className="fas fa-spinner fa-spin" />}
                    </Button>
                </Modal.Footer> */}
      </Modal>
    );
  }
}

const mapStateProps = state => ({
  categories: state.allCauseCategories,
  regions: state.allCauseRegions,
  usStates: state.usStates,
});

export default connect(mapStateProps, {
  getAllCategories,
  getAllRegions,
  updateUserCause,
  updateAdminCause,
})(EditCause);
