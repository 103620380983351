import React, { Component } from "react";
import { Alert, Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { appendFormData } from "../../Utils/Helpers";
import { saveRatting } from "../../Store/Actions/CommanAction";
import { DefaultVideo } from "../../Constants/Images";

class AddTestimonials extends Component {
    state = {
        show: this.props.show,
        cause: {},
        loading: false,
        data: {
            review_files: [],
            title: "",
            review: "",
            user_id: "",
            cause_id: "",
            user_name: "",
            userImg: "",
        },
        alertData: {
            show: false,
            type: "",
            message: "",
        },
        errors: {},
        filesArray: [],
        userTempImg: "",
        isAdmin: false,
    };

    UNSAFE_componentWillMount = () => {
        const { currentUser, cause, isAdmin } = this.props;
        const user_id = currentUser ? currentUser.id : "";

        this.setState({
            isAdmin,
            cause,
            data: {
                ...this.state.data,
                user_id,
                title: cause ? cause.title : "",
                cause_id: cause ? cause.id : "",
            },
        });
    };
    UNSAFE_componentWillReceiveProps = (props) => {
        const { currentUser } = props;
        const user_id = currentUser ? currentUser.id : "";

        this.setState({
            data: {
                ...this.state.data,
                user_id,
            },
        });
    };

    closeModal = () => {
        this.props.closeModal();
    };
    ratingChanged = (num) => {
        this.setState({
            data: {
                ...this.state.data,
                ratting: num,
            },
        });
    };
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value,
            },
        });
    };

    validateData = () => {
        const errors = {};
        const { data } = this.state;

        if (!data.review || data.review === "") {
            errors.review = "Please share your thoughts.";
        }

        return errors;
    };
    saveRatting = (e) => {
        const { loading, userTempImg, isAdmin } = this.state;
        const tempData = { ...this.state.data };

        const { review_files, userImg } = tempData;
        const errors = this.validateData();

        delete tempData["review_files"];
        delete tempData["userImg"];

        if (!loading && Object.entries(errors).length === 0) {
            this.setState({
                loading: true,
                errors: {},
            });

            const adminUser = isAdmin ? "Yes" : "No";
            const formData = appendFormData(tempData);

            if (review_files.length > 0) {
                review_files.forEach((item) => {
                    formData.append("review_files[]", item);
                });
            }

            if (userTempImg !== "") {
                formData.append("user_img", userImg);
            }

            if (isAdmin) {
                formData.append("status", "Approved");
            }

            this.props
                .saveRatting(formData, adminUser)
                .then((res) => {
                    if (res.type === "success") {
                        if (!isAdmin) {
                            this.props.setAlert({
                                show: true,
                                type: res.type,
                                message: res.message,
                            });

                            this.props.updateNeedRattings(res.needRattings);
                        } else {
                            this.setState({
                                loading: false,
                                data: {
                                    review_files: [],
                                    review: "",
                                    user_id: "",
                                    cause_id: "",
                                    user_name: "",
                                    userImg: "",
                                },
                                filesArray: [],
                                userTempImg: "",
                                alertData: {
                                    show: true,
                                    type: res.type === "error" ? "danger" : res.type,
                                    message: res.message,
                                },
                            });
                        }
                    } else {
                        this.setState({
                            loading: false,
                            alertData: {
                                show: true,
                                type: res.type === "error" ? "danger" : res.type,
                                message: res.message,
                            },
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                        alertData: {
                            show: false,
                            type: "danger",
                            message: err.message,
                        },
                    });
                });
        } else {
            this.setState({
                errors,
            });
        }
    };
    hideErrMsg = () => {
        this.setState({
            alertData: {
                show: false,
                type: "",
                message: "",
            },
        });
    };

    addMoreFiles = (e) => {
        const review_files = [...this.state.data.review_files];
        const filesArray = [...this.state.filesArray];
        const imgTypes = ["image/gif", "image/jpeg", "image/png"];
        const allowedFileTypes = [...imgTypes, "video/mp4"];

        const allFiles = e.target.files;

        for (let i = 0; i < allFiles.length; i++) {
            const filePath = e.target.files[i];
            const objectUrl = URL.createObjectURL(filePath);

            if (allowedFileTypes.includes(filePath.type)) {
                review_files.push(filePath);
                const fileType = imgTypes.includes(filePath.type) ? "image" : "video";
                filesArray.push({
                    type: fileType,
                    url: objectUrl,
                });
            }
        }

        this.setState(
            {
                data: {
                    ...this.state.data,
                    review_files,
                },
                filesArray,
            },
            () => {
                console.log("Files", this.state, review_files, filesArray);
            }
        );
    };
    removeFile = (index) => {
        const { filesArray, data } = this.state;
        const review_files = [...data.review_files];

        filesArray.splice(index, 1);
        review_files.splice(index, 1);

        this.setState(
            {
                data: {
                    ...this.data,
                    review_files,
                },
                filesArray,
            },
            () => {
                console.log("New State :", this.state);
            }
        );
    };
    changeUserImg = (e) => {
        const userFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(userFile);

        this.setState({
            data: {
                ...this.state.data,
                userImg: userFile,
            },
            userTempImg: objectUrl,
        });
    };
    removeUserIMg = () => {
        const userImg = document.getElementById("userImg");

        userImg.value = "";

        this.setState({
            data: {
                ...this.state.data,
                userImg: "",
            },
            userTempImg: "",
        });
    };

    render() {
        const { show, cause, data, errors, alertData, loading, filesArray, isAdmin, userTempImg } = this.state;

        return (
            <Modal className="medium" show={show} onHide={() => this.closeModal()}>
                <Modal.Header>
                    <Modal.Title>Add Response</Modal.Title>
                    <span className="close as-link" onClick={() => this.closeModal()}>
                        x
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-ratting-wrapper">
                        {alertData && alertData.show && (
                            <div className="alerts">
                                <Alert variant={alertData.type} onClose={() => this.hideErrMsg()} dismissible>
                                    {alertData.message}
                                </Alert>
                            </div>
                        )}

                        {console.log("cause :", cause)}

                        <div className="heading">
                            <h2>{cause ? cause.title : ""}</h2>
                            <h3>
                                {cause.category && cause.category.name} {cause.region && `| ${cause.region.name}`}{" "}
                            </h3>
                            <p>{cause ? cause.explain_impact : ""}</p>
                        </div>
                        <Form>
                            <Form.Group className="form-group" controlId="">
                                <Form.Label>Images / Videos</Form.Label>

                                <div className="files-drop-area">
                                    <span className="">Select Or Drop Files</span>
                                    <input
                                        multiple
                                        type="file"
                                        name="files"
                                        className="form-control"
                                        onChange={(e) => this.addMoreFiles(e)}
                                    />
                                </div>
                            </Form.Group>

                            {Object.entries(filesArray).length > 0 && (
                                <div className="preview-wrapper">
                                    <Row>
                                        {filesArray.map((item, index) => {
                                            const fileUrl = item.type === "video" ? DefaultVideo : item.url;
                                            return (
                                                <Col md={3}>
                                                    <div className="fileSelected">
                                                        <div className="">
                                                            <span
                                                                className="remove_img"
                                                                onClick={() => this.removeFile(index)}
                                                            >
                                                                <i className="fas fa-times" />
                                                            </span>
                                                            <img src={fileUrl} alt="" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </div>
                            )}

                            {isAdmin && (
                                <>
                                    <Form.Group className="form-group" controlId="user_name">
                                        <Form.Label>Name of Person / Organization</Form.Label>
                                        <FormControl
                                            name="user_name"
                                            onChange={(e) => this.onChange(e)}
                                            value={data.user_name}
                                            placeholder=""
                                        />
                                        {errors.user_name && <span className="errorMsg">{errors.user_name}</span>}
                                    </Form.Group>
                                    <Form.Group className="form-group mb-0" controlId="review">
                                        <Form.Label>Image / Logo </Form.Label>
                                    </Form.Group>
                                    <Row className="align-items-center mb-3">
                                        <Col>
                                            <Form.Group className="form-group mb-0" controlId="userImg">
                                                <input
                                                    id="userImg"
                                                    type="file"
                                                    name="userImg"
                                                    className="form-control"
                                                    onChange={(e) => this.changeUserImg(e)}
                                                />
                                                {errors.userImg && <span className="errorMsg">{errors.userImg}</span>}
                                            </Form.Group>
                                        </Col>
                                        {userTempImg && (
                                            <Col md={2}>
                                                <Form.Group className="form-group mb-0">
                                                    <div className="fileSelected mt-0">
                                                        <div className="">
                                                            <span
                                                                className="remove_img"
                                                                onClick={() => this.removeUserIMg()}
                                                            >
                                                                <i className="fas fa-times" />
                                                            </span>
                                                            <img src={userTempImg} alt="" />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            )}

                            {/* <Form.Group className="form-group" controlId="title">
                                <Form.Label>Title </Form.Label>
                                <FormControl
                                    name="title"
                                    onChange={(e) => this.onChange(e)}
                                    value={data.title}
                                    placeholder="Title"
                                />
                                {errors.title && <span className="errorMsg">{errors.title}</span>}
                            </Form.Group> */}

                            <Form.Group className="form-group" controlId="review">
                                <Form.Label>Comment </Form.Label>
                                <FormControl
                                    rows={4}
                                    as="textarea"
                                    name="review"
                                    onChange={(e) => this.onChange(e)}
                                    value={data.review}
                                    placeholder="Share your thoughts..."
                                />
                                {errors.review && <span className="errorMsg">{errors.review}</span>}
                            </Form.Group>

                            <Form.Group className="form-group mb-0" controlId="review">
                                {loading && (
                                    <Button className="btn btn-primary btn-small">
                                        <i className="fas fa-spinner fa-spin" />
                                    </Button>
                                )}
                                {!loading && (
                                    <Button className="btn btn-primary btn-small" onClick={() => this.saveRatting()}>
                                        Save
                                    </Button>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateProps = (state) => ({
    currentUser: state.user,
});

export default connect(mapStateProps, { saveRatting })(AddTestimonials);
