/* eslint-disable no-useless-computed-key */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row, Form, Button, Alert } from "react-bootstrap";

import { saveUser } from "../../../Store/Actions/UsersAction";
import { appendFormData, validateEmail } from "../../../Utils/Helpers";
import PhoneInput from "react-phone-input-2";

class EditUser extends Component {
    state = {
        data: {
            name: "",
            email: "",
            phone: "",
            uset_type: "",
            state: "",
            region: "",
            status: "",
            isUpdating: true,
        },
        errors: {},
        saving: false,
        alertData: {
            show: false,
            type: "",
            message: "",
        },
        usStates: {},
    };

    UNSAFE_componentWillMount = () => {
        const { show, toggleModal, usStates } = this.props;

        console.log("User :", this.props.user);

        this.setState(
            {
                show,
                toggleModal,
                usStates,
            },
            () => {
                let user = { ...this.props.user };
                user = this.filterUser(user);
                this.setState({
                    data: user,
                });
            }
        );
    };

    filterUser = (user) => {
        const stateId = user.state;
        const regionName = this.getRegion(stateId);
        user.region = regionName.region.name;
        user.state = stateId;

        console.log("User :", user);

        return user;
    };

    onChange = (e) => {
        const data = { ...this.state.data };
        data[e.target.name] = e.target.value;

        this.setState({
            data,
        });
    };

    onStateChange = (e) => {
        const { data } = this.state;
        const value = e.target.value;

        const stateData = this.getRegion(value);
        const region = stateData.region.name;

        this.setState({
            data: {
                ...data,
                ["state"]: value,
                ["region"]: region,
            },
        });
    };

    getRegion = (stateId) => {
        const { usStates } = this.state;

        const state = usStates.filter((item) => item.id === stateId);

        return state ? state[0] : {};
    };

    setPhone = (phone) => {
        this.setState({
            data: {
                ...this.state.data,
                phone,
            },
        });
    };

    checkboxHandle = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.checked,
            },
        });
    };

    validateData = () => {
        const { data } = this.state;
        const errors = {};

        if (!data.name || data.name === "") {
            errors.name = "Please enter Name.";
        }
        if (!data.email || data.email === "") {
            errors.email = "Please enter email.";
        } else if (!validateEmail(data.email)) {
            errors.email = "Please enter valid email.";
        }

        if (!data.user_type || data.user_type === "") {
            errors.user_type = "Please select User role.";
        }

        if (!data.phone || data.phone === "") {
            errors.phone = "Please enter phone number.";
        }

        return errors;
    };

    saveUserDetails = () => {
        const { data } = this.state;
        const errors = this.validateData();

        if (Object.entries(errors).length === 0) {
            this.setState({ errors, saving: true });

            const formData = appendFormData(data);

            this.props
                .saveUser(formData)
                .then((res) => {
                    console.log(res);
                    this.setState({
                        data: {
                            ...res.user,
                        },
                        saving: false,
                        alertData: {
                            show: true,
                            type: res.type === "error" ? "danger" : res.type,
                            message: res.message,
                        },
                    });
                })
                .catch((err) => {
                    this.setState({
                        saving: false,
                        alertData: {
                            show: true,
                            type: "danger",
                            message: err.message,
                        },
                    });
                    console.log(err);
                });
        } else {
            this.setState({
                errors,
            });
        }
    };

    hideErrMsg = () => {
        this.setState({
            alertData: {
                show: false,
                type: "",
                message: "",
            },
        });
    };

    render() {
        const { data, errors, show, toggleModal, alertData, saving, usStates } = this.state;

        return (
            <Modal className="medium" show={show} onHide={() => toggleModal("edit_user_modal", false)}>
                <Modal.Header>
                    <Modal.Title>Edit User</Modal.Title>
                    <span
                        className="close as-link"
                        onClick={() => {
                            toggleModal("edit_user_modal", false);
                        }}
                    >
                        x
                    </span>
                </Modal.Header>
                <Modal.Body>
                    {alertData && alertData.show && (
                        <Alert variant={alertData.type} onClose={() => this.hideErrMsg()} dismissible>
                            {alertData.message}
                        </Alert>
                    )}
                    <div className="modal-form-wrapper">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="form-group" controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder=""
                                            value={data.name}
                                            onChange={(e) => this.onChange(e)}
                                        />
                                        {errors.name && <span className="errorMsg">{errors.name}</span>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="form-group" controlId="email">
                                        <label>Email</label>
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            placeholder=""
                                            value={data.email}
                                            onChange={(e) => this.onChange(e)}
                                        />
                                        {errors.email && <span className="errorMsg">{errors.email}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="form-group" controlId="phone">
                                        <label>Phone</label>
                                        <PhoneInput
                                            className="form-control"
                                            country={"in"}
                                            value={data.phone}
                                            onChange={(phone) => this.setPhone(phone)}
                                        />
                                        {errors.phone && <span className="errorMsg">{errors.phone}</span>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="form-group" controlId="role">
                                        <label>Role</label>
                                        <div className="select-dropdown">
                                            <select
                                                name="role"
                                                value={data.user_type}
                                                onChange={(e) => this.onChange(e)}
                                                className="form-control"
                                            >
                                                <option value="">Choose Role</option>
                                                <option>Administrator</option>
                                                <option>Subscriber</option>
                                            </select>
                                        </div>
                                        {errors.user_type && <span className="errorMsg">{errors.user_type}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="form-group" controlId="state">
                                        <label>Which state do you live in?</label>

                                        <div className="select-dropdown">
                                            <select
                                                name="state"
                                                value={data.state ? data.state : ""}
                                                onChange={(e) => this.onStateChange(e)}
                                                className="form-control"
                                            >
                                                <option value="">Choose</option>
                                                {Object.entries(usStates).length > 0 &&
                                                    usStates.map((item, index) => (
                                                        <option value={item.id} key={`state_index_${index}`}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        {errors.state && <span className="errorMsg">{errors.state}</span>}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="form-group" controlId="role">
                                        <label>Region</label>
                                        <Form.Control
                                            name="region"
                                            type="text"
                                            placeholder=""
                                            value={data.region ? data.region : ""}
                                            disabled
                                        />

                                        {errors.region && <span className="errorMsg">{errors.region}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="form-group" controlId="status">
                                        <label>Status</label>
                                        <div className="select-dropdown">
                                            <select
                                                name="status"
                                                value={data.status}
                                                onChange={(e) => this.onChange(e)}
                                                className="form-control"
                                            >
                                                <option value="">Choose Status</option>
                                                <option>Active</option>
                                                <option>Inactive</option>
                                                <option>Unapproved</option>
                                            </select>
                                        </div>
                                        {errors.status && <span className="errorMsg">{errors.status}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button
                        onClick={() => {
                            if (!saving) {
                                this.saveUserDetails();
                            }
                        }}
                    >
                        {!saving && "Save"}
                        {saving && <i className="fas fa-spinner fa-spin" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateProps = (state) => ({
    usStates: state.usStates,
});

export default connect(mapStateProps, { saveUser })(EditUser);
