import React from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import moment from 'moment';
import MomentDate from 'react-moment';

const DetailsSubscription = props => {
  const {show, data, cancelSubscription} = props;

  // console.log("Data :", data);

  const closeModal = () => {
    props.closeModal();
  };

  const startTime = moment.utc(parseInt(data.start_date) * 1000);
  const endTime = moment.utc(parseInt(data.end_date) * 1000);

  return (
    <Modal className="medium" show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>Contribution Details</Modal.Title>
        <span className="close as-link" onClick={() => closeModal()}>
          x
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="subdesc-wrapper">
          <Row>
            <Col md={6}>
              <p>
                <span>Region - </span> {data.region.name}
              </p>
            </Col>
            <Col md={6}>
              <p>
                <span>Amount - </span> ${data.amount}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>
                <span>Contribution Type - </span> {data.contribution_type}
              </p>
            </Col>
            <Col md={6}>
              {data.pledge_type && (
                <p>
                  <span>Pledge Type - </span> {data.pledge_type}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>
                <span>Start From - </span>{' '}
                <MomentDate date={startTime} format="DD/MM/YYYY" />
              </p>
            </Col>
            <Col md={6}>
              <p>
                <span>Expired At - </span>{' '}
                <MomentDate date={endTime} format="DD/MM/YYYY" />
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>
                <span>Status - </span> {data.status}
              </p>
            </Col>
          </Row>

          {data.contribution_type !== 'One-Time' && (
            <Row className="mt-2">
              <Col md={6}>
                {data.status === 'Active' && (
                  <span
                    className="btn btn-small btn-danger"
                    onClick={() => cancelSubscription(data.id)}>
                    Cancel Subscription
                  </span>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DetailsSubscription;
