/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../Assets/NewHome/css/style.css";
import "../../Assets/NewHome/css/responsive.css";

import {
    Logo,
    girlImg,
    PhoneImg,
    christinaImg,
    processIcon1,
    processIcon2,
    processIcon3,
    sideImg,
    laptopMobile,
} from "../../Constants/Images";

import { faqs } from "../../Constants";
import { getTestimonials } from "../../Store/Actions/FrontActions";

import { TestimonialSlider } from "../../Components";

class Home extends Component {
    state = {
        testimonials: [],
        menuOpen: false,
        currentUser: {},
    };

    UNSAFE_componentWillMount = () => {
        const { currentUser } = this.props;

        this.setState({
            currentUser,
        });

        this.props
            .getTestimonials()
            .then((res) => {
                const { testimonials } = res;
                this.setState({ testimonials });
            })
            .catch((err) => {
                console.log("Err :", err);
            });
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        const { currentUser } = props;

        this.setState({
            currentUser,
        });
    };

    mobileToggle = (menuState) => {
        this.setState({
            menuOpen: menuState,
        });
    };

    render() {
        const { testimonials, menuOpen, currentUser } = this.state;
        const menuClass = menuOpen ? "open" : "";

        const regsisterLink = currentUser && !currentUser.authToken ? "/register" : "/causes";

        return (
            <>
                {/* Header */}
                <div className="site-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={Logo} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className={`navigation-wrapper ${menuClass}`}>
                                    <div className="hambergar" onClick={() => this.mobileToggle(!menuOpen)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="navigation">
                                        <div className="top-menu-wrapper">
                                            <div className="login-links">
                                                <ul>
                                                    {currentUser && !currentUser.authToken && (
                                                        <li>
                                                            <Link className="btn btn-small btn-outline" to="/login">
                                                                Login
                                                            </Link>
                                                        </li>
                                                    )}
                                                    {currentUser && currentUser.authToken && (
                                                        <li>
                                                            <Link className="btn btn-small btn-outline" to="/causes">
                                                                Dashboard
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="social-icons text-right">
                                                <ul>
                                                    <li className="social">
                                                        <a href="#" target="_blank">
                                                            <i className="fab fa-linkedin-in"></i>
                                                        </a>
                                                    </li>
                                                    <li className="social">
                                                        <a href="https://instagram.com/trying.to.build" target="_blank">
                                                            <i className="fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="#home">Home</a>
                                            </li>
                                            <li>
                                                <a href="#about-us">About</a>
                                            </li>
                                            <li>
                                                <a href="#how-it-works">How It Works</a>
                                            </li>
                                            <li>
                                                <a href="#faq">FAQs</a>
                                            </li>
                                            <li>
                                                <Link to={regsisterLink}>Become A Pledger</Link>
                                            </li>
                                            {/* <li><a href="#" className="one-time-donation">Make One-Time Donation</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Banner */}
                <div id="home" className="banner-wrapper">
                    <div className="container">
                        <div className="banner-content">
                            <h2>
                                <span>Give As One</span>
                                The transparent path to impact
                            </h2>
                            <Link to="/register" className="btn btn-primary btn-outline">
                                Become a Pledger
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="about-wrapper">
                    <div className="container">
                        <div className="about-content">
                            <h2>
                                BUILD Foundation is a 501c(3) nonprofit organization that distributes monthly grants to
                                dreams, hardships and community causes.
                            </h2>
                        </div>
                    </div>
                </div>

                {testimonials && Object.entries(testimonials).length > 0 && (
                    <div className="testimonial-wrapper">
                        <div className="container">
                            <div className="headings text-center">
                                <h2>Recent Impact</h2>
                                <p>When we come together as one, our donations can truly change lives.</p>
                            </div>
                            <div className="testimonial-slider">
                                <TestimonialSlider testimonials={testimonials} />
                            </div>
                        </div>
                    </div>
                )}

                <div id="about-us" className="mobile-content-wrapper">
                    <div className="container">
                        {/* <div className="headings">
                            <h2>Pledge And Join Our Community Of Builders</h2>
                        </div> */}
                        <div className="row align-items-center reverse-column">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-images">
                                    <div className="rounded-img">
                                        <img src={girlImg} alt="" />
                                    </div>

                                    {/* <div className="app-store-links">
                                        <span className="coming-soon-text">Coming Soon!</span>
                                        <a href="#"><img src="assets/images/playstore.png" alt="" /></a>
                                        <a href="#"><img src="assets/images/apple-store.png" alt="" /></a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-content">
                                    <h2>Small Contributions Matter</h2>
                                    <p>
                                        When you become a member of our Foundation and pledge as low as $5 monthly or
                                        $60 annually, your impact is felt immediately. The following month your funds
                                        will be added to the regional pot to assist with causes.
                                    </p>
                                    <div className="content-btn">
                                        <Link to="/register" className="btn btn-primary btn-dark-outline black-hover">
                                            Become A Pledger
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-content-wrapper no-bg-img">
                    <div className="container">
                        {/* <div className="headings">
                            <h2>Pledge And Join Our Community Of Builders</h2>
                        </div> */}
                        <div className="row align-items-center">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-images">
                                    <img src={PhoneImg} alt="" />

                                    {/* <div className="app-store-links">
                                        <span className="coming-soon-text">Coming Soon!</span>
                                        <a href="#"><img src="assets/images/playstore.png" alt="" /></a>
                                        <a href="#"><img src="assets/images/apple-store.png" alt="" /></a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-content">
                                    <h2>You Determine Allocation of Funds</h2>
                                    <p>
                                        When you pledge, you gain voting rights. Each month you have the power to
                                        nominate causes, vote and track our organizations’ management of your funds.
                                    </p>
                                    <div className="content-btn">
                                        <Link to="/register" className="btn btn-primary btn-dark-outline black-hover">
                                            Become A Pledger
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-content-wrapper no-bg-img">
                    <div className="container">
                        <div className="row align-items-center reverse-column">
                            <div className="col-md-6 col-sm-6 col-xs-12 text-right">
                                <div className="mobile-images">
                                    <div className="rounded-img">
                                        <img src={christinaImg} alt="" />
                                    </div>
                                    {/* <div className="app-store-links left-side">
                                        <span className="coming-soon-text">Coming Soon!</span>
                                        <a href="#"><img src="assets/images/playstore.png" alt="" /></a>
                                        <a href="#"><img src="assets/images/apple-store.png" alt="" /></a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-content">
                                    <h2>Monthly Cause Categories</h2>
                                    <h3>Dreamers Fund</h3>
                                    <p>Financial support for entrepreneurs to execute on ideas.</p>
                                    <h3>Kids of Tomorrow</h3>
                                    <p>Specifically focused on causes involving our youth.</p>
                                    <h3>Community Growth</h3>
                                    <p>Causes that stimulate community growth.</p>
                                    <h3>Personal Hardship</h3>
                                    <p>People helping people overcome tough times.</p>
                                    <div className="content-btn">
                                        <Link to="/register" className="btn btn-primary btn-dark-outline black-hover">
                                            Become A Pledger
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="how-it-works" className="our-process-wrapepr">
                    <div className="container">
                        <div className="headings text-center">
                            <h2>How It Works</h2>
                            <p>
                                We make it simple to come together as a community and deliver great impact to the youth,
                                elderly and dreamers.
                            </p>
                        </div>
                        <div className="process-wrap">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="process-item">
                                        <div className="process-item-img">
                                            <img src={processIcon1} alt="" />
                                        </div>
                                        <div className="process-item-content">
                                            <h2>Pledge</h2>
                                            <p>Choose monthly or annually</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="process-item">
                                        <div className="process-item-img">
                                            <img src={processIcon2} alt="" />
                                        </div>
                                        <div className="process-item-content">
                                            <h2>Vote Monthly</h2>
                                            <p>Nominate and Explore causes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="process-item">
                                        <div className="process-item-img">
                                            <img src={processIcon3} alt="" />
                                        </div>
                                        <div className="process-item-content">
                                            <h2>Experience Impact</h2>
                                            <p>View history of support</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-content-wrapper no-bg-img">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-images">
                                    <div className="rounded-img">
                                        <img src={sideImg} alt="" />
                                    </div>
                                    {/* <div className="app-store-links">
                                        <span className="coming-soon-text">Coming Soon!</span>
                                        <a href="#"><img src="assets/images/playstore.png" alt="" /></a>
                                        <a href="#"><img src="assets/images/apple-store.png" alt="" /></a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-content">
                                    <h2>Support Dreams and Hardships</h2>
                                    <p>
                                        Life is full of surprises but we have each other to overcome these challenges.
                                        Our community is full of Builders that have your best interests at heart.
                                    </p>
                                    <div className="content-btn">
                                        <Link to="/register" className="btn btn-primary btn-dark-outline black-hover">
                                            Become A Pledger
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-content-wrapper no-bg-img">
                    <div className="container">
                        <div className="row align-items-center reverse-column">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-images">
                                    <img src={laptopMobile} alt="" />

                                    {/* <div className="app-store-links">
                                        <span className="coming-soon-text">Coming Soon!</span>
                                        <a href="#"><img src="assets/images/playstore.png" alt="" /></a>
                                        <a href="#"><img src="assets/images/apple-store.png" alt="" /></a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="mobile-content">
                                    <h2>Completely Transparent</h2>
                                    <p>
                                        BUILD Foundation prides itself for being completely transparent with our
                                        community. No surprises or trying to figure out where your money is going.
                                        Everything is available from your dashboard.
                                    </p>
                                    <div className="content-btn">
                                        <Link to="/register" className="btn btn-primary btn-dark-outline black-hover">
                                            Become A Pledger
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="faq" className="faq-wrapper">
                    <div className="container">
                        <div className="faq">
                            <div className="headings text-center animate" data-animation="fadeInUp">
                                <h2>FAQs</h2>
                            </div>

                            <Accordion defaultActiveKey="0">
                                {Object.entries(faqs).length > 0 &&
                                    faqs.map((item, index) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{item.title}</Accordion.Header>
                                            <Accordion.Body>
                                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </div>
                    </div>
                </div>

                {/* <!-- Footer --> */}
                <div className="footer-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-4 col-12">
                                <div className="mobile-footer-icons">
                                    <div className="social-icons">
                                        <ul>
                                            <li>
                                                <a href="#" target="_blank">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://instagram.com/trying.to.build" target="_blank">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-12">
                                <div className="copyright">
                                    <p>BUILD Foundation Inc. 2022 All Rights Reserved</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-12">
                                <div className="footer-links">
                                    <ul></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateProps = (state) => ({
    currentUser: state.user,
});

export default connect(mapStateProps, { getTestimonials })(Home);
