import React, { Component } from "react";
import { Alert, Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";

import { appendFormData, getThumbnailUrl } from "../../../Utils/Helpers";
import { updateTestimonial } from "../../../Store/Actions/AdminAction";
import { DefaultVideo } from "../../../Constants/Images";
import { BASE_URL } from "../../../Utils/env";

class EditRating extends Component {
    state = {
        show: this.props.show,
        cause: {},
        loading: false,
        data: {
            id: "",
            title: "",
            review: "",
            review_files: [],
            user_id: "",
            cause_id: "",
            status: "",
            user_name: "",
            userImg: "",
            removeFiles: [],
            removeUserImg: "",
        },
        alertData: {
            show: false,
            type: "",
            message: "",
        },
        errors: {},
        filesArray: [],
        currentFilesArray: [],
        userTempImg: "",
        isAdmin: true,
    };

    UNSAFE_componentWillMount = () => {
        const { isAdmin } = this.props;
        const testimonial = { ...this.props.testimonial };

        // console.log("testimonial :", testimonial);

        this.setState({
            cause: testimonial.cause,
            data: {
                id: testimonial.id,
                title: testimonial.title,
                review: testimonial.review,
                review_files: [],
                user_id: testimonial.user ? testimonial.user.id : "",
                cause_id: testimonial.cause ? testimonial.cause.id : "",
                status: testimonial.status,
                user_name: testimonial.user_name,
                removeFiles: [],
            },
            currentFilesArray: testimonial.review_files,
            isAdmin,
            userTempImg: testimonial.user_img ? `${BASE_URL}/${testimonial.user_img}` : "",
        });
    };

    closeModal = () => {
        this.props.closeModal();
    };
    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value,
            },
        });
    };

    validateData = () => {
        const errors = {};
        const { data } = this.state;

        if (!data.review || data.review === "") {
            errors.review = "Please share your thoughts.";
        }

        return errors;
    };

    updateTestimonial = (e) => {
        const { loading, userTempImg } = this.state;
        const tempData = { ...this.state.data };
        const { review_files, removeFiles, userImg } = tempData;

        delete tempData["review_files"];
        delete tempData["removeFiles"];
        delete tempData["userImg"];

        const errors = this.validateData();

        if (!loading && Object.entries(errors).length === 0) {
            this.setState({
                loading: true,
                errors: {},
            });

            const formData = appendFormData(tempData);

            if (review_files.length > 0) {
                review_files.forEach((item) => {
                    console.log("Adding Item", item);
                    formData.append("review_files[]", item);
                });
            }
            formData.append("removeFiles", JSON.stringify(removeFiles));

            if (userTempImg !== "") {
                formData.append("user_img", userImg);
            }

            this.props
                .updateTestimonial(formData)
                .then((res) => {
                    this.setState({
                        loading: false,
                        alertData: {
                            show: true,
                            type: res.type === "error" ? "danger" : res.type,
                            message: res.message,
                        },
                    });
                })
                .catch((err) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        alertData: {
                            show: false,
                            type: "danger",
                            message: err.message,
                        },
                    });
                });
        } else {
            this.setState({
                errors,
            });
        }
    };

    hideErrMsg = () => {
        this.setState({
            alertData: {
                show: false,
                type: "",
                message: "",
            },
        });
    };

    addMoreFiles = (e) => {
        const review_files = [...this.state.data.review_files];
        const filesArray = [...this.state.filesArray];
        const imgTypes = ["image/gif", "image/jpeg", "image/png"];
        const allowedFileTypes = [...imgTypes, "video/mp4"];

        const allFiles = e.target.files;

        console.log(allFiles);

        for (let i = 0; i < allFiles.length; i++) {
            const filePath = e.target.files[i];
            const objectUrl = URL.createObjectURL(filePath);

            if (allowedFileTypes.includes(filePath.type)) {
                review_files.push(filePath);
                const fileType = imgTypes.includes(filePath.type) ? "image" : "video";
                filesArray.push({
                    type: fileType,
                    url: objectUrl,
                });
            }
        }

        this.setState(
            {
                data: {
                    ...this.state.data,
                    review_files,
                },
                filesArray,
            },
            () => {
                // console.log("Files", this.state, review_files, filesArray);
            }
        );
    };

    removeFile = (index) => {
        const { filesArray, data } = this.state;
        const review_files = [...data.review_files];

        filesArray.splice(index, 1);
        review_files.splice(index, 1);

        this.setState(
            {
                data: {
                    ...this.state.data,
                    review_files,
                },
                filesArray,
            },
            () => {
                console.log("New State :", this.state);
            }
        );
    };

    removeCurrentFile = (item, index) => {
        const { currentFilesArray, data } = this.state;
        const removeFiles = [...data.removeFiles];

        console.log(typeof item);

        const isString = typeof item === "string" ? item : item.id;

        currentFilesArray.splice(index, 1);
        removeFiles.push(isString);

        this.setState(
            {
                data: {
                    ...this.state.data,
                    removeFiles,
                },
                currentFilesArray,
            },
            () => {
                console.log("After:", this.data, currentFilesArray, removeFiles);
            }
        );
    };

    changeUserImg = (e) => {
        const userFile = e.target.files[0];
        const objectUrl = URL.createObjectURL(userFile);

        this.setState({
            data: {
                ...this.state.data,
                userImg: userFile,
            },
            userTempImg: objectUrl,
        });
    };
    removeUserIMg = () => {
        const { data } = this.state;
        const removeUserImg = data.userImg;

        const userImg = document.getElementById("userImg");
        userImg.value = "";

        this.setState({
            data: {
                ...this.state.data,
                userImg: "",
                removeUserImg,
            },
            userTempImg: "",
        });
    };

    render() {
        const { show, cause, data, errors, alertData, loading, filesArray, currentFilesArray, userTempImg, isAdmin } =
            this.state;

        return (
            <Modal className="medium" show={show} onHide={() => this.closeModal()}>
                <Modal.Header>
                    <Modal.Title>Edit Response</Modal.Title>
                    <span className="close as-link" onClick={() => this.closeModal()}>
                        x
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="add-ratting-wrapper">
                        {alertData && alertData.show && (
                            <div className="alerts">
                                <Alert variant={alertData.type} onClose={() => this.hideErrMsg()} dismissible>
                                    {alertData.message}
                                </Alert>
                            </div>
                        )}

                        <div className="heading">
                            <h2>{cause ? cause.title : ""}</h2>
                            <p>{cause ? cause.explain_impact : ""}</p>
                        </div>
                        <Form>
                            <Form.Group className="form-group" controlId="">
                                <Form.Label>Images / Videos</Form.Label>

                                <div className="files-drop-area">
                                    <span className="">Select Or Drop Files</span>
                                    <input
                                        multiple
                                        type="file"
                                        name="files"
                                        className="form-control"
                                        onChange={(e) => this.addMoreFiles(e)}
                                    />
                                </div>
                            </Form.Group>

                            <div className="preview-wrapper">
                                <Row>
                                    {Object.entries(currentFilesArray).length > 0 &&
                                        currentFilesArray.map((item, index) => {
                                            const fileUrl = getThumbnailUrl(item);
                                            return (
                                                <Col md={3}>
                                                    <div className="fileSelected">
                                                        <div className="">
                                                            <span
                                                                className="remove_img"
                                                                onClick={() => this.removeCurrentFile(item, index)}
                                                            >
                                                                <i className="fas fa-times" />
                                                            </span>
                                                            <img src={fileUrl} alt="" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {Object.entries(filesArray).length > 0 &&
                                        filesArray.map((item, index) => {
                                            const fileUrl = item.type === "video" ? DefaultVideo : item.url;
                                            return (
                                                <Col md={3}>
                                                    <div className="fileSelected">
                                                        <div className="">
                                                            <span
                                                                className="remove_img"
                                                                onClick={() => this.removeFile(index)}
                                                            >
                                                                <i className="fas fa-times" />
                                                            </span>
                                                            <img src={fileUrl} alt="" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>

                            {isAdmin && (
                                <>
                                    <Form.Group className="form-group" controlId="user_name">
                                        <Form.Label>Name of Person / Organization</Form.Label>
                                        <FormControl
                                            name="user_name"
                                            onChange={(e) => this.onChange(e)}
                                            value={data.user_name}
                                            placeholder=""
                                        />
                                        {errors.user_name && <span className="errorMsg">{errors.user_name}</span>}
                                    </Form.Group>
                                    <Form.Group className="form-group mb-0" controlId="review">
                                        <Form.Label>Image / Logo </Form.Label>
                                    </Form.Group>
                                    <Row className="align-items-center mb-3">
                                        <Col>
                                            <Form.Group className="form-group mb-0" controlId="userImg">
                                                <input
                                                    id="userImg"
                                                    type="file"
                                                    name="userImg"
                                                    className="form-control"
                                                    onChange={(e) => this.changeUserImg(e)}
                                                />
                                                {errors.userImg && <span className="errorMsg">{errors.userImg}</span>}
                                            </Form.Group>
                                        </Col>
                                        {userTempImg && (
                                            <Col md={2}>
                                                <Form.Group className="form-group mb-0">
                                                    <div className="fileSelected mt-0">
                                                        <div className="">
                                                            <span
                                                                className="remove_img"
                                                                onClick={() => this.removeUserIMg()}
                                                            >
                                                                <i className="fas fa-times" />
                                                            </span>
                                                            <img src={userTempImg} alt="" />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            )}

                            {/* <Form.Group className="form-group" controlId="title">
                                <Form.Label>Title </Form.Label>
                                <FormControl
                                    name="title"
                                    onChange={(e) => this.onChange(e)}
                                    value={data.title}
                                    placeholder="Title"
                                />
                                {errors.title && <span className="errorMsg">{errors.title}</span>}
                            </Form.Group> */}

                            <Form.Group className="form-group" controlId="review">
                                <Form.Label>Comment </Form.Label>
                                <FormControl
                                    rows={4}
                                    as="textarea"
                                    name="review"
                                    onChange={(e) => this.onChange(e)}
                                    value={data.review}
                                    placeholder="Share your thoughts..."
                                />
                                {errors.review && <span className="errorMsg">{errors.review}</span>}
                            </Form.Group>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="form-group" controlId="review">
                                        <Form.Label>Status</Form.Label>
                                        <div className="select-dropdown">
                                            <select
                                                name="status"
                                                className="form-control"
                                                onChange={(e) => this.onChange(e)}
                                                value={data.status}
                                            >
                                                <option value="">Select</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Unapproved">Unapproved</option>
                                            </select>
                                        </div>
                                        {errors.review && <span className="errorMsg">{errors.review}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="form-group mb-0" controlId="review">
                                {loading && (
                                    <Button className="btn btn-primary btn-small">
                                        <i className="fas fa-spinner fa-spin" />
                                    </Button>
                                )}
                                {!loading && (
                                    <Button
                                        className="btn btn-primary btn-small"
                                        onClick={() => this.updateTestimonial()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateProps = (state) => ({
    currentUser: state.user,
});

export default connect(mapStateProps, { updateTestimonial })(EditRating);
