import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const GuestRouter = ({ isAuthenticated, currentUser, component: Component, ...rest }) => {
    const redirectTo = isAuthenticated && currentUser.user_type === "Administrator" ? "/admin" : "/causes";
    return (
        <Route
            {...rest}
            render={(props) => (!isAuthenticated ? <Component {...props} /> : <Redirect to={redirectTo} />)}
        />
    );
};

const mapStateProp = (state) => ({
    currentUser: state.user,
    isAuthenticated: !!state.user.authToken,
});

export default connect(mapStateProp, {})(GuestRouter);
