/* eslint-disable no-useless-computed-key */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Row, Form, Button, Alert} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

import {userImage} from '../../Constants/Images';
import SocialIcons from '../SocialIcons';
import {appendFormData, validateEmail} from '../../Utils/Helpers';

import {
  getUserProfile,
  saveUserProfile,
} from '../../Store/Actions/FrontActions';
// import { BASE_URL } from "../../Utils/env";

class ProfileUpdate extends Component {
  state = {
    data: {},
    errors: {},
    isSaving: false,
    alertData: {
      type: '',
      message: '',
      show: false,
    },
    socials: [
      {
        social: 'Instagram',
        link: '',
      },
    ],
    currentUser: {},
    usStates: {},
  };

  UNSAFE_componentWillMount = () => {
    const {user, usStates} = this.props;

    console.log('User ;', user);

    this.setState({
      usStates,
    });

    if (user.authToken) {
      this.getUserData(user.id);
    }
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {usStates} = props;

    this.setState({
      usStates,
    });

    const {user} = this.props;
    // if (user.authToken) {
    //   // this.getUserData(user.id);
    // }
  };

  getUserData = id => {
    this.props
      .getUserProfile(id)
      .then(res => {
        if (res.type === 'success') {
          this.filterData(res.user);
        } else {
          this.setState({
            alertData: {
              type: 'danger',
              message: res.message,
              show: true,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          alertData: {
            type: 'danger',
            message: err.message,
            show: true,
          },
        });
      });
  };

  filterData = userData => {
    // console.log(userData.socials, JSON.parse(userData.socials));
    console.log('userData :', userData);

    const regionName = userData.state ? this.getRegion(userData.state) : '';
    userData.region = regionName ? regionName.region.name : '';
    const SocialIcons =
      userData.socials !== ''
        ? JSON.parse(userData.socials)
        : [
            {
              social: 'Instagram',
              link: '',
            },
          ];

    this.setState({
      data: userData,
      socials: SocialIcons,
    });
  };

  onChange = e => {
    const data = {...this.state.data};

    if (e.target.name === 'profile_img' && e.target.type === 'file') {
      const imgData = e.target.files[0];
      const path = new FileReader();
      path.readAsDataURL(imgData);

      const profileImg = {
        path,
        imgData,
      };
      this.setState({profileImg});
    } else {
      data[e.target.name] = e.target.value;

      this.setState({
        data,
      });
    }
  };
  onStateChange = e => {
    const {data} = this.state;
    const value = e.target.value;

    const stateData = this.getRegion(value);
    const region = stateData ? stateData.region.name : '';

    this.setState({
      data: {
        ...data,
        ['state']: value,
        ['region']: region,
      },
    });
  };
  getRegion = stateId => {
    const {usStates} = this.state;

    if (Object.entries(usStates).length > 0) {
      const state = usStates.filter(item => item.id === stateId);

      return state ? state[0] : {};
    } else {
      return {};
    }
  };

  validateData = () => {
    const {data} = this.state;
    const errors = {};

    if (!data.name || data.name === '') {
      errors.name = 'Please enter your Name.';
    } else if (!data.email || data.email === '') {
      errors.email = 'Please enter your Email.';
    } else if (!validateEmail(data.email)) {
      errors.email = 'Please enter your valid Email.';
    } else if (!data.region || data.region === '') {
      errors.region = 'Please enter your Region.';
    } else if (!data.state || data.state === '') {
      errors.state = 'Please enter your State.';
    }

    return errors;
  };

  saveProfile = () => {
    const {isSaving, data, socials} = this.state;
    const errors = this.validateData();

    console.log('errors', errors);

    if (!isSaving && Object.entries(errors).length === 0) {
      this.setState({isSaving: true});

      const userData = appendFormData(data);

      if (Object.entries(socials).length > 0) {
        userData.append('socials', JSON.stringify(socials));
      }

      if (data.profile_img && Object.entries(data.profile_img).length > 0) {
        userData.append('profile_img', data.profile_img.imgData);
      }

      this.props
        .saveUserProfile(userData)
        .then(res => {
          this.filterData(res.user);
          this.setState({
            alertData: {
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
              show: true,
            },
            isSaving: false,
          });
          console.log('Res:', res);
        })
        .catch(err => {
          this.setState({
            alertData: {
              type: 'danger',
              message: err.message,
              show: true,
            },
          });
          console.log('Err :', err);
        });
    } else {
      this.setState({errors});
    }
  };

  setSocials = icons => {
    this.setState({
      socials: icons,
    });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        type: '',
        message: '',
        show: false,
      },
    });
  };

  onCoverPick = e => {
    const filePath = e.target.files[0];
    const objectUrl = URL.createObjectURL(filePath);

    const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];

    if (imgTypes.includes(filePath.type)) {
      console.log(filePath.size);
      if (filePath.size <= 1000000) {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: filePath,
            profile_img: objectUrl,
          },
          errors: {
            ...this.state.errors,
            profile_img_file: '',
          },
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            profile_img_file: 'Image size must be less then 1MB.',
          },
        });
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          profile_img_file: 'Please select valid Image.',
        },
      });
    }
  };
  removeGalleryImg = () => {
    this.setState({
      data: {
        ...this.state.data,
        profile_img_file: '',
        remove_cover: 'yes',
        profile_img: '',
      },
    });
  };

  render() {
    const {isSaving, alertData, data, errors, socials, usStates} = this.state;
    const userProfileImg = data.profile_img ? data.profile_img : userImage;

    return (
      <div className="modal-form-wrapper">
        {/* <div className="modal-headings">
                    <h2>What cause would you like to nominate?</h2>
                    <p>We can’t guarantee your cause will be selected this month but you can always resubmit.</p>
                </div> */}

        {alertData && alertData.show && (
          <Alert
            variant={alertData.type}
            onClose={() => this.hideErrMsg()}
            dismissible>
            {alertData.message}
          </Alert>
        )}

        <Form method="post">
          <Row>
            <Col>
              <div className="profile-img-wrapper d-flex align-items-center justify-content-center mb-4 mt-2">
                <div className="profile-img">
                  <img src={userProfileImg} alt="" />
                  <span className="upload-btn">
                    <div className="file-field-wrapper mb-0">
                      <i className="fas fa-camera" />
                      <input
                        type="file"
                        name="profile_img_file"
                        onChange={e => {
                          this.onCoverPick(e);
                        }}
                      />
                    </div>
                  </span>
                </div>
                {/* <div className="profile-btn">
                                    <span className="btn btn-primary">Upload</span>
                                    <span className="btn">Remove</span>
                                </div> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder=""
                  value={data.name ? data.name : ''}
                  onChange={e => this.onChange(e)}
                />
                {errors.name && <span className="errorMsg">{errors.name}</span>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="phone">
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  name="display_name"
                  type="text"
                  placeholder=""
                  value={data.display_name ? data.display_name : ''}
                  onChange={e => this.onChange(e)}
                />
                {errors.display_name && (
                  <span className="errorMsg">{errors.display_name}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={6}>
              <Form.Group className="form-group" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  placeholder=""
                  value={data.email ? data.email : ''}
                  onChange={e => this.onChange(e)}
                />
                {errors.email && (
                  <span className="errorMsg">{errors.email}</span>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={6}>
              <Form.Group className="form-group" controlId="name">
                <Form.Label>Phone</Form.Label>

                <PhoneInput
                  className="form-control"
                  country={'in'}
                  value={data.phone ? data.phone : ''}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group" controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  name="address"
                  type="text"
                  placeholder=""
                  value={data.address ? data.address : ''}
                  onChange={e => this.onChange(e)}
                />
                {errors.address && (
                  <span className="errorMsg">{errors.address}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group" controlId="state">
                <Form.Label>State</Form.Label>
                <div className="select-dropdown">
                  <select
                    name="state"
                    value={data.state ? data.state : ''}
                    onChange={e => this.onStateChange(e)}
                    className="form-control">
                    <option value="">Choose</option>
                    {Object.entries(usStates).length > 0 &&
                      usStates.map((item, index) => (
                        <option value={item.id} key={`state_index_${index}`}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                {errors.state && (
                  <span className="errorMsg">{errors.state}</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="region">
                <Form.Label>Region</Form.Label>
                <Form.Control
                  name="region"
                  type="text"
                  placeholder=""
                  value={data.region ? data.region : ''}
                  disabled
                />
                {errors.region && (
                  <span className="errorMsg">{errors.region}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  name="city"
                  type="text"
                  placeholder=""
                  value={data.city ? data.city : ''}
                  onChange={e => this.onChange(e)}
                />
                {errors.city && <span className="errorMsg">{errors.city}</span>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="zipcode">
                <Form.Label>Zipcode</Form.Label>
                <Form.Control
                  name="zipcode"
                  type="text"
                  placeholder=""
                  value={data.zipcode ? data.zipcode : ''}
                  onChange={e => this.onChange(e)}
                />
                {errors.zipcode && (
                  <span className="errorMsg">{errors.zipcode}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group" controlId="name">
                <Form.Label>Socials</Form.Label>
                <SocialIcons
                  icons={socials}
                  updateIcons={icons => this.setSocials(icons)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group mb-0 mt-2" controlId="name">
                {isSaving && (
                  <span className="btn btn-primary">
                    <i className="fas fa-spinner fa-spin" /> Saving
                  </span>
                )}
                {!isSaving && (
                  <Button
                    className="btn btn-primary"
                    onClick={() => this.saveProfile()}>
                    Save
                  </Button>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateProps = state => ({
  user: state.user,
  usStates: state.usStates,
});

export default connect(mapStateProps, {getUserProfile, saveUserProfile})(
  ProfileUpdate,
);
