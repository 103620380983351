import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {DefaultImg, userImage} from '../Constants/Images';
import VoteBtn from './VoteBtn';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {AddTestimonials} from '../Components';
import {BASE_URL} from '../Utils/env';
import {convertNumber} from '../Utils/Helpers';

const CauseListItem = props => {
  const {item, currentUser} = props;

  const [rattingPopup, setRattingPopup] = useState(false);

  const votesPercentage = item.vote_percentage ? item.vote_percentage : '0';
  let description = item.explain_impact;
  description = description.substring(0, 120);

  const causeItemDate = moment.unix(item.add_time).format('M');
  const currentMonth = new Date();

  const isSameMonth =
    parseInt(causeItemDate) === currentMonth.getMonth() + 1 ? true : false;
  const coverImg = item.cover_img ? item.cover_img : DefaultImg;

  const userImg =
    item.user_data && item.user_data.profile_img !== ''
      ? `${BASE_URL}/${item.user_data.profile_img}`
      : userImage;

  const itemLink = `/cause/${item.id}`;

  return (
    <>
      <div key={`cause_key_${item.id}`} className="list-item">
        <div className="cover-thumb">
          <div className="as-link" onClick={() => props.showDetails(item.id)}>
            <img src={coverImg} alt="" />
          </div>
        </div>
        <div className="list-description">
          <div className="name-wrapper">
            <div className="as-link" onClick={() => props.showDetails(item.id)}>
              <div className="short-info">
                <h2>{item.title}</h2>
                <h3>{item.region.name}</h3>
                <p>{description}</p>
              </div>
              <div className="percentage-count">
                <span>{votesPercentage}%</span>
              </div>
            </div>
          </div>
          <div className="user-info-wrapper">
            <div className="user-info">
              <img src={userImg} alt="" />
              <span className="user-name">{item.user_data.name}</span>
            </div>
            <span className="price">${convertNumber(item.fund_amount)}</span>
          </div>
          <div className="pledge-given">
            {item.winning_status !== 'selected' && (
              <VoteBtn
                causeId={item.id}
                isSameMonth={isSameMonth}
                isVoted={item.isVoted}
                updateCauseVote={id => props.updateCauseVote(id)}
              />
            )}
            {item.isRatted === 'No' &&
              item.user_id === currentUser.id &&
              item.winning_status === 'selected' && (
                <Button
                  className="btn btn-primary btn-small"
                  onClick={() => setRattingPopup(true)}>
                  Add Review
                </Button>
              )}
          </div>
        </div>
      </div>
      {rattingPopup && (
        <AddTestimonials
          cause={item}
          show={rattingPopup}
          closeModal={() => setRattingPopup(false)}
        />
      )}
    </>
  );
};

const mapStateProps = state => ({
  currentUser: state.user,
});
export default connect(mapStateProps, {})(CauseListItem);
