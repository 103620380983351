import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { appendFormData } from "../Utils/Helpers";

const VerifyPhone = ({ otp, newUser, phone, showAlert, updatePhone, resetForm, verifyOtp }) => {
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});
    const [num1, setNumOne] = useState("");
    const [num2, setNumTwo] = useState("");
    const [num3, setNumThree] = useState("");
    const [num4, setNumFour] = useState("");

    const validateData = () => {
        const tempErr = {};
        const tempOtp = `${num1}${num2}${num3}${num4}`;

        if (tempOtp === "" || tempOtp.length !== 4) {
            tempErr.otp = "Please enter valid OTP.";
        }

        return tempErr;
    };

    const submitForm = (e) => {
        e.preventDefault();
        const errs = validateData();

        if (!loading && Object.entries(errs).length === 0) {
            setLoading(true);

            let formData = "";
            const tempOtp = `${num1}${num2}${num3}${num4}`;

            if (newUser && Object.entries(newUser).length > 0) {
                newUser.is_new_user = "Yes";
                newUser.otp = tempOtp;
                formData = appendFormData(newUser);
            } else {
                formData = new FormData();
                formData.append("phone", phone);
                formData.append("otp", JSON.stringify(tempOtp));
            }

            try {
                verifyOtp(formData)
                    .then((res) => {
                        const type = res.type === "error" ? "danger" : res.type;

                        showAlert(type, res.message);
                        setLoading(false);
                        updatePhone();
                        resetForm();
                    })
                    .catch((err) => {
                        showAlert("danger", err.message);
                        setLoading(false);
                    });
            } catch (err) {
                showAlert("danger", err.message);
            }
        } else {
            setErrors(errs);
        }

        return false;
    };

    const updateNumber = (e, target) => {
        const number = e.target.value;

        switch (target) {
            case "one":
                setNumOne(number);
                if (number) {
                    document.getElementById("num-2").focus();
                }
                break;
            case "two":
                setNumTwo(number);
                if (number) {
                    document.getElementById("num-3").focus();
                }
                break;
            case "three":
                setNumThree(number);
                if (number) {
                    document.getElementById("num-4").focus();
                }
                break;
            default:
                setNumFour(number);
                break;
        }
    };

    useEffect(() => {
        if (otp) {
            const number = `${otp}`;
            setNumOne(number.charAt(0));
            setNumTwo(number.charAt(1));
            setNumThree(number.charAt(2));
            setNumFour(number.charAt(3));
        }
    }, [otp]);

    return (
        <div className="verify-phone-wrapper">
            <Form onSubmit={submitForm}>
                <Form.Label>OTP</Form.Label>
                <div className="otp-wrapper">
                    <Form.Group className="form-group">
                        <Form.Control id="num-1" maxLength={1} value={num1} onChange={(e) => updateNumber(e, "one")} />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control id="num-2" maxLength={1} value={num2} onChange={(e) => updateNumber(e, "two")} />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control
                            id="num-3"
                            maxLength={1}
                            value={num3}
                            onChange={(e) => updateNumber(e, "three")}
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Control id="num-4" maxLength={1} value={num4} onChange={(e) => updateNumber(e, "four")} />
                    </Form.Group>
                </div>
                {errors.otp && <span className="info-text error">{errors.otp}</span>}

                <Form.Group className="form-group mb-0">
                    <button className="btn btn-small" onClick={submitForm}>
                        {!loading && "Verify Now"}
                        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                    </button>

                    <span className="update-number" onClick={() => updatePhone()}>
                        Change Number
                    </span>
                </Form.Group>
            </Form>
        </div>
    );
};

export default VerifyPhone;
