import {
  faUsers,
  faChartBar,
  faRibbon,
  faGlobeAmericas,
  faCertificate,
  faHome,
  faLock,
  faClosedCaptioning,
  faUserEdit,
  faCoins,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

export const menuItems = [
  //   {
  //     label: 'Home',
  //     link: '/causes',
  //     icon: faHome,
  //     submenu: [],
  //   },
  {
    label: 'Dashboard',
    link: '/admin/dashboard',
    icon: 'icon-home',
    submenu: [],
  },
  {
    label: 'Nominations',
    link: '/admin/causes',
    icon: 'icon-nominations',
    submenu: [],
  },
  {
    label: 'Donations',
    link: '/admin/subscriptions',
    icon: 'icon-contributions',
    submenu: [],
  },
  // {
  //     label: "Payments",
  //     link: "/admin/payments",
  //     icon: faCreditCard,
  //     submenu: [],
  // },
  {
    label: 'Expenses',
    link: '/admin/expenses',
    icon: 'fas fa-coins',
    submenu: [],
  },
  {
    label: 'Events',
    link: '/admin/events',
    icon: 'icon-events',
    submenu: [],
  },

  {
    label: 'Manage',
    link: '/admin/users',
    icon: 'fas fa-tasks',
    submenu: [
      {
        label: 'Users',
        link: '/admin/users',
        icon: 'fas fa-user',
      },
      {
        label: 'Categories',
        link: '/admin/categories',
        icon: 'fas fa-certificate',
      },
      {
        label: 'Regions',
        link: '/admin/regions',
        icon: 'fas fa-globe-americas',
      },
    ],
  },
];

export const AuthMenuItems = [
  //   {
  //     label: 'Home',
  //     link: '/',
  //     icon: faGlobe,
  //     submenu: [],
  //   },
  {
    label: 'Dashboard',
    link: '/causes',
    icon: 'icon-home',
    submenu: [],
  },
  {
    label: 'Contributions',
    link: '/past-contributions',
    icon: 'icon-contributions',
    submenu: [],
  },
  {
    label: 'Nominations',
    link: '/nominations',
    icon: 'icon-nominations',
    submenu: [],
  },
];

export const GuestMenuItems = [
  //   {
  //     label: 'Home',
  //     link: '/',
  //     icon: faHome,
  //     submenu: [],
  //   },
  //   {
  //     label: 'BUILD App',
  //     link: 'https://build.tryingtobuild.com/',
  //     isExternal: true,
  //     icon: faTabletAlt,
  //     submenu: [],
  //   },
  {
    label: 'Login',
    link: '/login',
    icon: faLock,
    submenu: [],
  },
];
