import React from 'react';

import {connect} from 'react-redux';

import {
  Header,
  FrontSidebar,
  Breadcrumb,
  ProfileUpdate,
} from '../../Components';

const Profile = props => {
  const {location, menuState} = props;
  const isOpen = menuState ? 'open' : '';

  return (
    <div className={`admin-wrapper ${isOpen}`}>
      <FrontSidebar location={location} />
      <div className="admin-content">
        <Header />
        <div className="admin-body">
          <div className="container">
            <div className="body-card">
              <div className="heading">
                <h2>Account</h2>
                <div className="filter-options-wrapper"></div>
              </div>

              <div className="body-content">
                <div className="seach-box-wrapper"></div>

                <div className="cuases-lists-wrapper user-profile-wrapper">
                  <ProfileUpdate />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  isLogin: !!state.user.authToken,
});

export default connect(mapStateProps, {})(Profile);
