import {animateScroll as scroll} from 'react-scroll';

import {DefaultVideo} from '../Constants/Images';
import {BASE_URL} from './env';

const appendFormData = data => {
  const formData = new FormData();

  if (Object.entries(data).length !== 0) {
    for (const [key, value] of Object.entries(data)) {
      // console.log("Form Data :", key, value);
      formData.append(key, value);
    }
  }

  return formData;
};

const validateEmail = email => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  return pattern.test(email);
};

const checkDifferent = timeStamp => {
  const current = Date.now();
  const difference = timeStamp > current ? timeStamp - current : 0;

  return current + difference;
};

const updateUrl = (newUrl, history) => {
  // const newUrl = `${BASE_URL}/admin/services/network`;
  //window.history.pushState({}, null, newUrl);
  // history.pushState({}, null, newUrl);
  history.push({pathname: newUrl});
};

const convertNumber = num => {
  let nf = new Intl.NumberFormat('en-US');

  const amount = nf.format(parseFloat(num).toFixed(2));

  return amount;
};

const getThumbnailUrl = item => {
  let fileUrl = DefaultVideo;

  if (typeof item === 'object') {
    if (
      item.thumbnails &&
      item.thumbnails.high &&
      item.thumbnails.high.url !== ''
    ) {
      fileUrl = item.thumbnails.high.url;
    } else if (
      item.thumbnails &&
      item.thumbnails.medium &&
      item.thumbnails.medium.url !== ''
    ) {
      fileUrl = item.thumbnails.medium.url;
    } else if (
      item.thumbnails &&
      item.thumbnails.default &&
      item.thumbnails.default.url !== ''
    ) {
      fileUrl = item.thumbnails.default.url;
    }
  } else if (typeof item === 'string') {
    fileUrl = `${BASE_URL}/${item}`;
  }

  return fileUrl;
};

const getPopupList = medias => {
  const popupList = [];

  if (medias.length > 0) {
    for (let i = 0; i < medias.length; i++) {
      const media = medias[i];

      if (media && media !== '') {
        if (typeof media === 'object') {
          popupList.push(media.url);
        } else {
          const url = `${BASE_URL}/${media}`;
          popupList.push(url);
        }
      }
    }
  }

  return popupList;
};
const getMediaList = medias => {
  const mediaList = [];

  if (medias.length > 0) {
    for (let i = 0; i < medias.length; i++) {
      const media = medias[i];

      if (media && media !== '') {
        let type = 'photo';
        let url = '';

        if (typeof media === 'object') {
          type = 'video';
          url = getThumbnailUrl(media);
        } else if (media.indexOf('.mp4') !== -1) {
          type = 'video';
          url = DefaultVideo;
        } else {
          url = media;
        }

        mediaList.push({
          url,
          type,
          title: '',
        });
      }
    }
  }

  return mediaList;
};

// const scrollTo = (id) => {
//     const target = id && id !== "" ? document.getElementById(id) : null;

//     const headerElement = document.getElementsByClassName("header-wrapper");
//     const headerHeight = headerElement[0].clientHeight + 20;
//     const topPos = id && id !== "" ? target.offsetTop - headerHeight : 0;

//     scroll.scrollTo(topPos);

//     return false;
// };

const checkScrollPosition = () => {
  var scrollPos = window.scrollY;
  var screenHeight = window.outerHeight / 4;

  const header = document.getElementsByClassName('site-header');
  if (header && header[0]) {
    if (scrollPos > 50) {
      header[0].classList.add('fixed');
    } else {
      header[0].classList.remove('fixed');
    }
  }

  const backToTop = document.getElementsByClassName('scrollToTop');
  if (backToTop && backToTop[0]) {
    if (scrollPos > screenHeight) {
      backToTop[0].classList.add('show');
    } else {
      backToTop[0].classList.remove('show');
    }
  }
};

const scrollTo = id => {
  const target = id && id !== '' ? document.getElementById(id) : null;

  //   const headerElement = document.getElementsByClassName('header-wrapper');
  //   const headerHeight = headerElement[0].clientHeight + 20;
  const topPos = id && id !== '' ? target.offsetTop : 0;

  scroll.scrollTo(topPos);

  return false;
};

window.addEventListener('scroll', checkScrollPosition);

export {
  validateEmail,
  appendFormData,
  checkDifferent,
  checkScrollPosition,
  updateUrl,
  convertNumber,
  getMediaList,
  getPopupList,
  getThumbnailUrl,
  scrollTo,
};
