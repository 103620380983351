import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';

import moment from 'moment';
import MomentDate from 'react-moment';
import {Breadcrumb} from '../../Components';

import {
  getPaymentsList,
  removePaymentItem,
} from '../../Store/Actions/AdminAction';
import {EditSubscription} from '../../Components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {updateUrl} from '../../Utils/Helpers';

class PaymentsList extends Component {
  state = {
    loading: false,
    payments: {},
    pagging: {},
    page: 1,
    detailsModal: false,
    subDetails: {},
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const page = this.props.match.params.page
      ? this.props.match.params.page
      : 1;

    this.setState(
      {
        page,
      },
      () => {
        this.getPayments(page);
      },
    );
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {payments} = props;

    this.setState({
      payments,
    });
  };

  getPayments = page => {
    const {loading} = this.state;

    if (!loading) {
      this.setState({loading: true});

      this.props
        .getPaymentsList(page)
        .then(res => {
          const tempState = {...this.state};

          if (res.type === 'success') {
            tempState.pagging = res.pagging;
            tempState.page = res.pagging;
            const CurrentPageNo =
              res.pagging && res.pagging.current
                ? parseInt(res.pagging.current)
                : 1;

            const newUrl =
              CurrentPageNo === 1
                ? `/admin/payments`
                : `/admin/payments/page/${page}`;
            updateUrl(newUrl, this.props.history);
          }

          tempState.loading = false;

          this.setState(tempState);
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  enableDetailsModal = subItem => {
    this.setState({
      detailsModal: true,
      subDetails: subItem,
    });
  };

  removePaymentItem = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .removePaymentItem(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  _renderItems = item => {
    const timestamp = moment.utc(parseInt(item.add_time) * 1000);

    return (
      <tr key={`items_key_${item.id}`}>
        <td align="center">{}</td>
        <td>{item.user.name}</td>
        <td>{item.payment_id}</td>
        <td>${item.amount}</td>
        <td>{item.region ? item.region.name : ''}</td>
        <td>{item.status}</td>
        <td>
          <MomentDate date={timestamp} format="MM/DD/YYYY HH:mm" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span
              title="Payments"
              className="actionIcon danger"
              onClick={() => this.removePaymentItem(item.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      detailsModal: false,
      subDetails: {},
    });
  };

  render() {
    const {alertData, loading, payments, pagging, detailsModal, subDetails} =
      this.state;

    return (
      <div className="admin-body">
        <div className="container">
          {/* <Breadcrumb title="Payments" /> */}

          <div className="body-card">
            <div className="heading">
              <h2>Payments</h2>
            </div>

            <div className="body-content">
              <div className="listing-tables mt-0">
                {alertData && alertData.show && (
                  <div className="alerts">
                    <Alert
                      variant={alertData.type}
                      onClose={() => this.hideErrMsg()}
                      dismissible>
                      {alertData.message}
                    </Alert>
                  </div>
                )}

                <table className="table table-inverse table-responsive">
                  <thead className="thead-inverse">
                    <tr>
                      <th width="10" className="text-center">
                        &nbsp;
                      </th>
                      <th>User</th>
                      <th>Payment ID</th>
                      <th>Amount</th>
                      <th>Region</th>
                      <th>Status</th>
                      <th width="130">Add Time</th>
                      <th width="80" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td align="center" colSpan={8}>
                          <span className="fas fa-spinner fa-spin" />
                        </td>
                      </tr>
                    )}
                    {!loading && Object.entries(payments).length === 0 && (
                      <tr>
                        <td align="center" colSpan={8}>
                          No Payments Available.
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      Object.entries(payments).length > 0 &&
                      payments.map(item => this._renderItems(item))}
                  </tbody>
                </table>

                {pagging && (
                  <div className="pagging-wrapper">
                    {pagging.current && pagging.pages && pagging.totalItems && (
                      <div className="pagging-text">
                        <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                      </div>
                    )}

                    <div className="pagging-btns">
                      {pagging.previous && (
                        <span
                          className="btn btn-small"
                          onClick={() => this.getPayments(pagging.previous)}>
                          Previous
                        </span>
                      )}
                      {pagging.next && (
                        <span
                          className="btn btn-small next-btn"
                          onClick={() => this.getPayments(pagging.next)}>
                          Next
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {detailsModal && (
              <EditSubscription
                data={subDetails}
                show={detailsModal}
                updateSubscription={data => this.updateSubscription(data)}
                closeModal={() => this.closeModal()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateProps = state => ({
  menuState: state.menuState,
  payments: state.adminPayments,
});

export default connect(mapStateProps, {getPaymentsList, removePaymentItem})(
  PaymentsList,
);
