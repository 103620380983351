import {
  GET_CATEGORIES,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  REMOVE_CATEGORY,
  GET_REGIONS,
  ADD_REGION,
  UPDATE_REGION,
  REMOVE_REGION,
  GET_ADMIN_PAST_CAUSES,
  GET_ADMIN_CAUSES,
  ADD_ADMIN_CAUSE,
  UPDATE_ADMIN_CAUSE,
  REMOVE_ADMIN_CAUSE,
} from '../../Constants/Types';
import API from '../../Utils/API';

/* Causes Categories */
export const getCategoriesDispatch = categories => ({
  type: GET_CATEGORIES,
  categories,
});
export const addCategoryDispatch = category => ({
  type: ADD_CATEGORY,
  category,
});
export const updateCategoryDispatch = category => ({
  type: UPDATE_CATEGORY,
  category,
});
export const removeCategoryDispatch = category => ({
  type: REMOVE_CATEGORY,
  category,
});

export const getCategories = page => dispatch => {
  return API.getCategories(page).then(res => {
    if (res.type !== 'error') {
      const {categories} = res;

      dispatch(getCategoriesDispatch(categories));
    }
    return res;
  });
};
export const addCategory = data => dispatch =>
  API.addCategory(data).then(res => {
    if (res.type !== 'error') {
      const {category} = res;

      dispatch(addCategoryDispatch(category));
    }
    return res;
  });
export const updateCategory = data => dispatch =>
  API.updateCategory(data).then(res => {
    if (res.type !== 'error') {
      const {category} = res;

      dispatch(updateCategoryDispatch(category));
    }
    return res;
  });
export const removeCategory = data => dispatch =>
  API.removeCategory(data).then(res => {
    if (res.type !== 'error') {
      const {category} = res;

      dispatch(removeCategoryDispatch(category));
    }
    return res;
  });

/* Causes Regions */
export const getRegionsDispatch = regions => ({
  type: GET_REGIONS,
  regions,
});
export const addRegionDispatch = region => ({
  type: ADD_REGION,
  region,
});
export const updateRegionDispatch = region => ({
  type: UPDATE_REGION,
  region,
});
export const removeRegionDispatch = region => ({
  type: REMOVE_REGION,
  region,
});

export const getRegions = page => dispatch =>
  API.getRegions(page).then(res => {
    if (res.type !== 'error') {
      const {regions} = res;

      dispatch(getRegionsDispatch(regions));
    }
    return res;
  });
export const addRegion = data => dispatch =>
  API.addRegion(data).then(res => {
    if (res.type !== 'error') {
      const {region} = res;

      dispatch(addRegionDispatch(region));
    }
    return res;
  });
export const updateRegion = data => dispatch =>
  API.updateRegion(data).then(res => {
    if (res.type !== 'error') {
      const {region} = res;

      dispatch(updateRegionDispatch(region));
    }
    return res;
  });
export const removeRegion = data => dispatch =>
  API.removeRegion(data).then(res => {
    if (res.type !== 'error') {
      const {region} = res;

      dispatch(removeRegionDispatch(region));
    }
    return res;
  });

/* Causes List  */
export const getGetPastCauseListDispatch = causes => ({
  type: GET_ADMIN_PAST_CAUSES,
  causes,
});
export const getGetCauseListDispatch = causes => ({
  type: GET_ADMIN_CAUSES,
  causes,
});
export const addAdminCuaseDispatch = cause => ({
  type: ADD_ADMIN_CAUSE,
  cause,
});
export const updateAdminCauseDispatch = cause => ({
  type: UPDATE_ADMIN_CAUSE,
  cause,
});
export const removeAdminCuaseDispatch = cause => ({
  type: REMOVE_ADMIN_CAUSE,
  cause,
});

export const getAdminPastCuasesList = page => dispatch =>
  API.getAdminPastCauses(page).then(res => {
    if (res.type !== 'error') {
      const {causes} = res;

      dispatch(getGetPastCauseListDispatch(causes));
    }
    return res;
  });
export const getAdminCuasesList = page => dispatch =>
  API.getAdminCauses(page).then(res => {
    if (res.type !== 'error') {
      const {causes} = res;

      dispatch(getGetCauseListDispatch(causes));
    }
    return res;
  });
export const addAdminCuase = data => dispatch =>
  API.addAdminCuase(data).then(res => {
    if (res.type !== 'error') {
      const {cause} = res;

      dispatch(addAdminCuaseDispatch(cause));
    }
    return res;
  });
export const updateAdminCause = data => dispatch =>
  API.updateAdminCause(data).then(res => {
    if (res.type !== 'error') {
      const {cause} = res;

      dispatch(updateAdminCauseDispatch(cause));
    }
    return res;
  });
export const removeAdminCuase = data => dispatch =>
  API.removeAdminCuase(data).then(res => {
    if (res.type !== 'error') {
      const {cause} = res;

      dispatch(removeAdminCuaseDispatch(cause));
    }
    return res;
  });
