import {combineReducers} from 'redux';
import {user} from './AuthReducer';

import {
  menuState,
  settings,
  notifications,
  pageLoaded,
  usStates,
  myCards,
} from './CommanReducers';
import {
  causeCategory,
  causeRegions,
  adminCauses,
  adminPastCauses,
} from './CausesReduces-Admin';
import {
  allCauseCategories,
  allCauseRegions,
  myCauses,
  subscriptions,
  myPayments,
  homeCauses,
  categoryCauses,
} from './FrontReducers';
import {
  adminSubscriptions,
  adminPayments,
  expensesList,
  testimonialList,
  termPages,
  eventsList,
} from './AdminReducer';

import {users} from './UserReducer';

export default combineReducers({
  pageLoaded,
  menuState,
  usStates,
  settings,
  notifications,
  user,
  users,
  causeCategory,
  causeRegions,
  adminPastCauses,
  adminCauses,
  expensesList,
  testimonialList,
  allCauseCategories,
  allCauseRegions,
  homeCauses,
  categoryCauses,
  myCauses,
  myCards,
  subscriptions,
  myPayments,
  adminSubscriptions,
  adminPayments,
  termPages,
  eventsList,
});
