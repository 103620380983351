/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {Col, Modal, Row, Form, Button, Alert} from 'react-bootstrap';

import {appendFormData} from '../../Utils/Helpers';
import {makeDonatePayments} from '../../Store/Actions/FrontActions';

import {getAllUsers} from '../../Store/Actions/UsersAction';

const ContributionForm = props => {
  const [users, setUsers] = useState([]);

  const isPledged = 'No';

  const [state, setState] = useState({
    data: {
      amount: props.user.amount,
      address: props.user.state,
      contribution_plan: isPledged === 'Yes' ? 'One-Time' : 'Pledge',
      pledge_plan: isPledged === 'No' ? 'Annually' : '',
      mannual_payment: 'Yes',
    },
    errors: {},
    saving: false,
    min_amount: isPledged === 'Yes' ? 60 : 5,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  });

  const onChange = e => {
    const tempdata = {...state.data};
    tempdata[e.target.name] = e.target.value;

    setState({
      ...state,
      data: tempdata,
    });
  };

  const onCheckChange = e => {
    const tempdata = {...state.data};
    const value = e.target.value;
    let min_amount = 5;

    tempdata[e.target.name] = value;

    switch (value) {
      case 'Pledge':
        min_amount = 60;
        break;
      case 'Annually':
        min_amount = 60;
        break;
      case 'One-Time':
        min_amount = 5;
        break;
      case 'Monthly':
        min_amount = 5;
        break;

      default:
        break;
    }

    setState({
      ...state,
      data: tempdata,
      min_amount,
    });
  };

  const validateData = () => {
    const {data, min_amount} = state;
    const errors = {};

    if (!data.amount || data.amount === '') {
      errors.amount = `Please enter any Amount`;
    } else if (!parseInt(data.amount)) {
      errors.amount = `Please enter valid Amount`;
    } else if (data.amount < min_amount) {
      errors.amount = `Minimum contribution amount must be $${min_amount}`;
    } else if (!data.user || data.user === '') {
      errors.user = `Select user from the list.`;
    }

    return errors;
  };

  const makePayment = event => {
    event.preventDefault();

    const errors = validateData();
    const {data} = state;

    if (Object.entries(errors).length === 0) {
      setState({...state, errors, saving: true});

      const formData = appendFormData(data);
      formData.append('mannual_payment', 'Yes');

      donateNow(formData);
    } else {
      setState({
        ...state,
        errors,
      });
    }
  };

  const donateNow = formData => {
    props
      .donateNow(formData)
      .then(res => {
        if (res.type === 'success') {
        }

        setState({
          ...state,
          data: {
            ...state.data,
            amount: res.type === 'success' ? 0 : state.data.amount,
          },
          errors: {},
          saving: false,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        setState({
          ...state,
          errors: {},
          saving: false,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const hideErrMsg = () => {
    setState({
      ...state,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  const changeAmount = amount => {
    setState({
      ...state,
      data: {
        ...state.data,
        amount,
      },
    });
  };

  const updateFrequency = plan => {
    const innitialAmount = plan === 'Monthly' ? 10 : 60;

    setState({
      ...state,
      data: {
        ...state.data,
        pledge_plan: plan,
        amount: innitialAmount,
      },
    });
  };

  const updateContributionPlan = plan => {
    const innitialAmount = plan === 'One-Time' ? 10 : 60;

    const tempData = {contribution_plan: plan, amount: innitialAmount};

    if (plan === 'Pledge') {
      tempData.pledge_plan = 'Annually';
    }

    setState({
      ...state,
      data: {
        ...state.data,
        ...tempData,
      },
    });
  };

  useEffect(() => {
    setUsers(props.users);
  }, [props.users]);

  const {data, errors, alertData, saving, min_amount} = state;

  const sampleAmount =
    data.pledge_plan !== 'Annually' || data.contribution_plan === 'One-Time'
      ? [5, 10, 15, 25]
      : [60, 120, 180, 300];

  return (
    <>
      {alertData && alertData.show && (
        <Alert
          variant={alertData.type}
          onClose={() => hideErrMsg()}
          dismissible>
          {alertData.message}
        </Alert>
      )}

      <form onSubmit={makePayment} autocomplete="off">
        <Row>
          <Col>
            <div className="modal-headings mt-0 mb-1">
              <Form.Group
                className="form-group mt-0 example-amounts mb-2"
                controlId="name">
                <Form.Label>
                  Select what you are comfortable sharing below.
                </Form.Label>

                <span
                  className={`btn btn-small btn-primary btn-outline ${
                    data.contribution_plan === 'Pledge' ? 'active' : ''
                  }`}
                  onClick={() => updateContributionPlan('Pledge')}>
                  Pledge
                </span>
                <span
                  className={`btn btn-small btn-primary btn-outline ${
                    data.contribution_plan === 'One-Time' ? 'active' : ''
                  }`}
                  onClick={() => updateContributionPlan('One-Time')}>
                  One-Time
                </span>

                {errors.contribution_plan && (
                  <span className="errorMsg">{errors.contribution_plan}</span>
                )}
              </Form.Group>
            </div>
          </Col>
        </Row>

        {data.contribution_plan === 'Pledge' && (
          <Row>
            <Col>
              <div className="modal-headings mt-0 mb-1">
                <h2>Contribution Frequency</h2>
                <p>How often would you like to pay?</p>

                <Form.Group
                  className="form-group example-amounts mb-2"
                  controlId="name">
                  <span
                    className={`btn btn-small btn-primary btn-outline ${
                      data.pledge_plan === 'Monthly' && 'active'
                    }`}
                    onClick={() => updateFrequency('Monthly')}>
                    Monthly
                  </span>
                  <span
                    className={`btn btn-small btn-primary btn-outline ${
                      data.pledge_plan === 'Annually' && 'active'
                    }`}
                    onClick={() => updateFrequency('Annually')}>
                    Annually
                  </span>

                  {errors.pledge_plan && (
                    <span className="errorMsg">{errors.pledge_plan}</span>
                  )}
                </Form.Group>
              </div>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="modal-headings mt-0 mb-1">
              <h2>Recurring Contribution Amount</h2>
              <p>How much would you like to give?</p>
              <div className="example-amounts">
                {sampleAmount.map((item, index) => {
                  const activeClass = item === data.amount ? 'active' : '';
                  return (
                    <span
                      key={`example_amount_${index}`}
                      className={`btn btn-small btn-primary btn-outline ${activeClass}`}
                      onClick={() => changeAmount(item)}>
                      ${item}
                    </span>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="form-group" controlId="amount">
              <Form.Label>Contribution Amount</Form.Label>
              <div className="inputWithIcon">
                <i className="fas fa-dollar-sign" />
                <Form.Control
                  name="amount"
                  type="text"
                  placeholder={`Amount must be at least $${min_amount}`}
                  value={data.amount ? data.amount : ''}
                  onChange={e => onChange(e)}
                />
              </div>
              {errors.amount && (
                <span className="errorMsg">{errors.amount}</span>
              )}
              <span className="info-text">
                * Minimum pledge donation is $5 monthly or $60 annually.
              </span>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="form-group mb-4" controlId="user">
              <Form.Label>Payment For</Form.Label>
              <Form.Control
                name="user"
                type="text"
                placeholder={`Type user Phone or Name`}
                value={data.user ? data.user : ''}
                onChange={e => onChange(e)}
                list="user_list"
              />
              <datalist id="user_list">
                {Object.entries(users).length > 0 &&
                  users.map(item => (
                    <option
                      key={`users_list_${item.id}`}
                      value={`${item.name} +${item.phone}`}
                    />
                  ))}
              </datalist>

              {errors.user && (
                <>
                  <span className="errorMsg">{errors.user}</span> <br />
                </>
              )}

              <span
                className="info-text add-new-user-link"
                onClick={() => props.showAddUser()}>
                Add New User
              </span>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            {!saving && (
              <Button type="submit" onClick={() => makePayment}>
                Submit
              </Button>
            )}
            {saving && (
              <Button>
                <i className="fas fa-spinner fa-spin" />
              </Button>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
};

const AddContribution = props => {
  const {show, user, makeDonatePayments, getAllUsers} = props;

  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const closeModal = () => {
    if (user.isSubscribed !== 'No') {
      props.closeModal();
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then(res => {
        setLoading(false);
        const {users} = res;
        if (Object.entries(users).length > 0) {
          setAllUsers(users);
        }
      })
      .catch(e => {
        setLoading(false);
        console.log('Err :', e);
      });
  }, []);

  return (
    <Modal className="small" show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>Mannual Payment</Modal.Title>
        {user.isSubscribed !== 'No' && (
          <span className="close as-link" onClick={() => closeModal()}>
            x
          </span>
        )}
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="modal-form-wrapper">
          <div className="modal-headings"></div>

          {loading && (
            <div className="mt-5 mb-5 text-center">
              <span className="fas fa-spinner fa-spin" />
            </div>
          )}

          {!loading && (
            <ContributionForm
              user={user}
              donateNow={data => makeDonatePayments(data)}
              users={allUsers}
              showAddUser={() => props.showAddUser()}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateProps = state => ({
  categories: state.allCauseCategories,
  regions: state.allCauseRegions,
  user: state.user,
});

export default connect(mapStateProps, {makeDonatePayments, getAllUsers})(
  AddContribution,
);
