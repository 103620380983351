import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Alert} from 'react-bootstrap';
import moment from 'moment';
import MomentDate from 'react-moment';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCoins} from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  FrontSidebar,
  Breadcrumb,
  SubscriptionModal,
  AddCardModal,
  AddContribution,
} from '../../Components';
import {
  getMySubscriptions,
  cancelMySubscription,
} from '../../Store/Actions/FrontActions';
import {updateUrl} from '../../Utils/Helpers';

class SubscriptionsList extends Component {
  state = {
    loading: false,
    subscriptions: {},
    donateModal: {show: false},
    pagging: {},
    page: 1,
    detailsModal: false,
    subDetails: {},
    currentUser: {},
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const page = this.props.match.params.page
      ? this.props.match.params.page
      : 1;

    this.setState(
      {
        page,
      },
      () => {
        this.getMySubscriptions(page);
      },
    );
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {subscriptions, currentUser} = props;

    this.setState({
      subscriptions,
      currentUser,
    });
  };

  getMySubscriptions = page => {
    const {loading} = this.state;

    if (!loading) {
      this.setState({loading: true});

      this.props
        .getMySubscriptions(page)
        .then(res => {
          const tempState = {...this.state};

          if (res.type === 'success') {
            tempState.pagging = res.pagging;
            tempState.page = res.pagging;

            const CurrentPageNo =
              res.pagging && res.pagging.current
                ? parseInt(res.pagging.current)
                : 1;

            const newUrl =
              CurrentPageNo === 1
                ? `/past-contributions`
                : `/past-contributions/page/${page}`;
            updateUrl(newUrl, this.props.history);
          }

          tempState.loading = false;

          this.setState(tempState);
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  enableDetailsModal = subItem => {
    this.setState({
      detailsModal: true,
      subDetails: subItem,
    });
  };

  cancelSubscription = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .cancelMySubscription(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            detailsModal: false,
            subDetails: {},
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  _renderItems = item => {
    const startData = moment.utc(parseInt(item.start_date) * 1000);
    const timestamp = moment.utc(parseInt(item.end_date) * 1000);

    console.log(item);

    return (
      <tr key={`items_key_${item.id}`}>
        <td align="center">{}</td>
        <td>{item.contribution_type}</td>
        {/* <td>{item.pledge_type}</td> */}
        <td>${item.amount}</td>

        <td>{item.region ? item.region.name : ''}</td>
        <td>
          <MomentDate date={startData} format="DD/MM/YYYY" />
        </td>
        <td>
          <MomentDate date={timestamp} format="DD/MM/YYYY" />
        </td>
      </tr>
    );
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      detailsModal: false,
      subDetails: {},
    });
  };

  toggleModal = (modal, state) => {
    this.setState({
      [modal]: state,
    });
  };

  render() {
    const {
      alertData,
      loading,
      subscriptions,
      pagging,
      detailsModal,
      subDetails,
      add_card_modal,
      donateModal,
      currentUser,
    } = this.state;
    const {location, menuState} = this.props;
    const isOpen = menuState ? 'open' : '';

    console.log('currentUser ;', currentUser);

    return (
      <div className={`admin-wrapper ${isOpen}`}>
        <FrontSidebar location={location} />
        <div className="admin-content">
          <Header />
          <div className="admin-body">
            <div className="container">
              {/* <Breadcrumb title="Past Contributions" /> */}

              <div className="body-card">
                <div className="heading">
                  <h2>Contributions</h2>
                  <div className="filter-options-wrapper">
                    <button
                      className="btn btn-primary btn-small add-new-btn"
                      onClick={() =>
                        this.setState({
                          donateModal: {show: true, type: 'One-Time'},
                        })
                      }>
                      One-Time Donation
                    </button>

                    {(currentUser.isPledged === 'No' ||
                      currentUser.isPledged === '') && (
                      <button
                        className="btn btn-primary btn-small add-new-btn"
                        onClick={() =>
                          this.setState({
                            donateModal: {show: true, type: 'Pledge'},
                          })
                        }>
                        Setup Contribution
                      </button>
                    )}

                    <span
                      className="btn btn-primary btn-small add-new-btn"
                      onClick={() => {
                        this.toggleModal('add_card_modal', true);
                      }}>
                      Manage Cards
                    </span>
                  </div>
                </div>

                <div className="body-content">
                  <div className="listing-tables mt-0">
                    {alertData && alertData.show && (
                      <div className="alerts">
                        <Alert
                          variant={alertData.type}
                          onClose={() => this.hideErrMsg()}
                          dismissible>
                          {alertData.message}
                        </Alert>
                      </div>
                    )}

                    <table className="table table-inverse table-responsive">
                      <thead className="thead-inverse">
                        <tr>
                          <th width="10" className="text-center">
                            &nbsp;
                          </th>
                          <th>Contribution Type</th>
                          {/* <th>Type</th> */}
                          <th>Amount</th>
                          <th>Payment Method</th>
                          <th>Date</th>
                          <th>Vote Expiration</th>
                          {/* <th>Status</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {loading && (
                          <tr>
                            <td align="center" colSpan={8}>
                              <span className="fas fa-spinner fa-spin" />
                            </td>
                          </tr>
                        )}
                        {!loading &&
                          Object.entries(subscriptions).length === 0 && (
                            <tr>
                              <td align="center" colSpan={8}>
                                No Subscriptions Available.
                              </td>
                            </tr>
                          )}
                        {!loading &&
                          Object.entries(subscriptions).length > 0 &&
                          subscriptions.map(item => this._renderItems(item))}
                      </tbody>
                    </table>

                    {pagging && (
                      <div className="pagging-wrapper">
                        {pagging.current &&
                          pagging.pages &&
                          pagging.totalItems && (
                            <div className="pagging-text">
                              <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                            </div>
                          )}

                        <div className="pagging-btns">
                          {pagging.previous && (
                            <span
                              className="btn btn-small"
                              onClick={() =>
                                this.getMySubscriptions(pagging.previous)
                              }>
                              Previous
                            </span>
                          )}
                          {pagging.next && (
                            <span
                              className="btn btn-small next-btn"
                              onClick={() =>
                                this.getMySubscriptions(pagging.next)
                              }>
                              Next
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {add_card_modal && (
          <AddCardModal
            show={add_card_modal}
            closeModal={() => this.toggleModal('add_card_modal', false)}
          />
        )}

        {donateModal.show && (
          <AddContribution
            show={donateModal.show}
            type={donateModal.type}
            closeModal={() =>
              this.setState({
                donateModal: {
                  show: false,
                  type: '',
                },
              })
            }
          />
        )}

        {detailsModal && (
          <SubscriptionModal
            show={detailsModal}
            data={subDetails}
            closeModal={() => this.closeModal()}
            cancelSubscription={id => this.cancelSubscription(id)}
          />
        )}
      </div>
    );
  }
}

const mapStateProps = state => ({
  menuState: state.menuState,
  isLogin: !!state.user.authToken,
  subscriptions: state.subscriptions,
  currentUser: state.user,
});

export default connect(mapStateProps, {
  getMySubscriptions,
  cancelMySubscription,
})(SubscriptionsList);
