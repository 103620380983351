/* eslint-disable no-useless-computed-key */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Col,
  Modal,
  Row,
  Form,
  Button,
  Alert,
  FormControl,
} from 'react-bootstrap';

import {SocialIcons} from '../../Components';

import {
  getAllCategories,
  getAllRegions,
} from '../../Store/Actions/FrontActions';
import {saveUserCause} from '../../Store/Actions/FrontActions';
import {appendFormData} from '../../Utils/Helpers';
import {DefaultVideo} from '../../Constants/Images';
// import { usStates } from "../../Constants";

const cuaseStatusArray = ['Approved', 'Inactive', 'Unapproved'];

const causeInitState = {
  title: '',
  category: '',
  reciever_org_name: '',
  reciever_org_email: '',
  region: '',
  state: '',
  justify_cause: '',
  explain_impact: '',
  fund_amount: '',
  cover_img: '',
  gallery_imgs: '',
  socials: [{social: 'Instagram', link: ''}],
  status: 'Unapproved',
  usStates: {},
};

class AddCause extends Component {
  state = {
    data: causeInitState,
    errors: {},
    saving: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    show: false,
    categories: {},
  };

  UNSAFE_componentWillMount = () => {
    const {show, toggleModal, categories, regions, usStates, currentUser} =
      this.props;
    // console.log("Props :", currentUser);

    this.getCategories();

    this.setState({
      show,
      toggleModal,
      categories,
      regions,
      usStates,
      currentUser,
    });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {categories, regions, usStates, currentUser} = props;
    let isAdmin = currentUser.user_type === 'Administrator' ? true : false;

    const region = this.getRegion(currentUser.state);

    this.setState({
      categories,
      regions,
      usStates,
      data: {
        ...this.state.data,
        isAdmin,
        state: currentUser.state,
        region: region ? region.region.name : '',
      },
      currentUser,
    });
  };

  getCategories = () => {
    this.props
      .getAllCategories()
      .then(res => {
        // console.log("Res", res);
      })
      .catch(err => {
        console.log('Err Res', err);
      });

    this.props
      .getAllRegions()
      .then(res => {
        // console.log("Res", res);
      })
      .catch(err => {
        console.log('Err Res', err);
      });
  };

  onChange = e => {
    const tempdata = {...this.state.data};
    tempdata[e.target.name] = e.target.value;

    this.setState({
      data: tempdata,
    });
  };

  onStateChange = e => {
    const {data} = this.state;
    const value = e.target.value;

    const stateData = this.getRegion(value);
    const region = stateData.region.name;

    this.setState({
      data: {
        ...data,
        ['state']: value,
        ['region']: region,
      },
    });
  };

  getRegion = stateId => {
    const {usStates} = this.state;

    const state = usStates.filter(item => item.id === stateId);

    return state ? state[0] : {};
  };

  setSocials = socials => {
    this.setState({
      data: {
        ...this.state.data,
        socials,
      },
    });
  };

  validateData = () => {
    const {data} = this.state;
    const errors = {};

    if (!data.title || data.title === '') {
      errors.title = 'Please enter title.';
    }
    if (!data.category || data.category === '') {
      errors.category = 'Please select Category.';
    }
    if (!data.state || data.state === '') {
      errors.state = 'Please select State.';
    }
    // if (!data.justify_cause || data.justify_cause === "") {
    //     errors.justify_cause = "Please justify your cause.";
    // }
    if (!data.explain_impact || data.explain_impact === '') {
      errors.explain_impact = 'Please explain the impact.';
    }
    if (!data.fund_amount || data.fund_amount === '') {
      errors.fund_amount = 'Please enter the amount.';
    }

    return errors;
  };

  saveCuase = () => {
    const {data, currentUser} = this.state;
    const errors = this.validateData();

    if (Object.entries(errors).length === 0) {
      const currentDate = new Date();
      const today = currentDate.getDay();

      const maxDay = currentUser.user_type === 'Administrator' ? 26 : 16;

      if (today >= maxDay) {
        if (
          !window.confirm('Your Nomination will be added in next month list.')
        ) {
          console.log('Cancel');
          return false;
        } else {
          console.log('Accept');
        }
      }

      this.setState({errors, saving: true});

      const formData = appendFormData(data);

      formData.append('socials', JSON.stringify(data.socials));

      this.props
        .saveUserCause(formData)
        .then(res => {
          console.log('Res :', res);
          this.setState({
            saving: false,
            data: res.type === 'success' ? causeInitState : data,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          this.setState({
            saving: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
          console.log(err);
        });
    } else {
      this.setState({
        errors,
      });
    }
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });

    this.props.closeModal();
  };

  onCoverPick = e => {
    const filePath = e.target.files[0];
    const objectUrl = URL.createObjectURL(filePath);

    const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];

    if (imgTypes.includes(filePath.type)) {
      console.log(filePath.size);
      if (filePath.size <= 1000000) {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: filePath,
            cover_img: objectUrl,
          },
          errors: {
            ...this.state.errors,
            cover_img_file: '',
          },
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            cover_img_file: 'Image size must be less then 1MB.',
          },
        });
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          cover_img_file: 'Please select valid Image.',
        },
      });
    }
  };
  removeGalleryImg = () => {
    this.setState({
      data: {
        ...this.state.data,
        cover_img_file: '',
        cover_img: '',
      },
    });
  };
  onMediaPick = e => {
    const filePath = e.target.files[0];
    console.log('File :', filePath);

    const mediaTypes = ['video/mp4'];

    if (mediaTypes.includes(filePath.type)) {
      if (filePath.size <= 5000000) {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: filePath,
            media_name: filePath.name,
          },
          errors: {
            ...this.state.errors,
            media_file: '',
          },
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            media_file: 'Video size must be less then 5MB.',
          },
        });
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          media_file: 'Please select valid Video File.',
        },
      });
    }
  };
  removeMedia = () => {
    this.setState({
      data: {
        ...this.state.data,
        media_file: '',
        remove_media: 'yes',
        media_name: '',
      },
    });
  };

  render() {
    const {data, errors, alertData, saving, show, categories, usStates} =
      this.state;

    return (
      <Modal className="medium" show={show} onHide={() => this.closeModal()}>
        <Modal.Header>
          <Modal.Title>Nominate Cause</Modal.Title>
          <span className="close as-link" onClick={() => this.closeModal()}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <div className="modal-headings">
              <h2>What cause would you like to nominate?</h2>
              <p>
                We can’t guarantee your cause will be selected this month but
                you can always resubmit.
              </p>
            </div>

            {alertData && alertData.show && (
              <Alert
                variant={alertData.type}
                onClose={() => this.hideErrMsg()}
                dismissible>
                {alertData.message}
              </Alert>
            )}

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Create a title driven by impact</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder=""
                    value={data.title ? data.title : ''}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.title && (
                    <span className="errorMsg">{errors.title}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="role">
                  <label>Category</label>
                  <div className="select-dropdown">
                    <select
                      name="category"
                      value={data.category ? data.category : ''}
                      onChange={e => this.onChange(e)}
                      className="form-control">
                      <option value="">Choose</option>
                      {Object.entries(categories).length > 0 &&
                        categories.map((item, index) => (
                          <option
                            value={item.id}
                            key={`category_index_${index}`}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.category && (
                    <span className="errorMsg">{errors.category}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Reciever / Organization Name</Form.Label>
                  <Form.Control
                    name="reciever_name_org"
                    type="text"
                    placeholder=""
                    value={data.reciever_name_org ? data.reciever_name_org : ''}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.reciever_name_org && (
                    <span className="errorMsg">{errors.reciever_name_org}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>Reciever / Organization Email</Form.Label>
                  <Form.Control
                    name="reciever_email"
                    type="text"
                    placeholder=""
                    value={data.reciever_email ? data.reciever_email : ''}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.reciever_email && (
                    <span className="errorMsg">{errors.reciever_email}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>Which state do you live in?</label>

                  <div className="select-dropdown">
                    <select
                      name="state"
                      value={data.state ? data.state : ''}
                      onChange={e => this.onStateChange(e)}
                      className="form-control">
                      <option value="">Choose</option>
                      {Object.entries(usStates).length > 0 &&
                        usStates.map((item, index) => (
                          <option value={item.id} key={`state_index_${index}`}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {errors.state && (
                    <span className="errorMsg">{errors.state}</span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="form-group" controlId="role">
                  <label>Region</label>
                  <Form.Control
                    name="region"
                    type="text"
                    placeholder=""
                    value={data.region ? data.region : ''}
                    disabled
                  />

                  {errors.region && (
                    <span className="errorMsg">{errors.region}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
                            <Col>
                                <Form.Group className="form-group" controlId="state">
                                    <label>How does this align with our Foundations’ mission?</label>
                                    <FormControl
                                        name="justify_cause"
                                        as="textarea"
                                        row="5"
                                        value={data.justify_cause}
                                        onChange={(e) => this.onChange(e)}
                                    />

                                    {errors.justify_cause && <span className="errorMsg">{errors.justify_cause}</span>}
                                </Form.Group>
                            </Col>
                        </Row> */}

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>What type of impact can this have if funded?</label>
                  <FormControl
                    name="explain_impact"
                    as="textarea"
                    row="5"
                    value={data.explain_impact}
                    onChange={e => this.onChange(e)}
                  />

                  {errors.explain_impact && (
                    <span className="errorMsg">{errors.explain_impact}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>What is your funding goal?</label>
                  <div className="inputWithIcon">
                    <i className="fas fa-dollar-sign" />
                    <Form.Control
                      name="fund_amount"
                      type="text"
                      placeholder=""
                      value={data.fund_amount}
                      onChange={e => this.onChange(e)}
                    />
                  </div>
                  {errors.fund_amount && (
                    <span className="errorMsg">{errors.fund_amount}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>How should funds be delivered?</label>
                  <FormControl
                    name="how_funds_deliver"
                    as="textarea"
                    row="5"
                    value={data.how_funds_deliver}
                    onChange={e => this.onChange(e)}
                  />

                  {errors.how_funds_deliver && (
                    <span className="errorMsg">{errors.how_funds_deliver}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="state">
                  <label>Add media to support your cause (optional)</label>
                  <Row className="mt-1">
                    <Col>
                      <div className="file-field-wrapper">
                        <span className="btn btn-small btn-primary">
                          <i className="fas fa-camera" /> Add Cover
                          <input
                            type="file"
                            name="cover_img_file"
                            onChange={e => {
                              this.onCoverPick(e);
                            }}
                          />
                        </span>
                      </div>
                      {data.cover_img && (
                        <div className="fileSelected">
                          <div className="">
                            <span
                              className="remove_img"
                              onClick={() => this.removeGalleryImg()}>
                              <i className="fas fa-times" />
                            </span>
                            <img src={data.cover_img} alt="" />
                          </div>
                        </div>
                      )}
                      {errors.cover_img_file && (
                        <span className="errorMsg">
                          {errors.cover_img_file}
                        </span>
                      )}
                    </Col>
                    <Col className="text-right">
                      <span className="btn btn-small btn-primary file-field-wrapper">
                        <i className="fas fa-video" /> Add Media
                        <input
                          type="file"
                          name="media_file"
                          onChange={e => {
                            this.onMediaPick(e);
                          }}
                        />
                      </span>
                      {errors.media_file && (
                        <span className="errorMsg">{errors.media_file}</span>
                      )}
                      {data.media_file && (
                        <div className="fileSelected">
                          <div className="">
                            <span
                              className="remove_img"
                              onClick={() => this.removeMedia()}>
                              <i className="fas fa-times" />
                            </span>
                            <img src={DefaultVideo} alt="" />
                            <span className="fileSelected-text">
                              {data.media_file.name}
                            </span>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="socials">
                  <label>How can people learn more about your cause?</label>
                  <SocialIcons
                    icons={data.socials}
                    updateIcons={icons => this.setSocials(icons)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {data.isAdmin && (
              <Row>
                <Col>
                  <Form.Group className="form-group" controlId="socials">
                    <label>Status</label>
                    <div className="select-dropdown">
                      <select
                        name="status"
                        value={data.status ? data.status : ''}
                        onChange={e => this.onChange(e)}
                        className="form-control">
                        <option value="">Choose</option>
                        {Object.entries(cuaseStatusArray).length > 0 &&
                          cuaseStatusArray.map((item, index) => (
                            <option key={`state_index_${index}`}>{item}</option>
                          ))}
                      </select>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Button
                  onClick={() => {
                    if (!saving) {
                      this.saveCuase();
                    }
                  }}>
                  {!saving && 'Save'}
                  {saving && <i className="fas fa-spinner fa-spin" />}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateProps = state => ({
  categories: state.allCauseCategories,
  regions: state.allCauseRegions,
  usStates: state.usStates,
  currentUser: state.user,
});

export default connect(mapStateProps, {
  getAllCategories,
  getAllRegions,
  saveUserCause,
})(AddCause);
