import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {Logo} from '../Constants/Images';

import PageNotFound from '../Pages/PageNotFound';

import Home from '../Pages/Front/Home';
import HomeNew from '../Pages/Front/HomeNew';
import CauseList from '../Pages/Front/CausesList';
import Subscriptions from '../Pages/Front/Subscriptions';
import PaymentLists from '../Pages/Front/SubscriptionPayments';
import Nominations from '../Pages/Front/Nominations';
import Login from '../Pages/Auth/Login';
import Signup from '../Pages/Auth/Signup';

import AdminRoutes from './AdminRoutes';
import AuthRoute from './AuthRoute';
import GuestRoute from './GuestRoutes';
import Root from '../Pages/Admin/Root';
import Profile from '../Pages/Front/Profile';
import causeDetails from '../Pages/Front/CauseDetails';
import CategoryPage from '../Pages/Front/CategoryPage';
import MyCausesList from '../Pages/Front/MyCausesList';
import TermsPage from '../Pages/Front/TermsPage';
import PrivacyPolicy from '../Pages/Front/PrivacyPolicy';

import '../Utils/Scripts';
import '../Utils/userLoginCheck';

import {getAllStates} from '../Store/Actions/CommanAction';

const RoutesFile = props => {
  props.getAllStates();
  return (
    <div className="page-wrapper">
      <div id="loader" className="loader">
        <div className="loader-logo">
          <img src={Logo} alt="" />
        </div>
      </div>
      <Router>
        <Switch>
          <Route exact path="/" component={HomeNew} />
          <GuestRoute exact path="/register" component={Signup} />
          <GuestRoute exact path="/login" component={Login} />
          <GuestRoute exact path="/verify-otp" component={Login} />

          <AdminRoutes path="/admin" component={Root} />

          <AuthRoute exact path="/causes" component={CauseList} />
          <AuthRoute path="/profile" component={Profile} />
          <AuthRoute
            exact
            path="/past-contributions"
            component={Subscriptions}
          />
          <AuthRoute
            exact
            path="/past-contributions/page/:page"
            component={Subscriptions}
          />
          <AuthRoute
            exact
            path="/past-contribution/payments/:id"
            component={PaymentLists}
          />
          <AuthRoute exact path="/my-nominations" component={MyCausesList} />
          <AuthRoute exact path="/nominations" component={Nominations} />
          <AuthRoute
            exact
            path="/my-nominations/page/:id"
            component={MyCausesList}
          />
          <Route exact path="/cause/:causeId" component={causeDetails} />
          <Route exact path="/category/:categoryId" component={CategoryPage} />

          <Route exact path="/terms-and-conditions" component={TermsPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default connect(null, {getAllStates})(RoutesFile);
