import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";

import { getAllRegions } from "../Store/Actions/FrontActions";

class HomeRegionsbox extends Component {
    state = {
        regions: [],
        selectedRegion: "",
    };

    UNSAFE_componentWillMount = () => {
        const { regions, getAllRegions, region } = this.props;

        if (Object.entries(regions).length === 0) {
            getAllRegions();
        }

        this.setState({ regions, selectedRegion: region });
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        const { regions, region } = props;
        // console.log("new region :", region);

        this.setState({ regions, selectedRegion: region });
    };

    render() {
        const { regions, selectedRegion } = this.state;
        return (
            <div className="home-regions-dropdown">
                <Form.Group>
                    <Form.Select value={selectedRegion} onChange={(e) => this.props.changeRegion(e.target.value)}>
                        <option value="">All Regions</option>
                        {Object.entries(regions).length > 0 &&
                            regions.map((item) => (
                                <option key={`home-dropdown-region-${item.id}`} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                    </Form.Select>
                </Form.Group>
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    regions: state.allCauseRegions,
});
export default connect(mapStateProps, { getAllRegions })(HomeRegionsbox);
