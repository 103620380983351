import React, { Component } from "react";
import { Accordion, Button, Form, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";

import { CauseListItem, FrontSidebar, Breadcrumb, Header, AddCauseFront } from "../../Components";
import { getCategoryCauses, updateCauseVote, getAllCategories, getAllRegions } from "../../Store/Actions/FrontActions";
import { getAllStates } from "../../Store/Actions/CommanAction";
import { updateUrl } from "../../Utils/Helpers";

class CategoryPage extends Component {
    state = {
        categoryId: "",
        causesRequests: {},
        causeLoading: false,
        currentUser: {},
        currentCategory: {},
        stateInfo: {},
        usStates: {},
        categories: [],
        regions: [],
        filterData: {
            region: "",
            category: "",
        },
        isLogin: false,
        addCauseModal: false,
    };

    UNSAFE_componentWillMount = () => {
        const { causesRequests } = this.props;
        const categoryId = this.props.match.params.categoryId;

        this.props.getAllStates().catch((err) => {
            console.log("Err :", err);
        });

        this.props.getAllCategories().catch((err) => {
            console.log("Err :", err);
        });

        this.props.getAllRegions().catch((err) => {
            console.log("Err :", err);
        });

        this.getCategoryCauses(categoryId);

        this.setState({
            categoryId,
            causesRequests,
            filterData: {
                ...this.state.filterData,
                category: categoryId,
            },
        });
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        const { causesRequests, usStates, categories, regions } = props;
        const categoryId = this.props.match.params.categoryId;

        this.setState(
            {
                categoryId,
                causesRequests,
                usStates,
                categories,
                regions,
            },
            () => {
                this.setCurrentCate();
            }
        );
    };

    updateFilter = (e) => {
        const fieldName = e.target.name;

        const filterData = {
            ...this.state.filterData,
        };

        if (fieldName === "region") {
            filterData[fieldName] = e.target.value;
        } else if (fieldName === "category") {
            if (e.target.value !== "all") {
                filterData[fieldName] = e.target.value;

                const newUrl = `/category/${e.target.value}`;
                updateUrl(newUrl, this.props.history);
            }
        }

        this.setState(
            {
                filterData,
            },
            () => {
                const { category, region } = this.state.filterData;
                this.getCategoryCauses(category, region);
            }
        );
    };

    setCurrentCate = () => {
        const { categories, categoryId } = this.state;
        let currentCategory = {};
        if (categories && Object.entries(categories).length > 0) {
            currentCategory = categories.filter((item) => item.id === categoryId);
        }

        this.setState({
            currentCategory: currentCategory[0],
        });
    };

    getCategoryCauses = (categoryId, regionId = "", type = "update") => {
        const { causeLoading } = this.state;

        if (!causeLoading) {
            this.setState({
                causeLoading: true,
            });

            const formData = new FormData();
            formData.append("category_id", categoryId);
            formData.append("region_id", regionId);

            this.props
                .getCategoryCauses(formData, type)
                .then((res) => {
                    this.setState({
                        causeLoading: false,
                    });
                })
                .catch((err) => {
                    console.log("Err :", err);
                });
        }
    };

    renderCauseLoop = () => {
        const { causesRequests } = this.state;

        const currentIndex =
            Object.entries(causesRequests).length > 0 && causesRequests[0].title !== "Current Month" ? 0 : 1;

        return (
            <Accordion defaultActiveKey={[currentIndex]}>
                {causesRequests.map((item, index) => {
                    if (item.title !== "Current Month") {
                        return this.renderYearItem(item, index);
                    } else {
                        return <span key={`year_key_${item.month}`}></span>;
                    }
                })}
            </Accordion>
        );
    };

    renderYearItem = (item, index) => {
        const causes = item.causes;
        return (
            <Accordion.Item key={`year_key_${item.month}`} eventKey={index}>
                <Accordion.Header>
                    <div className="heading">
                        <h2>{item.title}</h2>
                        <div className="distributed-text">
                            {item.distributed && (
                                <>
                                    <span>Distributed</span> ${item.distributed}
                                </>
                            )}
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="list-wrapper">
                        {Object.entries(causes).length > 0 &&
                            causes.map((item, index) => (
                                <CauseListItem
                                    key={`cause_${item.id}_${index}`}
                                    item={item}
                                    updateCauseVote={(id) => this.updateCauseVote(id)}
                                />
                            ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        );
    };

    updateCauseVote = ($causeId) => {
        const { filterData } = this.state;

        const voteData = new FormData();
        voteData.append("cause_id", $causeId);
        voteData.append("category_id", filterData.category);
        voteData.append("region_id", filterData.region);
        voteData.append("page_name", "category_page");

        this.props
            .updateCauseVote(voteData, "category_page")
            .then((res) => {
                // console.log("Res", res);
            })
            .catch((err) => {
                console.log("Err :", err);
            });
    };

    toggleModal = (modal, state) => {
        this.setState({
            [modal]: state,
        });
    };

    render() {
        const { causeLoading, currentCategory, causesRequests, filterData, regions, categories, addCauseModal } =
            this.state;
        const { location, menuState } = this.props;
        const isOpen = menuState ? "open" : "";

        const isCurrentMonth =
            Object.entries(causesRequests).length > 0 && causesRequests[0].title === "Current Month" ? true : false;
        const currentMonthCategories = isCurrentMonth ? causesRequests[0].causes : [];

        return (
            <div className={`admin-wrapper ${isOpen}`}>
                <FrontSidebar location={location} />
                <div className="admin-content">
                    <Header />
                    <div className="admin-body">
                        {/**
                         *
                         */}
                        <div className="cause-filter-wrapper">
                            <div className="filter-form">
                                <div className="container">
                                    <Form className="d-flex">
                                        <Form.Group className="form-group mr-2">
                                            <Form.Label>Regions</Form.Label>
                                            <div className="select-dropdown">
                                                <select
                                                    name="region"
                                                    className="form-control"
                                                    value={filterData.region}
                                                    onChange={(e) => this.updateFilter(e)}
                                                >
                                                    <option value="all">All Regions</option>
                                                    {Object.entries(regions).length > 0 &&
                                                        regions.map((item, index) => (
                                                            <option key={`region_${item.id}`} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="form-group mr-2">
                                            <Form.Label>Categories</Form.Label>
                                            <div className="select-dropdown">
                                                <select
                                                    name="category"
                                                    className="form-control"
                                                    value={filterData.category}
                                                    onChange={(e) => this.updateFilter(e)}
                                                >
                                                    <option value="all">All Categories</option>
                                                    {Object.entries(categories).length > 0 &&
                                                        categories.map((item, index) => (
                                                            <option key={`category_${item.id}`} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="body-card">
                                <div className="heading mt-3">
                                    <h2>{currentCategory && currentCategory.name}</h2>
                                </div>
                                {/* <Breadcrumb parent={{ title: "Category", link: "" }} title="Payments" /> */}
                                <Breadcrumb
                                    history={this.props.history}
                                    className="mt-2 mb-2"
                                    title={currentCategory && currentCategory.name}
                                />

                                <div className="body-content">
                                    <div className="cuases-lists-wrapper">
                                        <div className="months-wrapper">
                                            {causesRequests && Object.entries(causesRequests).length > 0 && (
                                                <Tabs
                                                    defaultActiveKey={"current-month"}
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3 mt-4"
                                                >
                                                    <Tab eventKey="current-month" title="Current Month">
                                                        {isCurrentMonth && (
                                                            <div className="list-wrapper">
                                                                {currentMonthCategories &&
                                                                    Object.entries(currentMonthCategories).length > 0 &&
                                                                    currentMonthCategories.map((item, index) => (
                                                                        <CauseListItem
                                                                            key={`cause_${item.id}_${index}`}
                                                                            item={item}
                                                                            updateCauseVote={(id) =>
                                                                                this.updateCauseVote(id)
                                                                            }
                                                                        />
                                                                    ))}
                                                            </div>
                                                        )}

                                                        {!isCurrentMonth && (
                                                            <div className="mt-5 mb-4 text-center">
                                                                <div className="my-impact-headings appretiation-text">
                                                                    <p>
                                                                        No one has submitted Nominations for this month
                                                                        yet. Be the first one by clicking below.
                                                                    </p>
                                                                    <Button
                                                                        className="btn btn-primary btn-small add-new-btn"
                                                                        onClick={() =>
                                                                            this.toggleModal("addCauseModal", true)
                                                                        }
                                                                    >
                                                                        Add Nomination
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Tab>

                                                    <Tab eventKey="history" title="History">
                                                        {Object.entries(causesRequests).length > 0 &&
                                                            this.renderCauseLoop()}

                                                        {isCurrentMonth &&
                                                            Object.entries(causesRequests).length === 1 && (
                                                                <div className="no-results text-center">
                                                                    No Cuase Found.
                                                                </div>
                                                            )}
                                                    </Tab>
                                                </Tabs>
                                            )}

                                            {!causeLoading && Object.entries(causesRequests).length === 0 && (
                                                <div className="no-results text-center">No Cuase Found.</div>
                                            )}

                                            {causeLoading && (
                                                <span className="causes-loader text-center">
                                                    <i className="fas fa-spinner fa-spin fa-2x white" />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {addCauseModal && (
                    <AddCauseFront show={addCauseModal} closeModal={() => this.toggleModal("addCauseModal", false)} />
                )}
            </div>
        );
    }
}

const mapStateProps = (state) => ({
    menuState: state.menuState,
    currentUser: state.user,
    isLogin: !!state.user.authToken,
    causesRequests: state.categoryCauses,
    usStates: state.usStates,
    categories: state.allCauseCategories,
    regions: state.allCauseRegions,
});

export default connect(mapStateProps, {
    getCategoryCauses,
    updateCauseVote,
    getAllStates,
    getAllCategories,
    getAllRegions,
})(CategoryPage);
