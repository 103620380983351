import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Modal, Row, Form, Button, Alert} from 'react-bootstrap';

import {addExpense} from '../../../Store/Actions/AdminAction';
import {getAllRegions} from '../../../Store/Actions/FrontActions';
import {appendFormData} from '../../../Utils/Helpers';

const initialUserState = {
  title: '',
  type: '',
  description: '',
  region: '',
  amount: '',
  expense_date: '',
  deduct_from: 'Global Funds',
};

class AddExpense extends Component {
  state = {
    data: initialUserState,
    errors: {},
    saving: false,
    regions: [],
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const {show, toggleModal} = this.props;

    this.props.getAllRegions().catch(err => {
      console.log('Err :', err);
    });

    this.setState({
      show,
      toggleModal,
    });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {regions} = props;
    this.setState({
      regions,
    });
  };

  onChange = e => {
    const data = {...this.state.data};
    data[e.target.name] = e.target.value;

    this.setState({
      data,
    });
  };

  validateData = () => {
    const {data} = this.state;
    const errors = {};

    if (!data.title || data.title === '') {
      errors.title = 'Please enter Name.';
    }
    if (!data.type || data.type === '') {
      errors.type = 'Please select Type.';
    }
    if (!data.description || data.description === '') {
      errors.description = 'Please enter Description.';
    }
    if (
      (data.type === 'Regional Funds' && !data.region) ||
      data.region === ''
    ) {
      errors.region = 'Please select Region.';
    }
    if (!data.amount || data.amount === '') {
      errors.amount = 'Please enter Amount.';
    }
    if (!data.deduct_from || data.deduct_from === '') {
      errors.deduct_from = 'Please enter Amount.';
    }

    return errors;
  };

  saveExpense = () => {
    const {data} = this.state;
    const errors = this.validateData();

    if (Object.entries(errors).length === 0) {
      this.setState({errors, saving: true});

      const formData = appendFormData(data);

      this.props
        .addExpense(formData)
        .then(res => {
          console.log(res);

          this.setState({
            saving: false,
            data: res.type === 'success' ? initialUserState : data,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          this.setState({
            saving: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
          console.log(err);
        });
    } else {
      this.setState({
        errors,
      });
    }
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  addAmontField = () => {
    const {data, errors} = this.state;
    return (
      <Form.Group className="form-group" controlId="amount">
        <Form.Label>Amount</Form.Label>
        <div className="inputWithIcon">
          <i className="fas fa-dollar-sign" />
          <Form.Control
            name="amount"
            type="text"
            placeholder="Enter Amount"
            value={data.amount}
            onChange={e => this.onChange(e)}
          />
        </div>
        {errors.amount && <span className="errorMsg">{errors.amount}</span>}
      </Form.Group>
    );
  };

  render() {
    const {regions, data, errors, show, alertData, saving} = this.state;

    return (
      <Modal
        className="medium"
        show={show}
        onHide={() => this.props.closeModal()}>
        <Modal.Header>
          <Modal.Title>Add Expense</Modal.Title>
          <span
            className="close as-link"
            onClick={() => this.props.closeModal()}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          {alertData && alertData.show && (
            <Alert
              variant={alertData.type}
              onClose={() => this.hideErrMsg()}
              dismissible>
              {alertData.message}
            </Alert>
          )}
          <div className="modal-form-wrapper">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Title"
                    value={data.title}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.title && (
                    <span className="errorMsg">{errors.title}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-group" controlId="type">
                  <Form.Label>Expense Type</Form.Label>
                  <div className="select-dropdown">
                    <select
                      name="type"
                      value={data.type}
                      onChange={e => this.onChange(e)}
                      className="form-control">
                      <option value="">Select</option>
                      <option>Bank fees</option>
                      <option>Event</option>
                      <option>Accounting</option>
                      <option>Legal</option>
                      <option>Other</option>
                    </select>
                  </div>
                  {errors.type && (
                    <span className="errorMsg">{errors.type}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group
                  className="form-group mb-0"
                  controlId="expense_date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    name="expense_date"
                    type="date"
                    placeholder=""
                    value={data.expense_date}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.expense_date && (
                    <span className="errorMsg">{errors.expense_date}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="deduct_from">
                  <Form.Label>Deduct From</Form.Label>
                  <div className="select-dropdown">
                    <select
                      name="deduct_from"
                      value={data.deduct_from}
                      onChange={e => this.onChange(e)}
                      className="form-control">
                      <option value="">Select</option>
                      <option>Regional Funds</option>
                      <option>Global Funds</option>
                      <option>Emergency Funds</option>
                    </select>
                  </div>
                  {errors.deduct_from && (
                    <span className="errorMsg">{errors.deduct_from}</span>
                  )}
                </Form.Group>
              </Col>

              {data.deduct_from === 'Regional Funds' && (
                <Col md={6} sm={6} xs={12}>
                  <Form.Group className="form-group" controlId="region">
                    <Form.Label>Region</Form.Label>
                    <div className="select-dropdown">
                      <select
                        name="region"
                        value={data.region}
                        onChange={e => this.onChange(e)}
                        className="form-control">
                        <option value="">Select Region</option>
                        {regions &&
                          Object.entries(regions).length > 0 &&
                          regions.map((item, index) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                    {errors.region && (
                      <span className="errorMsg">{errors.region}</span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>

            <Row>
              <Col md={12}>{this.addAmontField()}</Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-0" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    type="text"
                    placeholder="Description"
                    value={data.description}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.description && (
                    <span className="errorMsg">{errors.description}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button
            onClick={() => {
              if (!saving) {
                this.saveExpense();
              }
            }}>
            {!saving && 'Save'}
            {saving && <i className="fas fa-spinner fa-spin" />}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateProps = state => ({
  regions: state.allCauseRegions,
});

export default connect(mapStateProps, {addExpense, getAllRegions})(AddExpense);
