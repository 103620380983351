import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Col, Form, Row} from 'react-bootstrap';

import moment from 'moment';
import MomentDate from 'react-moment';

import {
  getDashboardData,
  approveUsers,
  approveCauses,
} from '../../Store/Actions/AdminAction';
import {getAllRegions} from '../../Store/Actions/FrontActions';
import {BarChart, LineChart} from '../../Components/Charts';
import {convertNumber} from '../../Utils/Helpers';
import {YearMonths, nominationsReport} from '../../Constants';
import FrontRemainingAmount from '../../Components/Modals/FrontRemainingAmount';
import NominationFundDistribution from '../../Components/Admin/Modals/NominationFundDistribution';

class Dashboard extends Component {
  state = {
    loading: false,
    currentRegion: '',
    regions: [],
    headingData: {
      globalFunds: 0,
      emergencyFunds: 0,
      distributedAmount: 0,
      activeCuases: 0,
    },
    recentCauses: [],
    recentUsers: [],
    causesReport: [],
    paymentReport: {},
    alertData: {},
    availableFundsModal: false,
    ditributeNominations: false,
  };

  UNSAFE_componentWillMount = () => {
    this.props
      .getAllRegions()
      .then(res => {})
      .catch(err => {
        console.log('Err :', err);
      });
    this.getDashBoardData();
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {usStates, regions} = props;

    this.setState({usStates, regions});
  };

  getRegionData = e => {
    const value = e.target.value;
    // console.log("currentRegion :", value);

    this.setState(
      {
        currentRegion: value,
      },
      () => {
        this.getDashBoardData();
      },
    );
  };

  getDashBoardData = () => {
    const {loading, currentRegion} = this.state;

    if (!loading) {
      this.props
        .getDashboardData(currentRegion)
        .then(res => {
          const {
            headingData,
            recentCauses,
            recentUsers,
            causesReport,
            paymentReport,
          } = res.data;

          this.setState({
            headingData,
            recentCauses,
            recentUsers,
            causesReport,
            paymentReport,
            loading: false,
          });
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
          console.log('err :', err);
        });
    }
  };

  renderCause = cause => {
    const timestamp = moment.utc(parseInt(cause.add_time) * 1000);
    return (
      <tr key={`causes_key_${cause.id}`}>
        <td>{cause.title}</td>
        <td>${cause.fund_amount}</td>
        <td>{cause.category ? cause.category.name : ''}</td>
        <td>
          <MomentDate date={timestamp} format="DD/MM/YYYY hh:mm:ss" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span
              className="btn btn-small-xs success"
              onClick={() => this.approveCauses(cause.id)}>
              Approve
            </span>
          </div>
        </td>
      </tr>
    );
  };

  approveCauses = id => {
    this.props
      .approveCauses(id)
      .then(res => {
        const {causes} = res;

        this.setState({
          recentCauses: causes,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  renderUser = item => {
    const timestamp = moment.utc(parseInt(item.add_time) * 1000);
    return (
      <tr key={`user_key_${item.id}`}>
        <td>{item.name}</td>
        <td>+{item.phone}</td>

        <td className="text-center" valign="middle">
          <MomentDate date={timestamp} format="DD/MM/YYYY" />
        </td>
      </tr>
    );
  };

  approveUsers = id => {
    this.props
      .approveUsers(id)
      .then(res => {
        const {recentUsers} = res;

        this.setState({
          recentUsers,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  toggleModal = (modal, status) => {
    this.setState({
      [modal]: status,
    });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  render() {
    const {
      alertData,
      headingData,
      recentCauses,
      recentUsers,
      causesReport,
      paymentReport,
      currentRegion,
      regions,
      availableFundsModal,
      ditributeNominations,
    } = this.state;

    const monthText = new Date();

    return (
      <div className="admin-body">
        <div className="container">
          <div className="body-card">
            <div className="heading d-flex align-items-center mt-4 mb-3">
              <h2>Dashboard</h2>
              <div className="filter-options-wrapper">
                <div className="search-filter">
                  <Form.Group>
                    <div className="select-dropdown">
                      <select
                        name="category"
                        value={currentRegion ? currentRegion : ''}
                        onChange={e => this.getRegionData(e)}
                        className="form-control">
                        <option value="">All Regions</option>
                        {regions &&
                          Object.entries(regions).length > 0 &&
                          regions.map((item, index) => (
                            <option
                              key={`region_key_${index}_${item.id}`}
                              value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Form.Group>
                </div>

                <button
                  type="button"
                  className="btn btn-small btn-primary ml-0"
                  onClick={() => this.setState({ditributeNominations: true})}>
                  Distribute Nomination
                </button>
              </div>
            </div>

            <div className="dashboard-content">
              {alertData && alertData.show && (
                <div className="alerts">
                  <Alert
                    variant={alertData.type}
                    onClose={() => this.hideErrMsg()}
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}

              {/* Heading Part */}
              <Row>
                <Col>
                  <div
                    className="card as-link"
                    onClick={() =>
                      this.toggleModal('availableFundsModal', true)
                    }>
                    <div className="top-card">
                      <span className="icon fas fa-dollar-sign"></span>
                      <div className="top-card-content">
                        <h2>${convertNumber(headingData.distributedAmount)}</h2>
                        <p>
                          {' '}
                          {YearMonths[monthText.getMonth()]} Total Contribution
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="top-card">
                      <span className="icon fas fa-dollar-sign"></span>
                      <div className="top-card-content">
                        <h2>${convertNumber(headingData.globalFunds)}</h2>
                        <p>Global Funds</p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="top-card">
                      <span className="icon fas fa-dollar-sign"></span>
                      <div className="top-card-content">
                        <h2>${convertNumber(headingData.emergencyFunds)}</h2>
                        <p>Emergency Funds</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="card">
                    <div className="top-card">
                      <span className="icon fas fa-ribbon"></span>
                      <div className="top-card-content">
                        <h2>{convertNumber(headingData.activeCuases)}</h2>
                        <p>Active Cuases</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {/**
               * Charts
               */}
              <Row className="mt-4">
                <Col md={6}>
                  <div className="card">
                    <div className="card-content">
                      <h3>Causes Report</h3>
                      <div className="cause-lists">
                        {causesReport && (
                          <BarChart
                            labels={causesReport.labels}
                            data={causesReport.causes}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="card">
                    <div className="card-content">
                      <div className="card-heading">
                        <h3>Donation Reports</h3>
                      </div>
                      <div className="cause-lists">
                        {paymentReport && (
                          <LineChart
                            labels={paymentReport.labels}
                            data={paymentReport.donations}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {/**
               * Recent Causes Lists
               */}
              <Row className="mt-4">
                <Col md={7}>
                  <div className="card">
                    <div className="card-content">
                      <h3>Recent Cause Request</h3>
                      <div className="cause-lists listing-tables">
                        <table className="table table-inverse table-responsive mb-0">
                          <thead className="thead-inverse">
                            <tr>
                              <th>Title</th>
                              <th>Fund Amount</th>
                              <th>Category</th>
                              <th>Date</th>
                              <th width="50" className="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(recentCauses).length === 0 && (
                              <tr>
                                <td align="center" colSpan={7}>
                                  No Causes Available.
                                </td>
                              </tr>
                            )}
                            {Object.entries(recentCauses).length > 0 &&
                              recentCauses.map(cause =>
                                this.renderCause(cause),
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="card">
                    <div className="card-content">
                      <h3>Recent Users</h3>
                      <div className="cause-lists listing-tables">
                        <table className="table table-inverse table-responsive mb-0">
                          <thead className="thead-inverse">
                            <tr>
                              <th>Name</th>
                              <th>Phone</th>
                              <th width="120" className="text-center">
                                Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(recentUsers).length === 0 && (
                              <tr>
                                <td align="center" colSpan={3}>
                                  No Users Available.
                                </td>
                              </tr>
                            )}
                            {Object.entries(recentUsers).length > 0 &&
                              recentUsers.map(item => this.renderUser(item))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {availableFundsModal && (
          <FrontRemainingAmount
            show={availableFundsModal}
            closeModal={() => this.toggleModal('availableFundsModal', false)}
          />
        )}

        {ditributeNominations && (
          <NominationFundDistribution
            nominations={nominationsReport}
            show={ditributeNominations}
            hideModal={() => this.setState({ditributeNominations: false})}
          />
        )}
      </div>
    );
  }
}

const mapStateProps = state => ({
  menuState: state.menuState,
  regions: state.allCauseRegions,
});

export default connect(mapStateProps, {
  getDashboardData,
  approveUsers,
  approveCauses,
  getAllRegions,
})(Dashboard);
