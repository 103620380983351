import React, { useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import MomentDate from "react-moment";

const EditSubscription = (props) => {
    const { show } = props;

    const [data, setData] = useState(props.data);
    const [saving, setSaving] = useState(false);
    const [alertData, setAlert] = useState({
        show: false,
        type: "",
        message: "",
    });

    const onChange = (e) => {
        const tempData = { ...data };

        tempData[e.target.name] = e.target.value;

        setData(tempData);
    };

    const updateSubscription = (data) => {
        if (!saving) {
            const formData = new FormData();
            formData.append("id", data.id);
            formData.append("status", data.status);

            setSaving(true);
            props
                .updateSubscription(formData)
                .then((res) => {
                    setAlert({
                        show: true,
                        type: res.type === "error" ? "danger" : res.type,
                        message: res.message,
                    });
                })
                .catch((err) => {
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message,
                    });
                });
        }
    };

    const hideErrMsg = () => {
        setAlert({
            show: false,
            type: "",
            message: "",
        });
    };

    const closeModal = () => {
        props.closeModal();
    };

    const startTime = moment.utc(parseInt(data.start_date) * 1000);
    const endTime = moment.utc(parseInt(data.end_date) * 1000);

    return (
        <Modal className="medium" show={show} onHide={() => closeModal()}>
            <Modal.Header>
                <Modal.Title>Subscription</Modal.Title>
                <span className="close as-link" onClick={() => closeModal()}>
                    x
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="subdesc-wrapper">
                    {alertData && alertData.show && (
                        <div className="alerts">
                            <Alert variant={alertData.type} onClose={() => hideErrMsg()} dismissible>
                                {alertData.message}
                            </Alert>
                        </div>
                    )}
                    <Row>
                        <Col md={6}>
                            <p>
                                <span>Region - </span> {data.region.name}
                            </p>
                        </Col>
                        <Col md={6}>
                            <p>
                                <span>Amount - </span> ${data.amount}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <p>
                                <span>Contribution Type - </span> {data.contribution_type}
                            </p>
                        </Col>
                        <Col md={6}>
                            {data.pledge_type && (
                                <p>
                                    <span>Pledge Type - </span> {data.pledge_type}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <p>
                                <span>Start From - </span> <MomentDate date={startTime} format="DD/MM/YYYY hh:mm:ss" />
                            </p>
                        </Col>
                        <Col md={6}>
                            <p>
                                <span>Expired At - </span> <MomentDate date={endTime} format="DD/MM/YYYY hh:mm:ss" />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="form-group" controlId="status">
                                <label>Status</label>
                                <div className="select-dropdown">
                                    <select
                                        name="status"
                                        value={data.status}
                                        onChange={(e) => onChange(e)}
                                        className="form-control"
                                    >
                                        <option value="">Choose Status</option>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col md={6}>
                            <span className="btn btn-small " onClick={() => updateSubscription(data)}>
                                {!saving && "Save"}
                                {saving && <i className="fas fa-spinner fa-spin" />}
                            </span>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditSubscription;
