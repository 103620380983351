import React, {Component} from 'react';
import {Alert, Col, Form, Row, Tab, Tabs} from 'react-bootstrap';

import RichTextEditor from '../../Components/RichEditor';

import {Breadcrumb} from '../../Components';
import {appendFormData, updateUrl} from '../../Utils/Helpers';
import {connect} from 'react-redux';

import {saveTermPages, getTermPages} from '../../Store/Actions/AdminAction';

class PagesContent extends Component {
  state = {
    data: {
      terms_page_content: '',
      privacy_page_content: '',
    },
    errors: {},
    is_saving: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    isAuthentication: false,
    activeTab: 'terms-condition',
  };

  UNSAFE_componentWillMount = () => {
    const {termPages} = this.props;
    const newState = {
      data: termPages,
    };

    if (Object.entries(termPages).length === 0) {
      this.props
        .getTermPages()
        .then(res => {})
        .catch(err => {
          console.log('Err :', err);
        });
    }

    if (window.location.hash) {
      const hash = window.location.hash.substring(1);

      newState.activeTab = hash;
    }

    this.setState(newState);
  };

  UNSAFE_componentWillReceiveProps = ({termPages}) => {
    this.setState({
      data: termPages,
    });
  };

  setUrlHash = activeTab => {
    this.setState({
      activeTab,
    });

    // const newUrl = `/admin/pages-content/#${activeTab}`;
    // updateUrl(newUrl, this.props.history);
  };

  updateContent = (name, content) => {
    const newdata = this.state.data;
    newdata[name] = content;

    console.log('Data:', newdata);

    this.setState({
      data: newdata,
    });
  };

  savePages = () => {
    const {is_saving, data} = this.state;

    console.log('Data', data);

    if (!is_saving) {
      this.setState({is_saving: true});
      const formData = appendFormData(data);

      this.props
        .saveTermPages(formData)
        .then(res => {
          this.setState({
            is_saving: false,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          this.setState({
            is_saving: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
      },
    });
  };

  render() {
    const {data, errors, is_saving, alertData, activeTab} = this.state;

    return (
      <>
        {alertData && alertData.show && (
          <div className="alerts p-0">
            <Alert
              variant={alertData.type}
              onClose={() => this.hideErrMsg()}
              dismissible>
              {alertData.message}
            </Alert>
          </div>
        )}

        <div className="verticle-tabs mt-3 mb-2">
          <Tabs
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            onSelect={k => this.setUrlHash(k)}>
            <Tab
              eventKey="terms-condition"
              title="Terms & Condition"
              tabAttrs={{link: '#terms-condition'}}>
              <div className="tab-heading">
                <h2>Terms & Condition</h2>
              </div>

              <Row>
                <Col>
                  <Form.Group
                    className="form-group"
                    controlId="terms_page_content">
                    <label>Content</label>
                    <RichTextEditor
                      name="terms_page_content"
                      value={data.terms_page_content}
                      onChange={content =>
                        this.updateContent('terms_page_content', content)
                      }
                    />

                    {errors.terms_page_content && (
                      <span className="errorMsg">
                        {errors.terms_page_content}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.savePages()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>
            <Tab
              eventKey="privacy-policy"
              title="Privacy & Policy"
              tabAttrs={{link: '#privacy-policy'}}>
              <div className="tab-heading">
                <h2>Privacy & Policy</h2>
              </div>

              <Row>
                <Col>
                  <Form.Group
                    className="form-group"
                    controlId="privacy_page_content">
                    <label>Content</label>
                    <RichTextEditor
                      name="privacy_page_content"
                      value={data.privacy_page_content}
                      onChange={content => {
                        this.updateContent('privacy_page_content', content);
                      }}
                    />

                    {errors.privacy_page_content && (
                      <span className="errorMsg">
                        {errors.privacy_page_content}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.savePages()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateProps = state => ({
  termPages: state.termPages,
});

export default connect(mapStateProps, {saveTermPages, getTermPages})(
  PagesContent,
);
