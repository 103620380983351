import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';
import moment from 'moment';
import MomentDate from 'react-moment';

import {
  Header,
  FrontSidebar,
  Breadcrumb,
  EditCauseFront,
} from '../../Components';
import {
  getMyCauseRequests,
  removeMyCause,
} from '../../Store/Actions/FrontActions';
import {updateUrl} from '../../Utils/Helpers';

class MyCausesList extends Component {
  state = {
    loading: false,
    myCauses: {},
    pagging: {},
    page: 1,
    bulk_action_id: [],
    usStates: {},
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    this.getMyCauseRequests(1);
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {myCauses, usStates} = props;

    this.setState({
      myCauses,
      usStates,
    });
  };

  deleteCause = id => {
    const formData = new FormData();
    formData.append('id', id);
    this.props
      .removeMyCause(formData)
      .then(res => {
        this.setState({
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  enableEditModal = cause => {
    const tempCause = {...cause};
    this.setState({
      edit_cause_modal: true,
      edit_cause: tempCause,
    });
  };

  getMyCauseRequests = page => {
    const {loading} = this.state;

    if (!loading) {
      this.setState({loading: true});

      this.props
        .getMyCauseRequests(page)
        .then(res => {
          const tempState = {...this.state};

          if (res.type === 'success') {
            const CurrentPageNo =
              res.pagging && res.pagging.current
                ? parseInt(res.pagging.current)
                : 1;

            const newUrl =
              parseInt(CurrentPageNo) === 1
                ? `/my-nominations`
                : `/my-nominations/page/${page}`;
            updateUrl(newUrl, this.props.history);

            tempState.pagging = res.pagging;
            tempState.page = res.pagging;
          }

          tempState.loading = false;

          this.setState(tempState);
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  cancelSubscription = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .cancelMySubscription(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            detailsModal: false,
            subDetails: {},
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  _renderItems = cause => {
    const {bulk_action_id} = this.state;

    const stateId = this.getState(cause.state);
    const timestamp = moment.utc(parseInt(cause.add_time) * 1000);

    return (
      <tr key={`causes_key_${cause.id}`}>
        <td align="center">
          <input
            type="checkbox"
            name="bulk_action[]"
            value={cause.id}
            checked={
              bulk_action_id.indexOf(`${cause.id}`) !== -1 ? true : false
            }
            onChange={e => this.toggleBulkItems(e)}
          />
        </td>
        <td>{cause.title}</td>
        <td>${cause.fund_amount}</td>
        <td>{cause.category ? cause.category.name : ''}</td>
        <td>{stateId.name}</td>
        <td>{cause.status}</td>
        <td>
          <MomentDate date={timestamp} format="MM/DD/YYYY" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span
              className="actionIcon"
              onClick={() => this.enableEditModal(cause)}>
              <i className="fas fa-pen" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => this.deleteCause(cause.id)}>
              <i className="fas fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  getState = stateId => {
    const {usStates} = this.state;
    if (usStates && Object.entries(usStates).length > 0) {
      const state = usStates.filter(item => item.id === stateId);
      return state[0];
    } else {
      return {};
    }
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      edit_cause_modal: false,
      edit_cause: {},
    });
  };

  render() {
    const {
      alertData,
      loading,
      myCauses,
      pagging,
      edit_cause_modal,
      edit_cause,
    } = this.state;
    const {location, menuState} = this.props;
    const isOpen = menuState ? 'open' : '';

    return (
      <div className={`admin-wrapper ${isOpen}`}>
        <FrontSidebar location={location} />
        <div className="admin-content">
          <Header />
          <div className="admin-body">
            <div className="container">
              {/* <Breadcrumb title="My Nominations" /> */}
              <div className="body-card">
                <div className="heading">
                  <h2>My Nominations</h2>
                  <div className="filter-options-wrapper"></div>
                </div>

                <div className="body-content">
                  <div className="listing-tables mt-0">
                    {alertData && alertData.show && (
                      <div className="alerts">
                        <Alert
                          variant={alertData.type}
                          onClose={() => this.hideErrMsg()}
                          dismissible>
                          {alertData.message}
                        </Alert>
                      </div>
                    )}

                    <table className="table table-inverse table-responsive">
                      <thead className="thead-inverse">
                        <tr>
                          <th width="10" className="text-center">
                            <input
                              type="checkbox"
                              id="chkAll"
                              onChange={e => {
                                this.toggleAllBulk(e);
                              }}
                            />
                          </th>
                          <th>Title</th>
                          <th>Request</th>
                          <th>Category</th>
                          <th width="100">State</th>
                          <th width="100">Status</th>
                          <th width="135">Date</th>

                          {/* <th width="150">Date</th> */}
                          <th width="110" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && (
                          <tr>
                            <td align="center" colSpan={8}>
                              <span className="fas fa-spinner fa-spin" />
                            </td>
                          </tr>
                        )}
                        {!loading &&
                          myCauses &&
                          Object.entries(myCauses).length === 0 && (
                            <tr>
                              <td align="center" colSpan={8}>
                                No Nominations Available.
                              </td>
                            </tr>
                          )}
                        {!loading &&
                          myCauses &&
                          Object.entries(myCauses).length > 0 &&
                          myCauses.map(item => this._renderItems(item))}
                      </tbody>
                    </table>

                    {pagging && (
                      <div className="pagging-wrapper">
                        {pagging.current != '' &&
                          pagging.pages != '' &&
                          pagging.totalItems != '' && (
                            <div className="pagging-text">
                              <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                            </div>
                          )}

                        <div className="pagging-btns">
                          {pagging.previous && (
                            <span
                              className="btn btn-small"
                              onClick={() =>
                                this.getMyCauseRequests(pagging.previous)
                              }>
                              Previous
                            </span>
                          )}
                          {pagging.next && (
                            <span
                              className="btn btn-small next-btn"
                              onClick={() =>
                                this.getMyCauseRequests(pagging.next)
                              }>
                              Next
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {edit_cause_modal && (
          <EditCauseFront
            show={edit_cause_modal}
            cause={edit_cause}
            closeModal={() => this.closeModal()}
          />
        )}
      </div>
    );
  }
}

const mapStateProps = state => ({
  usStates: state.usStates,
  menuState: state.menuState,
  isLogin: !!state.user.authToken,
  subscriptions: state.subscriptions,
  myCauses: state.myCauses,
});

export default connect(mapStateProps, {getMyCauseRequests, removeMyCause})(
  MyCausesList,
);
