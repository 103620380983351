import {
  LOGIN,
  GET_ALL_CATEGORIES,
  GET_ALL_REGIONS,
  GET_MY_CAUSES,
  GET_MY_PAYMENTS,
  GET_MY_SUBSCRIPTIONS,
  UPDATE_MY_SUBSCRIPTION,
  GET_HOME_CAUSES,
  UPDATE_CAUSE_VOTE,
  UPDATE_MY_CAUSE_VOTE,
  GET_CATEGORY_CAUSES,
  ADDMORE_CATEGORY_CAUSES,
  UPDATE_MY_CAUSES,
  REMOVE_MY_CAUSES,
} from '../../Constants/Types';

import API from '../../Utils/API';
import {getItem} from '../../Utils/LocalStorage';
import {getMyCardDispatch} from './CommanAction';
import {dispatchAddSubscriptions} from './AdminAction';

/* Get USER Profile */
export const saveUserDispatch = user => ({
  type: LOGIN,
  user,
});
export const saveUserProfile = data => dispatch =>
  API.saveUserProfile(data).then(res => {
    const authToken = getItem('bf_auth_token');
    if (res.user) {
      res.user.authToken = authToken;
      dispatch(saveUserDispatch(res.user));
    }
    return res;
  });

export const getUserProfile =
  (id = '') =>
  dispatch =>
    API.getUserProfile(id);

export const makeDonatePayments = data => dispatch =>
  API.makeDonation(data).then(res => {
    const authToken = getItem('bf_auth_token');
    if (res.user) {
      res.user.authToken = authToken;
      dispatch(saveUserDispatch(res.user));
    }

    if (res.cards) {
      dispatch(getMyCardDispatch(res.cards));
    }

    if (res.mannual_payment && res.mannual_payment === 'Yes') {
      const {subscription} = res;
      dispatch(dispatchAddSubscriptions(subscription));
    }

    return res;
  });

export const getTestimonials = () => dispatch => API.getTestimonials();

export const updateContribution = data => dispatch =>
  API.updateContribution(data).then(res => {
    const authToken = getItem('bf_auth_token');
    if (res.user) {
      res.user.authToken = authToken;
      dispatch(saveUserDispatch(res.user));
    }

    if (res.cards) {
      dispatch(getMyCardDispatch(res.cards));
    }

    if (res.mannual_payment && res.mannual_payment === 'Yes') {
      const {subscription} = res;
      dispatch(dispatchAddSubscriptions(subscription));
    }

    return res;
  });
export const getCurrentContribution = () => dispatch =>
  API.getCurrentContribution();

/* GET ALL Categories */
export const dispatchGetAllCategory = categories => ({
  type: GET_ALL_CATEGORIES,
  categories,
});

export const getAllCategories = () => dispatch =>
  API.getAllCategories().then(res => {
    if (res.type === 'success' && res.categories) {
      const {categories} = res;

      dispatch(dispatchGetAllCategory(categories));
      return res;
    }
  });

export const dispatchGetAllRegions = regions => ({
  type: GET_ALL_REGIONS,
  regions,
});

export const getAllRegions = () => dispatch =>
  API.getAllRegions().then(res => {
    if (res.type === 'success' && res.regions) {
      const {regions} = res;

      dispatch(dispatchGetAllRegions(regions));
      return res;
    }
  });

/* Get Causes Requests */
export const dispatchCategoryRequests = causes => ({
  type: GET_CATEGORY_CAUSES,
  causes,
});
export const dispatchLoadmoreCategoryRequests = causes => ({
  type: ADDMORE_CATEGORY_CAUSES,
  causes,
});

export const getCategoryCauses = (data, type) => dispatch =>
  API.getCategoryCauses(data).then(res => {
    const {causes} = res;

    if (res.type === 'success') {
      if (type === 'update') {
        dispatch(dispatchCategoryRequests(causes));
      } else {
        dispatchLoadmoreCategoryRequests(causes);
      }
    }

    return res;
  });

export const dispatchCauseRequests = causes => ({
  type: GET_HOME_CAUSES,
  causes,
});

export const getCauseRequests = data => dispatch =>
  API.getCauseRequests(data).then(res => {
    const {causes} = res;

    if (res.type === 'success' && Object.entries(causes).length > 0) {
      dispatch(dispatchCauseRequests(causes));
    }

    return res;
  });

export const dispatchCauseVote = cause => ({
  type: UPDATE_CAUSE_VOTE,
  cause,
});

export const dispatchMyCauseVote = cause => ({
  type: UPDATE_MY_CAUSE_VOTE,
  cause,
});

export const updateCauseVote =
  (data, page_name = '') =>
  dispatch =>
    API.updateCauseVote(data).then(res => {
      const {causes} = res;

      if (res.type === 'success' && Object.entries(causes).length > 0) {
        if (page_name === 'category_page') {
          dispatch(dispatchCategoryRequests(causes));
        } else if (page_name === '') {
          dispatch(dispatchCauseRequests(causes));
        }
      }

      return res;
    });

export const getCauseDatails = causeId => dispatch =>
  API.getCauseDatails(causeId);
export const getSimilarCauses = data => dispatch => API.getSimilarCauses(data);

/* Save User Cause */
export const dispatchGetmyCauses = causes => ({
  type: GET_MY_CAUSES,
  causes,
});

export const dispatchUpdatemyCauses = cause => ({
  type: UPDATE_MY_CAUSES,
  cause,
});
export const dispatchRemovemyCauses = cause => ({
  type: REMOVE_MY_CAUSES,
  cause,
});

export const saveUserCause = data => dispatch =>
  API.saveUserCause(data).then(res => {
    return res;
  });

export const updateUserCause = data => dispatch =>
  API.updateMyCause(data).then(res => {
    if (res.type === 'success' && res.cause) {
      dispatch(dispatchUpdatemyCauses(res.cause));
    }

    return res;
  });
export const removeMyCause = data => dispatch =>
  API.removeMyCause(data).then(res => {
    if (res.type === 'success' && res.cause) {
      dispatch(dispatchRemovemyCauses(res.cause));
    }

    return res;
  });

export const getMyCauseRequests = data => dispatch =>
  API.getMyCauses(data).then(res => {
    if (res.type === 'success' && Object.entries(res.causes).length > 0) {
      dispatch(dispatchGetmyCauses(res.causes));
    }

    return res;
  });

/* Get My Subscriptions List */
export const dispatchGetmySubscriptions = subscriptions => ({
  type: GET_MY_SUBSCRIPTIONS,
  subscriptions,
});

export const getMySubscriptions = data => dispatch =>
  API.getMySubscriptions(data).then(res => {
    const {subscriptions} = res;

    if (res.type === 'success' && Object.entries(subscriptions).length > 0) {
      dispatch(dispatchGetmySubscriptions(subscriptions));
    }

    return res;
  });

export const dispatchUpdateMySubscription = subscription => ({
  type: UPDATE_MY_SUBSCRIPTION,
  subscription,
});

export const cancelMySubscription = data => dispatch =>
  API.cancelMySubscription(data).then(res => {
    const {subscription} = res;

    if (res.type === 'success' && Object.entries(subscription).length > 0) {
      dispatch(dispatchUpdateMySubscription(subscription));
    }

    return res;
  });

export const dispatchGetMyPayments = payments => ({
  type: GET_MY_PAYMENTS,
  payments,
});

export const getMyPayments = data => dispatch =>
  API.getMyPayments(data).then(res => {
    const {payments} = res;

    if (res.type === 'success' && Object.entries(payments).length > 0) {
      dispatch(dispatchGetMyPayments(payments));
    }

    return res;
  });
