import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Tab, Tabs} from 'react-bootstrap';
import moment from 'moment';
import MomentDate from 'react-moment';

import {
  Header,
  FrontSidebar,
  EditCauseFront,
  CurrentNominations,
  MyNominations,
  PastNominations,
} from '../../Components';
import {
  getCauseRequests,
  updateCauseVote,
  getMyCauseRequests,
  removeMyCause,
} from '../../Store/Actions/FrontActions';
import {updateUrl} from '../../Utils/Helpers';
import CauseDetails from './CauseDetails';

class NominationsList extends Component {
  state = {
    loading: false,
    myCauses: [],
    pastCauses: [],
    pagging: {},
    page: 1,
    bulk_action_id: [],
    usStates: {},
    causesRequests: [],
    selectedTab: 'current-nomination',
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    this.props.getCauseRequests(1);
    this.getMyCauseRequests(1);

    const {causesRequests} = this.props;
    this.setState({causesRequests});
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {myCauses, usStates, causesRequests} = props;

    this.setState({
      myCauses,
      usStates,
      causesRequests,
    });
  };

  deleteCause = id => {
    const formData = new FormData();
    formData.append('id', id);
    this.props
      .removeMyCause(formData)
      .then(res => {
        this.setState({
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  enableEditModal = cause => {
    const tempCause = {...cause};
    this.setState({
      edit_cause_modal: true,
      edit_cause: tempCause,
    });
  };

  getMyCauseRequests = page => {
    const {loading} = this.state;

    if (!loading) {
      this.setState({loading: true});

      this.props
        .getMyCauseRequests(page)
        .then(res => {
          const tempState = {...this.state};

          if (res.type === 'success') {
            const CurrentPageNo =
              res.pagging && res.pagging.current
                ? parseInt(res.pagging.current)
                : 1;

            // const newUrl =
            //   parseInt(CurrentPageNo) === 1
            //     ? `/my-nominations`
            //     : `/my-nominations/page/${page}`;
            // updateUrl(newUrl, this.props.history);

            tempState.pagging = res.pagging;
            tempState.page = res.pagging;
          }

          tempState.loading = false;

          this.setState(tempState);
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  cancelSubscription = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .cancelMySubscription(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            detailsModal: false,
            subDetails: {},
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  _renderItems = cause => {
    const {bulk_action_id} = this.state;

    const stateId = this.getState(cause.state);
    const timestamp = moment.utc(parseInt(cause.add_time) * 1000);

    return (
      <tr key={`causes_key_${cause.id}`}>
        <td align="center">
          <input
            type="checkbox"
            name="bulk_action[]"
            value={cause.id}
            checked={
              bulk_action_id.indexOf(`${cause.id}`) !== -1 ? true : false
            }
            onChange={e => this.toggleBulkItems(e)}
          />
        </td>
        <td>{cause.title}</td>
        <td>${cause.fund_amount}</td>
        <td>{cause.category ? cause.category.name : ''}</td>
        <td>{stateId.name}</td>
        <td>{cause.status}</td>
        <td>
          <MomentDate date={timestamp} format="MM/DD/YYYY" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span
              className="actionIcon"
              onClick={() => this.enableEditModal(cause)}>
              <i className="fas fa-pen" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => this.deleteCause(cause.id)}>
              <i className="fas fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  getState = stateId => {
    const {usStates} = this.state;
    if (usStates && Object.entries(usStates).length > 0) {
      const state = usStates.filter(item => item.id === stateId);
      return state[0];
    } else {
      return {};
    }
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      edit_cause_modal: false,
      edit_cause: {},
    });
  };

  render() {
    const {
      alertData,
      loading,
      myCauses,
      pagging,
      edit_cause_modal,
      edit_cause,
      selectedTab,
      causesRequests,
      showDetails,
    } = this.state;
    const {location, menuState} = this.props;
    const isOpen = menuState ? 'open' : '';

    console.log('myCauses :', causesRequests);

    return (
      <div className={`admin-wrapper ${isOpen}`}>
        <FrontSidebar location={location} />
        <div className="admin-content">
          <Header />
          <div className="admin-body">
            <div className="body-card">
              <div className="container">
                {alertData && alertData.show && (
                  <div className="alerts">
                    <Alert
                      variant={alertData.type}
                      onClose={() => this.hideErrMsg()}
                      dismissible>
                      {alertData.message}
                    </Alert>
                  </div>
                )}
              </div>
              <div className="panel-wrapper">
                <div className="primary-panel">
                  <Tabs
                    defaultActiveKey={selectedTab}
                    id="uncontrolled-tab-example"
                    className="mb-3 mt-0">
                    <Tab eventKey="current-nomination" title="Current">
                      <CurrentNominations
                        items={causesRequests}
                        showDetails={id =>
                          this.setState({showDetails: {show: true, id}})
                        }
                        updateCauseVote={voteData =>
                          this.props.updateCauseVote(voteData)
                        }
                      />
                    </Tab>
                    <Tab eventKey="my-nomination" title="My Nominations">
                      <MyNominations
                        items={myCauses}
                        showDetails={id => {
                          this.setState({showDetails: {show: true, id}});
                        }}
                        updateCauseVote={voteData =>
                          this.props.updateCauseVote(voteData)
                        }
                      />
                    </Tab>
                    <Tab eventKey="history" title="History">
                      <PastNominations
                        items={causesRequests}
                        showDetails={id =>
                          this.setState({showDetails: {show: true, id}})
                        }
                      />
                    </Tab>
                  </Tabs>
                </div>
                <div className="secondary-panel">
                  {showDetails && showDetails.show && (
                    <CauseDetails causeId={showDetails.id} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {edit_cause_modal && (
          <EditCauseFront
            show={edit_cause_modal}
            cause={edit_cause}
            closeModal={() => this.closeModal()}
          />
        )}
      </div>
    );
  }
}

const mapStateProps = state => ({
  usStates: state.usStates,
  menuState: state.menuState,
  isLogin: !!state.user.authToken,
  subscriptions: state.subscriptions,
  myCauses: state.myCauses,
  causesRequests: state.homeCauses,
});

export default connect(mapStateProps, {
  getMyCauseRequests,
  removeMyCause,
  getCauseRequests,
  updateCauseVote,
})(NominationsList);
