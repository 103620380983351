import React, {Component} from 'react';
import {Accordion, Alert, Button, Tab, Tabs} from 'react-bootstrap';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';

import {
  Header,
  FrontSidebar,
  AddCauseFront,
  AddContribution,
  UpdateContribution,
  CauseListItem,
  AddTestimonials,
  FrontDashboard,
  HomeRegionsbox,
  TestimonialSlider,
} from '../../Components';
import {
  getCauseRequests,
  updateCauseVote,
  getTestimonials,
} from '../../Store/Actions/FrontActions';
import {convertNumber} from '../../Utils/Helpers';
import {YearMonths} from '../../Constants';

class Home extends Component {
  state = {
    causesRequests: [],
    needRattings: [],
    testimonials: [],
    addCauseModal: false,
    donateModal: {
      show: false,
      type: 'Pledge',
    },
    updateContributionModal: false,
    addRattingModal: false,
    showRattingPopup: true,
    rattingCause: {},
    currentUser: {},
    isLogin: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    totalAvailableFund: 0,
    selectedRegion: '',
  };

  UNSAFE_componentWillMount = () => {
    const {currentUser, isLogin, causesRequests} = this.props;

    const tempState = {
      currentUser,
      isLogin,
      causesRequests,
    };

    if (currentUser.isSubscribed === 'No') {
      tempState.donateModal = {
        show: true,
        type: 'Pledge',
      };
    }

    if (window.location.hash) {
      const hash = window.location.hash.substring(1);

      if (hash === 'start-contribute') {
        tempState.donateModal = {
          show: true,
          type: 'One-Time',
        };
      }
    }

    this.setState(tempState);

    this.getCauseRequests(1);

    this.props
      .getTestimonials()
      .then(res => {
        const {testimonials} = res;
        this.setState({testimonials});
      })
      .catch(err => {
        console.log('Err :', err);
      });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {currentUser, isLogin, causesRequests} = props;

    const tempState = {
      currentUser,
      isLogin,
      causesRequests,
    };

    if (currentUser.isSubscribed === 'No') {
      tempState.donateModal = {
        show: true,
        type: 'Pledge',
      };
    }

    this.setState(tempState);
  };

  toggleModal = (modal, state) => {
    this.setState({
      [modal]: state,
    });
  };

  getCauseRequests = page => {
    this.props
      .getCauseRequests(page)
      .then(res => {
        const {needRattings, totalAvailableFund} = res;
        const newState = {...this.state, totalAvailableFund};

        if (needRattings && Object.entries(needRattings).length > 0) {
          newState.needRattings = needRattings;
        }
        this.setState(newState);
      })
      .catch(err => {
        console.log('Error :', err);
      });
  };

  renderCauseLoop = () => {
    const {causesRequests} = this.state;

    const currentIndex =
      Object.entries(causesRequests).length > 0 &&
      causesRequests[0].title !== 'Current Month'
        ? 0
        : 1;

    return (
      <Accordion defaultActiveKey={[currentIndex]}>
        {causesRequests.map((item, index) => {
          if (item.title !== 'Current Month') {
            return this.renderYearItem(item, index);
          } else {
            return <></>;
          }
        })}
      </Accordion>
    );
  };

  renderYearItem = (item, index) => {
    const causes = item.categories;
    const distributedAmount = item.distributed
      ? convertNumber(item.distributed)
      : '';

    return (
      <Accordion.Item key={`year_key_${item.month}`} eventKey={index}>
        <Accordion.Header>
          <div className="heading">
            <h2>{item.title}</h2>
            <div className="distributed-text">
              {distributedAmount && (
                <>
                  <span>Distributed</span> ${distributedAmount}
                </>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="list-wrapper">
            {Object.entries(causes).length > 0 &&
              causes.map((item, index) => this.renderCategoryItem(item, index))}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  renderCategoryItem = (item, index) => {
    const causes = item.causes;
    return (
      <div key={`category_key_${index}`} className="category-item">
        <h3>{item.name}</h3>
        {Object.entries(causes).length &&
          causes.map((causeItem, index) => (
            <CauseListItem
              item={causeItem}
              updateCauseVote={id => this.updateCauseVote(id)}
            />
          ))}
        <div className="view-all-link">
          <Link to={`/category/${item.id}`}>Show All</Link>
        </div>
      </div>
    );
  };

  updateCauseVote = $causeId => {
    const voteData = new FormData();
    voteData.append('cause_id', $causeId);

    this.props
      .updateCauseVote(voteData)
      .then(res => {
        console.log('Res', res);
      })
      .catch(err => {
        console.log('Err :', err);
      });
  };

  viewRattingModal = cause => {
    this.setState({
      addRattingModal: true,
      rattingCause: cause,
    });
  };

  hideRattingPopup = () => {
    this.setState({
      showRattingPopup: false,
    });
  };

  updateNeedRattings = needRattings => {
    const newState = {
      ...this.state,
      needRattings,
      addRattingModal: false,
      rattingCause: {},
    };

    if (Object.entries(needRattings).length > 0) {
      newState.showRattingPopup = false;
    }

    this.setState(newState, () => {
      console.log('New State:', this.state);
    });
  };

  setAlerts = alertData => {
    this.setState({
      alertData,
    });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  render() {
    const {
      alertData,
      addCauseModal,
      donateModal,
      updateContributionModal,
      addRattingModal,
      rattingCause,
      causesRequests,
      currentUser,
      showRattingPopup,
      needRattings,
      totalAvailableFund,
      isLogin,
      selectedRegion,
      testimonials,
    } = this.state;
    const {location, menuState} = this.props;
    const isOpen = menuState ? 'open' : '';

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 8000,
    };

    const isCurrentMonth =
      Object.entries(causesRequests).length > 0 &&
      causesRequests[0].title === 'Current Month'
        ? true
        : false;
    const currentMonthCategories = isCurrentMonth
      ? causesRequests[0].categories
      : [];

    const selectedTab = 'current-month';

    const donateButtonText =
      currentUser.isPledged === 'Yes'
        ? 'One Time Donation'
        : 'Setup Contribution';

    const myContribution = currentUser.myContribution
      ? currentUser.myContribution
      : 0;

    const monthText = new Date();

    return (
      <div className={`admin-wrapper ${isOpen}`}>
        <FrontSidebar location={location} />

        <div className="admin-content">
          <Header />
          <div className="admin-body">
            <div className="container">
              <div className="body-card">
                {(currentUser.isSubscribed === 'No' ||
                  currentUser.isSubscribed === '') &&
                  (currentUser.isPledged === 'No' ||
                    currentUser.isPledged === '') && (
                    <div className="body-content">
                      <div className="my-impact-headings appretiation-text not-contributed">
                        <p>
                          You haven't made any Contribution yet for this month.
                          Please create one with following.
                        </p>
                        <div className="btns-wrapper">
                          <Button
                            className="btn btn-primary btn-small add-new-btn"
                            onClick={() =>
                              this.setState({
                                donateModal: {show: true, type: 'One-Time'},
                              })
                            }>
                            One-Time Donation
                          </Button>

                          {(currentUser.isPledged === 'No' ||
                            currentUser.isPledged === '') && (
                            <Button
                              className="btn btn-primary btn-small add-new-btn"
                              onClick={() =>
                                this.setState({
                                  donateModal: {show: true, type: 'Pledge'},
                                })
                              }>
                              Setup Contribution
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {(currentUser.isSubscribed === 'Yes' ||
                  currentUser.isPledged === 'Yes') && (
                  <>
                    <div className="heading mt-0">
                      {/* <h2>&nbsp;</h2> */}
                      <HomeRegionsbox
                        region={selectedRegion}
                        changeRegion={id => this.setState({selectedRegion: id})}
                      />

                      <div className="filter-options-wrapper">
                        {isLogin && (
                          <>
                            <button
                              className="btn btn-primary btn-small add-new-btn"
                              onClick={() =>
                                this.setState({
                                  donateModal: {show: true, type: 'One-Time'},
                                })
                              }>
                              One-Time Donation
                            </button>

                            {currentUser.isPledged === 'No' && (
                              <button
                                className="btn btn-primary btn-small add-new-btn"
                                onClick={() =>
                                  this.setState({
                                    donateModal: {show: true, type: 'Pledge'},
                                  })
                                }>
                                Setup Contribution
                              </button>
                            )}

                            {currentUser.isPledged === 'Yes' && (
                              <button
                                className="btn btn-primary btn-small add-new-btn"
                                onClick={() =>
                                  this.setState({
                                    updateContributionModal: true,
                                  })
                                }>
                                Update Contribution
                              </button>
                            )}

                            {(currentUser.isSubscribed === 'Yes' ||
                              currentUser.isPledged === 'Yes') && (
                              <button
                                className="btn btn-primary btn-small add-new-btn"
                                onClick={() =>
                                  this.toggleModal('addCauseModal', true)
                                }>
                                Add Nomination
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <FrontDashboard region={selectedRegion} />

                    <div className="body-content">
                      {alertData && alertData.show && (
                        <div className="alerts">
                          <Alert
                            variant={alertData.type}
                            onClose={() => this.hideErrMsg()}
                            dismissible>
                            {alertData.message}
                          </Alert>
                        </div>
                      )}

                      {showRattingPopup &&
                        Object.entries(needRattings).length > 0 && (
                          <div className="alerts ratting-requests">
                            <Alert
                              variant="success"
                              onClose={() => this.hideRattingPopup()}
                              dismissible>
                              <Slider {...settings}>
                                {needRattings.map((item, index) => {
                                  return (
                                    <div className="ratting-item">
                                      <h3>
                                        Congratulations{' '}
                                        {currentUser && currentUser.name}!
                                      </h3>
                                      <p>
                                        Your cause received funds from the BUILD
                                        community.
                                      </p>
                                      <p>
                                        This month, the BUILD community voted to
                                        give you ${item.winning_amount} for{' '}
                                        {item.title}. Let your supporters know
                                        what this means to you. Feel free to add
                                        a video, images or just leave a message.
                                      </p>

                                      <div className="ratting-btn-wrapper">
                                        <Button
                                          className="btn btn-small btn-dark"
                                          onClick={() =>
                                            this.viewRattingModal(item)
                                          }>
                                          Thank Community
                                        </Button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </Slider>
                            </Alert>
                          </div>
                        )}

                      <div className="my-impact-headings">
                        <div className="contribution-wrapper">
                          <h2>
                            Your {YearMonths[monthText.getMonth()]}
                            {` `}
                            Contribution
                          </h2>
                          <span className="payment-text">
                            ${myContribution}
                          </span>
                          <div className="mt-3">
                            <Link
                              className="btn btn-primary "
                              to="/nominations">
                              Submit Month Vote
                            </Link>
                          </div>

                          {/* <div className="appretiation-text">
                            <p>
                              Thanks {currentUser.name} for your contributions
                              to the community. Check out the impact we are
                              making as a community below.
                            </p>
                          </div> */}
                        </div>
                      </div>

                      {testimonials &&
                        Object.entries(testimonials).length > 0 && (
                          <div className="testimonial-wrapper mt-3">
                            <div className="container">
                              <div className="headings text-center">
                                <h2>Recent Impact</h2>
                              </div>
                              <div className="testimonial-slider">
                                <TestimonialSlider
                                  fullWidth={true}
                                  testimonials={testimonials}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                      {/* <div className="cuases-lists-wrapper">
                        <div className="months-wrapper">
                          <Tabs
                            defaultActiveKey={selectedTab}
                            id="uncontrolled-tab-example"
                            className="mb-3 mt-4">
                            <Tab eventKey="current-month" title="Current Month">
                              <div className="list-wrapper">
                                {Object.entries(currentMonthCategories).length >
                                  0 &&
                                  currentMonthCategories.map((item, index) =>
                                    this.renderCategoryItem(item, index),
                                  )}

                                {Object.entries(currentMonthCategories)
                                  .length === 0 && (
                                  <div className="mt-5 mb-4 text-center">
                                    <div className="my-impact-headings appretiation-text">
                                      <p>
                                        No nominations have been submitted this
                                        month.
                                      </p>
                                      <Button
                                        className="btn btn-primary btn-small add-new-btn"
                                        onClick={() =>
                                          this.toggleModal(
                                            'addCauseModal',
                                            true,
                                          )
                                        }>
                                        Add Nomination
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Tab>

                            <Tab eventKey="history" title="History">
                              {Object.entries(causesRequests).length > 0 &&
                                this.renderCauseLoop()}
                            </Tab>
                          </Tabs>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {addCauseModal && (
          <AddCauseFront
            show={addCauseModal}
            closeModal={() => this.toggleModal('addCauseModal', false)}
          />
        )}

        {donateModal && donateModal.show && (
          <AddContribution
            show={donateModal.show}
            type={donateModal.type}
            closeModal={() => {
              this.setState({
                donateModal: {
                  show: false,
                  type: '',
                },
              });
            }}
          />
        )}

        {updateContributionModal && (
          <UpdateContribution
            show={updateContributionModal}
            type="Pledge"
            closeModal={() => {
              this.setState({
                updateContributionModal: false,
              });
            }}
          />
        )}

        {addRattingModal && (
          <AddTestimonials
            cause={rattingCause}
            show={addRattingModal}
            closeModal={() => this.toggleModal('addRattingModal', false)}
            updateNeedRattings={needRattings =>
              this.updateNeedRattings(needRattings)
            }
            setAlert={data => this.setAlerts(data)}
          />
        )}
      </div>
    );
  }
}

const mapStateProps = state => ({
  menuState: state.menuState,
  currentUser: state.user,
  isLogin: !!state.user.authToken,
  causesRequests: state.homeCauses,
});

export default connect(mapStateProps, {
  getCauseRequests,
  updateCauseVote,
  getTestimonials,
})(Home);
