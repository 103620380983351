import React, {useEffect, useState} from 'react';
import {Accordion, Button} from 'react-bootstrap';
import {connect} from 'react-redux';

import {Link} from 'react-router-dom';
import CauseListItem from './CauseListItem';

import {convertNumber} from '../Utils/Helpers';

const PastNominations = ({items, updateCauseVote, showDetails}) => {
  const [causesRequests, setItems] = useState([]);

  useEffect(() => {
    setItems(items);
  }, [items]);

  const renderCauseLoop = () => {
    const currentIndex =
      Object.entries(causesRequests).length > 0 &&
      causesRequests[0].title !== 'Current Month'
        ? 0
        : 1;

    return (
      <Accordion defaultActiveKey={[currentIndex]}>
        {causesRequests.map((item, index) => {
          if (item.title !== 'Current Month') {
            return renderYearItem(item, index);
          } else {
            return <></>;
          }
        })}
      </Accordion>
    );
  };

  const renderYearItem = (item, index) => {
    const causes = item.categories;
    const distributedAmount = item.distributed
      ? convertNumber(item.distributed)
      : '';

    return (
      // <Accordion.Item key={`year_key_${item.month}`} eventKey={index}>
      //   <Accordion.Header>
      //     <div className="heading">
      //       <h2>{item.title}</h2>
      //       <div className="distributed-text">
      //         {distributedAmount && (
      //           <>
      //             <span>Distributed</span> ${distributedAmount}
      //           </>
      //         )}
      //       </div>
      //     </div>
      //   </Accordion.Header>
      //   <Accordion.Body>
      <div className="list-wrapper">
        {Object.entries(causes).length > 0 &&
          causes.map((item, index) => renderCategoryItem(item, index))}
      </div>
      //   </Accordion.Body>
      // </Accordion.Item>
    );
  };

  const renderCategoryItem = (item, index) => {
    const causes = item.causes;
    return (
      <div key={`category_key_${index}`} className="category-item">
        <h3>{item.name}</h3>
        {Object.entries(causes).length &&
          causes.map((causeItem, index) => (
            <CauseListItem
              item={causeItem}
              updateCauseVote={id => updateVotes(id)}
              showDetails={id => showDetails(id)}
            />
          ))}
        {/* <div className="view-all-link">
          <Link to={`/category/${item.id}`}>Show All</Link>
        </div> */}
      </div>
    );
  };

  const updateVotes = $causeId => {
    const voteData = new FormData();
    voteData.append('cause_id', $causeId);

    updateCauseVote(voteData)
      .then(res => {
        console.log('Res', res);
      })
      .catch(err => {
        console.log('Err :', err);
      });
  };
  return (
    <div className="cuases-lists-wrapper">
      <div className="months-wrapper">
        <div className="list-wrapper">
          {renderCauseLoop()}
          {/* {Object.entries(currentMonthCategories).length > 0 &&
            currentMonthCategories.map((item, index) =>
              renderCategoryItem(item, index),
            )} */}

          {Object.entries(causesRequests).length === 0 && (
            <div className="mt-5 mb-4 text-center">
              <div className="my-impact-headings appretiation-text">
                <p>No nominations have been submitted.</p>
                <Button
                  className="btn btn-primary btn-small add-new-btn"
                  onClick={() => {}}>
                  Add Nomination
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(PastNominations);
