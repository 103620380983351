import React, {Component} from 'react';
import {Alert, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';

import {Header, FrontSidebar, VoteBtn, Breadcrumb} from '../../Components';
import {DefaultImg, userImage} from '../../Constants/Images';
import {
  getCauseDatails,
  updateCauseVote,
  getAllCategories,
  getAllRegions,
  getSimilarCauses,
} from '../../Store/Actions/FrontActions';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {faGlobe} from '@fortawesome/free-solid-svg-icons';
import {convertNumber, updateUrl} from '../../Utils/Helpers';
import {getAllStates} from '../../Store/Actions/CommanAction';
import {BASE_URL} from '../../Utils/env';

class CauseDetails extends Component {
  state = {
    cause: {},
    causeId: '',
    loading: false,
    similarCauses: [],
    similarLoading: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    stateInfo: {},
    isLogin: false,
    usStates: {},
    categories: [],
    regions: [],
    filterData: {
      region: '',
      category: '',
      causeId: '',
    },
    causeNavs: {
      prev: {},
      next: {},
    },
  };

  UNSAFE_componentWillMount = () => {
    const {usStates, isLogin} = this.props;
    const causeId = this.props.causeId
      ? this.props.causeId
      : this.props.match.params.causeId;

    this.props.getAllStates().catch(err => {
      console.log('Err :', err);
    });

    this.props.getAllCategories().catch(err => {
      console.log('Err :', err);
    });

    this.props.getAllRegions().catch(err => {
      console.log('Err :', err);
    });

    this.setState(
      {
        usStates,
        isLogin,
      },
      () => {
        this.getCauseDatails(causeId);
      },
    );
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {causeId} = this.state;
    const {usStates, isLogin, categories, regions} = props;
    const newCauseId = props.causeId
      ? props.causeId
      : props.match.params.causeId;

    this.setState(
      {
        usStates,
        isLogin,
        categories,
        regions,
        causeId: newCauseId,
      },
      () => {
        if (causeId !== newCauseId) {
          this.getCauseDatails(newCauseId);
        }
      },
    );
  };

  getCauseDatails = causeId => {
    const {loading} = this.state;

    if (!loading) {
      this.setState({
        loading: true,
      });

      this.props
        .getCauseDatails(causeId)
        .then(res => {
          const {cause, similarCauses} = res;

          this.filterSimilarCauses(similarCauses);

          this.getNavItems(cause.id);

          const stateInfo = this.getRegion(cause.state);
          cause.socials = cause.socials !== '' ? JSON.parse(cause.socials) : {};

          if (res.type === 'success') {
            this.setState({
              loading: false,
              cause,
              stateInfo,
              filterData: {
                region: cause.region ? cause.region.id : '',
                category: cause.category ? cause.category.id : '',
                causeId: cause.id,
              },
            });
          } else {
            this.setState({
              loading: false,
              alertData: {
                show: true,
                type: 'danger',
                message: res.message,
              },
            });
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  getNavItems = causeId => {
    const {similarCauses} = this.state;
    const simillarCount = Object.entries(similarCauses).length;

    let nextCause = {};
    let prevCause = {};

    for (let i = 0; i < simillarCount; i++) {
      if (causeId === similarCauses[i].id) {
        nextCause = i === simillarCount - 1 ? {} : similarCauses[i + 1];
        prevCause = i === 0 ? {} : similarCauses[i - 1];
      }
    }

    this.setState({
      causeNavs: {
        prev: prevCause,
        next: nextCause,
      },
    });
  };

  updateSimilarCauses = cause => {
    const similarCauses = [...this.state.similarCauses];
    const similaCount = Object.entries(similarCauses).length;

    if (similaCount > 0) {
      for (let i = 0; i < similaCount; i++) {
        if (similarCauses[i]['id'] === cause.id) {
          similarCauses[i] = cause;
        }
      }
    }

    this.setState({
      similarCauses,
    });
  };

  updateCauseVote = $causeId => {
    const voteData = new FormData();
    voteData.append('cause_id', $causeId);
    voteData.append('page_name', 'details_page');

    this.props
      .updateCauseVote(voteData, 'details_page')
      .then(res => {
        const {causes} = res;
        causes.socials = causes.socials ? JSON.parse(causes.socials) : [];
        this.updateSimilarCauses(causes);

        if (res.type === 'success') {
          this.setState({
            loading: false,
            cause: causes,
          });
        } else {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        console.log('Err :', err);
      });
  };

  getRegion = stateId => {
    const {usStates} = this.state;

    const state = usStates.filter(item => item.id === stateId);

    return state ? state[0] : {};
  };

  renderSocial = (item, index) => {
    let icon = '';
    const link = item.link;

    switch (item.social) {
      case 'Instagram':
        icon = faInstagram;
        break;
      case 'Facebook':
        icon = faFacebook;
        break;
      case 'Twitter':
        icon = faTwitter;
        break;
      case 'Youtube':
        icon = faYoutube;
        break;
      case 'Website':
        icon = faGlobe;
        break;
      default:
        icon = '';
        break;
    }

    return (
      <li key={`social_icon_${index}`}>
        <Link to={link} target="_blank">
          <FontAwesomeIcon icon={icon} />
        </Link>
      </li>
    );
  };

  updateFilter = e => {
    const fieldName = e.target.name;
    let {similarCauses, cause} = this.state;

    const filterData = {
      ...this.state.filterData,
    };

    if (fieldName === 'region') {
      filterData[fieldName] = e.target.value;
      filterData['category'] = '';
      filterData['causeId'] = '';
      similarCauses = [];
    } else if (fieldName === 'category') {
      filterData[fieldName] = e.target.value;
      filterData['causeId'] = '';
      similarCauses = [];
    } else {
      if (e.target.value !== '') {
        filterData[fieldName] = e.target.value;

        this.getNavItems(e.target.value);

        cause = this.getSimilarCause(e.target.value);

        const newUrl = `/cause/${e.target.value}`;
        updateUrl(newUrl, this.props.history);
      }
    }

    this.setState(
      {
        similarCauses,
        filterData,
        cause,
      },
      () => {
        if (fieldName === 'region' || fieldName === 'category') {
          this.fetchSimilarCauses();
        }
      },
    );
  };

  fetchSimilarCauses = () => {
    const {similarLoading} = this.state;
    const {region, category} = this.state.filterData;

    if (!similarLoading) {
      this.setState({
        similarLoading: true,
      });

      const formData = new FormData();
      formData.append('region', region);
      formData.append('category', category);

      this.props
        .getSimilarCauses(formData)
        .then(res => {
          const tempState = {
            similarLoading: false,
          };

          if (res.type === 'success') {
            this.filterSimilarCauses(res.causes);

            // const { filterData } = this.state;
            // firstCause = res.causes[0];
            // filterData.causeId = firstCause.id;
            // this.getNavItems(firstCause.id);
            // const stateInfo = this.getRegion(firstCause.state);

            // tempState.cause = firstCause;
            // tempState.stateInfo = stateInfo;
            // tempState.filterData = filterData;
          }

          this.setState(tempState);
        })
        .catch(err => {
          console.log('Err', err);
        });
    }
  };

  filterSimilarCauses = similarCauses => {
    const similaCount = Object.entries(similarCauses).length;

    if (similaCount > 0) {
      for (let i = 0; i < similaCount; i++) {
        if (similarCauses[i]['socials'] !== '') {
          similarCauses[i]['socials'] =
            similarCauses[i]['socials'] !== ''
              ? JSON.parse(similarCauses[i]['socials'])
              : {};
        }
      }
    }

    this.setState({
      similarCauses,
    });
  };

  getSimilarCause = id => {
    const {similarCauses} = this.state;

    const tempCause = similarCauses.filter(item => item.id === id);

    return Object.entries(tempCause).length > 0 ? tempCause[0] : [];
  };

  changeNavigation = id => {
    const {filterData} = this.state;

    this.getNavItems(id);

    const cause = this.getSimilarCause(id);

    const newUrl = `/cause/${id}`;
    updateUrl(newUrl, this.props.history);

    filterData.causeId = id;

    this.setState({
      filterData,
      cause,
    });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  render() {
    const {
      cause,
      alertData,
      stateInfo,
      loading,
      categories,
      regions,
      similarCauses,
      filterData,
      causeNavs,
    } = this.state;

    const {location, menuState} = this.props;
    const isOpen = menuState ? 'open' : '';

    const backgroungImg = cause.cover_img ? cause.cover_img : DefaultImg;
    const userImg =
      cause.user_data && cause.user_data.profile_img !== ''
        ? `${BASE_URL}/${cause.user_data.profile_img}`
        : userImage;

    const socialIcons = cause.socials ? cause.socials : [];

    const causeItemDate = moment.unix(cause.add_time).format('M');
    const currentMonth = new Date();

    const isSameMonth =
      parseInt(causeItemDate) === currentMonth.getMonth() + 1 ? true : false;

    return (
      <>
        {/* <div className={`admin-wrapper ${isOpen}`}>
        <FrontSidebar location={location} />
        <div className="admin-content">
          <Header />
          <div className="admin-body"> */}
        <div className="cause-details-wrapper w-100">
          {/* <div className="cause-filter-wrapper">
            <div className="filter-form">
              <div className="container">
                <Form className="d-flex">
                  <Form.Group className="form-group mr-2">
                    <Form.Label>Regions</Form.Label>
                    <div className="select-dropdown">
                      <select
                        name="region"
                        className="form-control"
                        value={filterData.region}
                        onChange={e => this.updateFilter(e)}>
                        <option value="all">All Regions</option>
                        {Object.entries(regions).length > 0 &&
                          regions.map((item, index) => (
                            <option key={`region_${item.id}`} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Form.Group>

                  <Form.Group className="form-group mr-2">
                    <Form.Label>Categories</Form.Label>
                    <div className="select-dropdown">
                      <select
                        name="category"
                        className="form-control"
                        value={filterData.category}
                        onChange={e => this.updateFilter(e)}>
                        <option value="all">All Categories</option>
                        {Object.entries(categories).length > 0 &&
                          categories.map((item, index) => (
                            <option key={`category_${item.id}`} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Form.Group>

                  <Form.Group className="form-group mr-2">
                    <Form.Label>Causes</Form.Label>
                    <div className="select-dropdown">
                      <select
                        name="causeId"
                        className="form-control"
                        value={filterData.causeId}
                        onChange={e => this.updateFilter(e)}>
                        <option value="">Select Cause</option>
                        {Object.entries(similarCauses).length > 0 &&
                          similarCauses.map((item, index) => {
                            return (
                              <option key={`cause_${item.id}`} value={item.id}>
                                {item.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div> */}

          {!loading && backgroungImg && (
            <div
              className="banner-img"
              style={{backgroundImage: `url('${backgroungImg}')`}}></div>
          )}

          <div className="container">
            <div className="body-card">
              <div className="seach-box-wrapper"></div>

              {alertData && alertData.show && (
                <Alert
                  variant={alertData.type}
                  onClose={() => this.hideErrMsg()}
                  dismissible>
                  {alertData.message}
                </Alert>
              )}

              {loading && (
                <span className="loader-icon text-center mt-5 mb-5">
                  <i className="fas fa-spinner fa-spin" />
                </span>
              )}

              {!loading && (
                <>
                  <div className="cause-details">
                    <h2>{cause.title}</h2>
                    <div className="cause-headings">
                      <div className="categ-info">
                        <span>{cause.category && cause.category.name}</span>
                        {Object.entries(socialIcons).length && (
                          <div className="social-icons">
                            <ul>
                              {socialIcons.map((item, index) =>
                                this.renderSocial(item, index),
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="regions-info">
                        <span>
                          {cause.region && cause.region.name} - {stateInfo.name}{' '}
                        </span>
                        <span className="percentages">
                          {cause.vote_percentage}%
                        </span>
                      </div>
                    </div>
                    <p>{cause.justify_cause}</p>
                  </div>

                  <div className="cause-funding-goal">
                    <p>Funding Goals</p>
                    <span>${convertNumber(cause.fund_amount)}</span>
                  </div>

                  <div className="cause-nomination">
                    <div className="user-info">
                      <img src={userImg} alt="" /> Nominated by{' '}
                      {cause.user_data && cause.user_data.name}
                    </div>

                    <VoteBtn
                      causeId={cause.id}
                      isSameMonth={isSameMonth}
                      isVoted={cause.isVoted}
                      updateCauseVote={id => this.updateCauseVote(id)}
                    />
                  </div>

                  {/* <div className="navs-wrapper align-items-center">
                    {causeNavs && causeNavs.prev.id && (
                      <button
                        className="btn btn-small prev"
                        onClick={() =>
                          this.changeNavigation(causeNavs.prev.id)
                        }>
                        <span className="nav-text">
                          <i className="fas fa-chevron-left " /> Previous
                        </span>
                        <span className="cause-item-name">
                          {causeNavs.prev.title}
                        </span>
                      </button>
                    )}

                    {causeNavs && causeNavs.next.id && (
                      <button
                        className="btn btn-small next text-right"
                        onClick={() =>
                          this.changeNavigation(causeNavs.next.id)
                        }>
                        <span className="nav-text">
                          Next
                          <i className="fas fa-chevron-right" />
                        </span>
                        <span className="cause-item-name">
                          {causeNavs.next.title}
                        </span>
                      </button>
                    )}
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
        {/* </div>
        </div>
      </div> */}
      </>
    );
  }
}

const mapStateProps = state => ({
  menuState: state.menuState,
  usStates: state.usStates,
  isLogin: !!state.user.authToken,
  categories: state.allCauseCategories,
  regions: state.allCauseRegions,
});

export default connect(mapStateProps, {
  getCauseDatails,
  updateCauseVote,
  getAllCategories,
  getAllRegions,
  getSimilarCauses,
  getAllStates,
})(CauseDetails);
