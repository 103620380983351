import React, {useState} from 'react';
import {Dropdown, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

import {LogoDark} from '../../Constants/Images';

import {setMenuState} from '../../Store/Actions/CommanAction';
import {userImage} from '../../Constants/Images';
import ProfileModal from '../Modals/ProfileModal';
// import { BASE_URL } from "../../Utils/env";

import {logout} from '../../Store/Actions/AuthActions';

const Header = ({menuState, setMenuState, user, isLogin, logout}) => {
  const [showProfileModal, setProfileModal] = useState(false);
  const profileImg = user.profile_img !== '' ? user.profile_img : userImage;

  return (
    <div className="admin-header">
      <span
        className="hamberger"
        onClick={() => {
          setMenuState(!menuState);
        }}>
        <FontAwesomeIcon icon={faBars} />
      </span>
      <div className="container">
        <div className="app-logo-wrapper">
          <div className="logo">
            <Link to="/causes">
              <Image src={LogoDark} />
            </Link>
          </div>
        </div>
        {isLogin && (
          <div className="user-dropdown-wrapper">
            <Dropdown>
              <Dropdown.Toggle variant="secondary" className="user-dropdown">
                <Image src={profileImg} />{' '}
                {user.display_name ? user.display_name : user.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Link className="dropdown-item" to="/profile">
                  Profile
                </Link>
                {/* <Dropdown.Item>
                </Dropdown.Item> */}
                <Dropdown.Item href="#" onClick={() => logout()}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* // onClick={() => setProfileModal(true)} */}
            {showProfileModal && (
              <ProfileModal
                show={showProfileModal}
                closeModal={() => setProfileModal(false)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  user: state.user,
  isLogin: !!state.user.authToken,
});

export default connect(mapStateProps, {setMenuState, logout})(Header);
