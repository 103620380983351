import React from "react";
import { Modal } from "react-bootstrap";

import { ProfileUpdate } from "../index";

const ProfileModal = (props) => {
    const { show } = props;

    const closeModal = () => {
        props.closeModal();
    };
    return (
        <Modal className="medium" show={show} onHide={() => closeModal()}>
            <Modal.Header>
                <Modal.Title>Account</Modal.Title>
                <span className="close as-link" onClick={() => closeModal()}>
                    x
                </span>
            </Modal.Header>
            <Modal.Body>
                <ProfileUpdate />
            </Modal.Body>
        </Modal>
    );
};

export default ProfileModal;
