import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthRoute = ({ isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)} />
    );
};

const mapStateProp = (state) => ({
    isAuthenticated: !!state.user.authToken,
});

export default connect(mapStateProp)(AuthRoute);
