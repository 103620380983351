/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Breadcrumb, Header} from '../../Components';
import {getTermPages} from '../../Store/Actions/AdminAction';

const PrivacyPolicy = ({termPages, getTermPages}) => {
  useEffect(() => {
    // console.log("termPages :", termPages);
    if (Object.entries(termPages).length === 0) {
      getTermPages()
        .then(res => {})
        .catch(err => {
          console.log('Err:', err);
        });
    }
  }, [termPages]);

  return (
    <div className={`admin-wrapper`}>
      <div className="admin-content">
        <Header />
        <div className="admin-body">
          <div className="container">
            {/* <Breadcrumb title="Privacy Policy" /> */}

            <div className="body-card">
              <div className="heading">
                <h2>Privacy Policy</h2>
              </div>

              <div className="body-content content-page">
                <div
                  dangerouslySetInnerHTML={{
                    __html: termPages.privacy_page_content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  termPages: state.termPages,
});

export default connect(mapStateProps, {getTermPages})(PrivacyPolicy);
