import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { ALLOWED_COUNTRY } from "../Utils/env";
import { requestOtp, verifyOtp } from "../Store/Actions/AuthActions";
import VerifyPhone from "./VerifyPhone";

const LoginForm = ({ requestOtp, verifyOtp }) => {
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [messageType, setMessageType] = useState("");
    const [message, setMessage] = useState("");

    const [otpState, changeOtpState] = useState(false);
    const [isNewUser, setNewUser] = useState(false);
    const [otp, setOtp] = useState("");

    const displayAlert = (type, message) => {
        setShowAlert(true);
        setMessageType(type);
        setMessage(message);
    };

    const submitLogin = (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("phone", phone);

        requestOtp(formData)
            .then((res) => {
                if (res.type === "success") {
                    changeOtpState(true);
                    const networkOtp = res.otp ? res.otp : "";
                    setOtp(networkOtp);

                    const userState = res.new_user === "true" ? true : false;
                    setNewUser(userState);
                }

                const type = res.type === "error" ? "danger" : res.type;
                displayAlert(type, res.message);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                displayAlert("danger", err.message);
            });

        return false;
    };

    const updatePhone = () => {
        setPhone("");
        setOtp("");
        setNewUser(false);
        changeOtpState(false);
    };

    // const resetForm = () => {};

    return (
        <div className="auth-form">
            {showAlert && (
                <Alert variant={messageType} onClose={() => setShowAlert(false)} dismissible>
                    <p>{message}</p>
                </Alert>
            )}

            {!otpState && (
                <Form onSubmit={submitLogin}>
                    <Form.Group className="form-group">
                        <PhoneInput
                            className="form-control"
                            onlyCountries={ALLOWED_COUNTRY}
                            country={"us"}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                        />
                    </Form.Group>

                    <Form.Group className="form-group mb-0">
                        <button className="btn btn-small" onClick={submitLogin}>
                            {!loading && "Login"}
                            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                        </button>
                    </Form.Group>

                    <div className="signup-links">
                        Don't have account? <Link to="/register">Register Now</Link>
                    </div>
                </Form>
            )}

            {otpState && (
                <VerifyPhone
                    otp={otp}
                    isNewUser={isNewUser}
                    phone={phone}
                    showAlert={(type, message) => displayAlert(type, message)}
                    updatePhone={() => updatePhone()}
                    verifyOtp={(data) => verifyOtp(data)}
                />
            )}
        </div>
    );
};

export default connect(null, { requestOtp, verifyOtp })(LoginForm);
