import React, {Component} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import API from '../../Utils/API';
import {convertNumber} from '../../Utils/Helpers';

class FrontRemainingAmount extends Component {
  state = {
    show: this.props.show,
    data: this.props.data ? this.props.data : {},
    loading: false,
    selected: '',
    userReg: {},
  };

  UNSAFE_componentWillMount = () => {
    this.getMonthsData();
    this.getRegion();
  };

  UNSAFE_componentWillReceiveProps = () => {
    this.getRegion();
  };

  getMonthsData = () => {
    const {loading, userReg} = this.state;

    if (!loading) {
      this.setState({loading: true});

      const formData = new FormData();
      formData.append('region', userReg.id);

      API.getAvailableFunds(formData)
        .then(res => {
          const {funds} = res;

          if (funds) {
            this.setState({
              loading: false,
              data: funds,
              selected: funds[0],
            });
          }
        })
        .catch(err => {});
    }
  };

  changeMonth = e => {
    const {data} = this.state;
    const target = e.target.value;

    const selected = data.filter(item => item.month === target);
    console.log('Change :', selected);

    this.setState({
      selected: selected[0],
    });
  };

  getRegion = () => {
    const {regions, currentUser} = this.props;

    const tempReg = regions.filter(
      item => parseInt(currentUser.region) === parseInt(item.id),
    );

    this.setState({
      userReg: tempReg[0],
    });
  };

  render() {
    const {data, show, userReg, selected, loading} = this.state;

    console.log('data :', data);

    return (
      <Modal
        className="medium"
        show={show}
        onHide={() => this.props.closeModal()}>
        <Modal.Header>
          <Modal.Title>
            Breakdown {`for ${selected.title ? selected.title : ''}`}
          </Modal.Title>
          <span
            className="close as-link"
            onClick={() => this.props.closeModal()}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="remaining-amount-wrapper">
            <div className="heading">
              <h3>{userReg.name}</h3>

              <div className="months-wrapper">
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    value={selected && selected.month}
                    onChange={e => this.changeMonth(e)}>
                    {/* <option value="">All</option> */}
                    {data &&
                      Object.entries(data).length > 0 &&
                      data.map((item, index) => (
                        <option value={item.month}>{item.title}</option>
                      ))}
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="remaining-amount-data-wrapper">
              {loading && (
                <div className="mt-5 mb-5 text-center">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              )}
              {!loading && selected && (
                <div className="listing-tables mt-0">
                  {/* <h3>{userReg.name}</h3> */}
                  <table className="table table-responsive">
                    <tbody>
                      <tr>
                        <th>Total Collected</th>
                        <td align="right">
                          ${convertNumber(selected.month_total)}
                        </td>
                      </tr>
                      <tr>
                        <th>Global</th>
                        <td align="right">
                          ${convertNumber(selected.global_fund)}
                        </td>
                      </tr>
                      <tr>
                        <th>Emergency</th>
                        <td align="right">
                          ${convertNumber(selected.emergency_fund)}
                        </td>
                      </tr>
                      <tr>
                        <th>Total Distributed</th>
                        <td align="right">
                          ${convertNumber(selected.dist_amount)}
                        </td>
                      </tr>
                      <tr>
                        <th>Expenses</th>
                        <td align="right">
                          ${convertNumber(selected.expenses)}
                        </td>
                      </tr>
                      <tr>
                        <th>&nbsp;</th>
                        <td align="right">&nbsp;</td>
                      </tr>
                      <tr>
                        <th>Carryover Amount</th>
                        <td align="right">
                          ${convertNumber(selected.carryover)}
                        </td>
                      </tr>
                      {/* <tr>
                                                <th>Total </th>
                                                <td align="right">$1,500</td>
                                            </tr> */}
                    </tbody>
                  </table>

                  {/* <table className="table table-responsive">
                    <tbody>
                      <tr>
                        <th>Total Collected</th>
                        <td align="right">$100</td>
                      </tr>
                      <tr>
                        <th>Expenses</th>
                        <td align="right">$0</td>
                      </tr>
                      <tr>
                        <th>Total Available</th>
                        <td align="right">$100</td>
                      </tr>
                      <tr>
                        <th>
                          Global{' '}
                          <span className="info-text">
                            (50% of Total Availble)
                          </span>
                        </th>
                        <td align="right">
                          <input type="text" name="global" />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Emergency{' '}
                          <span className="info-text">
                            (10% of Total Availble)
                          </span>
                        </th>

                        <td align="right">
                          <input type="text" name="Emergency" />
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Recipient Distribution{' '}
                          <span className="info-text">($50 Available)</span>
                        </th>
                        <td align="right">
                          <input type="text" name="availble" />
                        </td>
                      </tr>

                      <tr>
                        <th>&nbsp;</th>
                        <td align="right">&nbsp;</td>
                      </tr>
                      <tr>
                        <th>Carryover Amount</th>
                        <td align="right">
                          ${convertNumber(selected.carryover)}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateProps = state => ({
  currentUser: state.user,
  regions: state.allCauseRegions,
});

export default connect(mapStateProps, {})(FrontRemainingAmount);
