import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const VoteBtn = (props) => {
    const { isLogin, causeId, isSameMonth, isVoted } = props;

    return (
        <>
            {isLogin && isSameMonth && (
                <>
                    {!isVoted && (
                        <span
                            className="btn btn-primary btn-outline btn-small"
                            onClick={() => props.updateCauseVote(causeId)}
                        >
                            <i className="far fa-heart" /> Vote
                        </span>
                    )}
                    {isVoted && (
                        <span
                            className="btn btn-primary active btn-small"
                            onClick={() => props.updateCauseVote(causeId)}
                        >
                            <i className="fas fa-heart" /> Vote
                        </span>
                    )}
                </>
            )}
            {!isLogin && isSameMonth && (
                <Link to="/login">
                    <span className="btn btn-primary btn-small">
                        <i className="far fa-heart" /> Vote
                    </span>
                </Link>
            )}
        </>
    );
};

const mapStateProps = (state) => ({
    isLogin: !!state.user.authToken,
});

export default connect(mapStateProps, {})(VoteBtn);
