export const STRIPE_PUBLIC_KEY =
  'pk_test_51J2dADSEiFPruzTIwDVKVWBV2NqiwSgotejVdrXayN6GWbX4nr6U3yw4BYUKCjQaoRwgXFL8Yn6VXj1VdmMsSfl500Eia5krqL';

export const usStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const faqs = [
  {
    title: 'How do I sign up to pledge?',
    content:
      'Click on one of the buttons on this page labeled “Pledge For Impact.” You will be presented a form to establish pledge commitment to get started.',
  },
  {
    title: 'How long do I have to pledge?',
    content:
      'Once you establish your pledge amount and frequency, we will automatically pull funds per agreed schedule. You are welcome to cancel your pledge at any time.',
  },
  {
    title: 'How do I vote?',
    content:
      'Once you sign up as a Pledger, you will gain access to a dashboard. On this dashboard, you will find an area listing the causes you can vote on for that month.',
  },
  {
    title: 'How are funds distributed?',
    content:
      'Every cause submission requires instructions on how funds will be collected. After votes are in, funds will be sent within 7 business days.',
  },
  {
    title: 'How are causes vetted?',
    content: `<p>
                    Causes can only be submitted by Pledgers and we ask specific questions
                    to help us check its validity. BUILD Foundation staff does an initial
                    assessment prior to releasing the cause to the community.
                </p>
                <p>
                    We also have a feature for community members like you to flag causes you
                    discover aren’t genuine in their request.
                </p>`,
  },
  {
    title: 'Is there only one winner of funds per month?',
    content:
      'No. We break up funding by regions. Within each region, there are categories with multiple causes. The cause with the most votes will receive 50% of the pot. The remaining causes that receive over 10% of votes will receive the remaining funds.',
  },
  {
    title: 'Can I submit my own cause?',
    content:
      'As a Pledger, you can submit causes for yourself or anyone else. This also includes organizations and other nonprofits.',
  },
  {
    title: 'Does my vote have more weight if I pledge more?',
    content:
      'No. No matter what you contribute, we collectively make funding decisions through majority vote.',
  },
  {
    title: 'Can I claim my contributions with IRS?',
    content:
      'We are a 501c(3) nonprofit organization. Please consult with your accountant.',
  },
];

export const YearMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const nominationsReport = [
  {
    month: 'Current Month',
    title: 'Current Month',
    regions: [
      {
        id: 17,
        name: 'Northeast',
        data: {
          total: 100,
          expenses: 0,
          available: 100,
          globalFunds: 50,
          globalFundsMax: 100,
          globalPercentage: 50,
          emergencyFunds: 10,
          emergencyFundsMax: 50,
          emergencyPercentage: 10,
          recepientAvailable: 40,
          recepientAvailableAmount: 40,
          carryOver: 0,
          nominattedCauses: [],
        },
      },
      {
        id: 18,
        name: 'Mid-Atlantic',
        data: {
          total: 200,
          expenses: 0,
          available: 200,
          globalFunds: 100,
          globalPercentage: 50,
          emergencyFunds: 20,
          emergencyPercentage: 10,
          recepientAvailable: 80,
          recepientAvailableAmount: 80,
          carryOver: 0,
          globalFundsMax: 200,
          emergencyFundsMax: 100,
          nominattedCauses: [],
        },
      },
      {
        id: 19,
        name: 'Northwest',
        data: {
          total: 50,
          expenses: 0,
          available: 50,
          globalFunds: 25,
          globalPercentage: 50,
          emergencyFunds: 5,
          emergencyPercentage: 10,
          recepientAvailable: 20,
          recepientAvailableAmount: 20,
          carryOver: 0,
          globalFundsMax: 50,
          emergencyFundsMax: 25,
          nominattedCauses: [],
        },
      },
    ],
    isDistributed: false,
  },
  {
    month: 'June 2023',
    title: 'June 2023',
    regions: [
      {
        id: 18,
        name: 'Mid-Atlantic',
        data: {
          total: 400,
          expenses: 0,
          available: 400,
          globalFunds: 100,
          globalPercentage: 50,
          emergencyFunds: 20,
          emergencyPercentage: 10,
          recepientAvailable: 80,
          recepientAvailableAmount: 80,
          carryOver: 0,
          globalFundsMax: 200,
          emergencyFundsMax: 100,
          nominattedCauses: [],
        },
      },
      {
        id: 19,
        name: 'Northwest',
        data: {
          total: 50,
          expenses: 0,
          available: 50,
          globalFunds: 25,
          globalPercentage: 50,
          emergencyFunds: 5,
          emergencyPercentage: 10,
          recepientAvailable: 20,
          recepientAvailableAmount: 20,
          carryOver: 0,
          globalFundsMax: 50,
          emergencyFundsMax: 25,
          nominattedCauses: [],
        },
      },
      {
        id: 17,
        name: 'Northeast',
        data: {
          total: 100,
          expenses: 0,
          available: 100,
          globalFunds: 50,
          globalFundsMax: 100,
          globalPercentage: 50,
          emergencyFunds: 10,
          emergencyFundsMax: 50,
          emergencyPercentage: 10,
          recepientAvailable: 40,
          recepientAvailableAmount: 40,
          carryOver: 0,
          nominattedCauses: [
            {
              title: 'Test Nominations',
              requestedAmount: 20,
              votes: 50,
              description: '',
              distributedAmount: 11,
            },
            {
              title: 'Test Nominations 2',
              requestedAmount: 30,
              votes: 50,
              description: '',
              distributedAmount: 17,
            },
            {
              title: 'Test Nominations 3',
              requestedAmount: 30,
              votes: 50,
              description: '',
              distributedAmount: 12,
            },
          ],
        },
      },
    ],
    isDistributed: false,
  },
];
