/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Alert, Button, Form, Modal, Tab, Tabs} from 'react-bootstrap';
import {connect} from 'react-redux';
// import moment from "moment";
// import MomentDate from "react-moment";

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// import {STRIPE_PUBLIC_KEY} from '../../Constants';

import {
  saveCard,
  getMyCard,
  assignPrimary,
  deleteCard,
  getStripeKeys,
} from '../../Store/Actions/CommanAction';
import {CARDS_LIMIT} from '../../Utils/env';

const AddCardForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [myCards, setMyCards] = useState(props.myCards);

  const [state, setState] = useState({
    show: props.show,
    loading: false,
    card_details: {},
    is_primary: true,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    errors: {},
  });

  const saveCard = async event => {
    event.preventDefault();
    const {loading, is_primary} = state;

    if (myCards.length < CARDS_LIMIT) {
      if (elements == null) {
        return;
      }

      setState({...state, errors, saving: true});

      if (!loading) {
        setState({
          ...state,
          loading: true,
        });

        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

        if (!error) {
          setState({
            ...state,
            errors: {
              carderr: '',
            },
          });

          const formData = new FormData();
          formData.append('paynemt_details', JSON.stringify(paymentMethod));
          formData.append('is_primary', is_primary ? 'Yes' : 'No');

          props
            .saveCard(formData)
            .then(res => {
              if (res.type === 'success') {
                elements.getElement(CardElement).clear();
              }

              setState({
                ...state,
                loading: false,
                alertData: {
                  show: true,
                  type: res.type === 'error' ? 'danger' : res.type,
                  message: res.message,
                },
              });
            })
            .catch(err => {
              setState({
                ...state,
                loading: false,
                alertData: {
                  show: false,
                  type: 'danger',
                  message: err.message,
                },
              });
            });
        } else {
          const tempErr = {
            carderr: error.message,
          };

          setState({...state, errors: tempErr, loading: false});
        }
      }
    } else {
      setState({
        ...state,
        alertData: {
          show: true,
          type: 'warning',
          message: `You're already reached Maximum Cards Limit - ${CARDS_LIMIT}.`,
        },
      });
    }
  };

  const onChange = e => {
    const is_primary = e.target.checked;
    setState({
      ...state,
      is_primary,
    });
  };

  const hideErrMsg = () => {
    setState({
      ...state,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  useEffect(() => {
    if (myCards.length === CARDS_LIMIT) {
      setState({
        ...state,
        alertData: {
          show: true,
          type: 'warning',
          message: `You're already reached Maximum Cards Limit - ${CARDS_LIMIT}.`,
        },
      });
    }
  }, [myCards]);

  useEffect(() => {
    setMyCards(props.myCards);
  }, [props.myCards]);

  const {errors, alertData, loading, is_primary} = state;

  return (
    <div className="add-ratting-wrapper">
      {alertData && alertData.show && (
        <div className="alerts">
          <Alert
            variant={alertData.type}
            onClose={() => hideErrMsg()}
            dismissible>
            {alertData.message}
          </Alert>
        </div>
      )}

      <Form onSubmit={saveCard}>
        <Form.Group className="form-group" controlId="name">
          <Form.Label>New Card</Form.Label>
          <CardElement />
          <span className="info-text">
            All payments and cards are securely managed by industry leader{' '}
            <a href="https://www.stripe.com/" target="_blank">
              Stripe.
            </a>
          </span>
          {errors.carderr && <span className="errorMsg">{errors.carderr}</span>}
        </Form.Group>
        <Form.Group className="form-group" controlId="name">
          <Form.Check
            name="is_primary"
            type="switch"
            id="custom-switch"
            checked={is_primary}
            onChange={e => onChange(e)}
            label="Make Primary Card"
          />
        </Form.Group>

        <Form.Group className="form-group mb-0" controlId="review">
          {loading && (
            <Button className="btn btn-primary btn-small">
              <i className="fas fa-spinner fa-spin" />
            </Button>
          )}
          {!loading && (
            <Button className="btn btn-primary btn-small" onClick={saveCard}>
              Save
            </Button>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};

const MyCardList = props => {
  const {cards, loading, assignPrimary, deleteCard, user} = props;
  const [cardsList, setCards] = useState([]);
  const [cUser, setCuser] = useState({});

  useEffect(() => {
    if (cards) {
      setCards(cards);
    }
  }, [cards]);

  useEffect(() => {
    setCuser(user);
  }, [user]);

  return (
    <div className="card-list-wrapper listing-tables mt-0">
      <table className="table table-inverse table-responsive">
        <thead className="thead-inverse">
          <tr>
            <th>Type</th>
            <th>Number</th>
            <th>Exp. Date</th>
            <th width="130" className="text-center">
              Status
            </th>
            <th width="80" className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td align="center" colSpan={6}>
                <span className="fas fa-spinner fa-spin" />
              </td>
            </tr>
          )}
          {!loading && Object.entries(cardsList).length === 0 && (
            <tr>
              <td align="center" colSpan={6}>
                <p>No Cards found</p>
              </td>
            </tr>
          )}
          {console.log('cUser:', cUser)}
          {!loading &&
            Object.entries(cardsList).length > 0 &&
            cardsList.map((item, index) => {
              // const timestamp = moment.utc(parseInt(item.add_time) * 1000);
              console.log(
                'Active Card :',
                cUser.stripe_payment_id,
                item.payment_id,
              );
              const primaryCardClass =
                cUser && cUser.stripe_payment_id === item.payment_id
                  ? 'fas fa-star'
                  : 'far fa-star';
              return (
                <tr key={`card_${item.id}`}>
                  <td>{item.card_info && item.card_info.brand}</td>
                  <td>
                    {item.card_info && `**** **** **** ${item.card_info.last4}`}
                  </td>
                  <td>
                    {item.card_info &&
                      `${item.card_info.exp_month}/${item.card_info.exp_year}`}
                  </td>
                  <td align="center">{item.card_info && item.status}</td>
                  {/* <td align="center">
                      <MomentDate date={timestamp} format="MM/DD/YYYY HH:mm" />
                  </td> */}
                  <td className="text-right" valign="middle">
                    <div className="action-wrapper">
                      <span
                        className="actionIcon secondary"
                        onClick={() => assignPrimary(item.id)}>
                        <i className={primaryCardClass} />
                      </span>
                      {user.stripe_payment_id !== item.payment_id && (
                        <span
                          className="actionIcon danger"
                          onClick={() => deleteCard(item.id)}>
                          <i className="fas fa-trash" />
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const AddCardModal = props => {
  const {
    show,
    myCards,
    getMyCard,
    assignPrimary,
    deleteCard,
    currentUser,
    getStripeKeys,
  } = props;

  const [cUser, setCuser] = useState({});
  const [stripe_public_key, setStripeKey] = useState('');
  let stripePromise = '';

  if (stripe_public_key) {
    stripePromise = loadStripe(stripe_public_key);
  }

  const [cards, setMyCards] = useState([]);
  const [alertData, setAlert] = useState({
    show: false,
  });
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    props.closeModal();
  };

  const getMyCards = () => {
    setLoading(true);
    getMyCard()
      .then(res => {
        setLoading(false);
        console.log('Res:', res);
      })
      .catch(err => {
        console.log('Err:', err);
      });
  };

  const assignPrimaryCard = id => {
    assignPrimary(id)
      .then(res => {
        setAlert({
          show: true,
          type: res.type === 'error' ? 'danger' : res.type,
          message: res.message,
        });
      })
      .catch(err => {
        setAlert({
          show: true,
          type: 'danger',
          message: err.message,
        });
      });
  };
  const deleteMyCard = id => {
    deleteCard(id)
      .then(res => {
        setAlert({
          show: true,
          type: res.type === 'error' ? 'danger' : res.type,
          message: res.message,
        });
      })
      .catch(err => {
        console.log('Err', err);
        setAlert({
          show: true,
          type: 'danger',
          message: err.message,
        });
      });
  };

  const hideErrMsg = () => {
    setAlert({
      show: false,
    });
  };

  useEffect(() => {
    setLoading(true);
    getStripeKeys()
      .then(res => {
        setLoading(false);
        setStripeKey(res.stripe_key);
      })
      .catch(e => {
        setLoading(false);
        console.log('Err :', e);
      });

    getMyCards();
  }, []);

  useEffect(() => {
    if (myCards) {
      console.log('myCard', myCards);
      setMyCards(myCards);
    }
  }, [myCards]);

  useEffect(() => {
    setCuser(currentUser);
  }, [currentUser]);

  return (
    <Modal className="medium" show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>Manage Cards</Modal.Title>
        <span className="close as-link" onClick={() => closeModal()}>
          x
        </span>
      </Modal.Header>
      <Modal.Body>
        {alertData && alertData.show && (
          <div className="alerts">
            <Alert
              variant={alertData.type}
              onClose={() => hideErrMsg()}
              dismissible>
              {alertData.message}
            </Alert>
          </div>
        )}

        <Tabs
          defaultActiveKey="my-cards"
          id="uncontrolled-tab-example"
          className="mb-3 mt-0">
          <Tab eventKey="my-cards" title="My Cards">
            <MyCardList
              loading={loading}
              cards={cards}
              user={cUser}
              assignPrimary={id => assignPrimaryCard(id)}
              deleteCard={id => deleteMyCard(id)}
            />
          </Tab>
          <Tab eventKey="add-new-cards" title="Add New">
            {!loading && stripe_public_key && (
              <Elements stripe={stripePromise}>
                <AddCardForm saveCard={props.saveCard} myCards={cards} />
              </Elements>
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

const mapStateProps = state => ({
  myCards: state.myCards,
  currentUser: state.user,
});

export default connect(mapStateProps, {
  saveCard,
  getMyCard,
  assignPrimary,
  deleteCard,
  getStripeKeys,
})(AddCardModal);
