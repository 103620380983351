import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';
// import moment from "moment";

import {
  Header,
  FrontSidebar,
  Breadcrumb,
  SubscriptionModal,
} from '../../Components';
import {getMyPayments} from '../../Store/Actions/FrontActions';

class SubscriptionsList extends Component {
  state = {
    loading: false,
    payments: {},
    pagging: {},
    subscriptionId: '',
    page: 1,
    detailsModal: false,
    subDetails: {},
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const subId = this.props.match.params.id;

    if (subId) {
      this.setState(
        {
          subscriptionId: subId,
        },
        () => {
          this.getMyPayments(1);
        },
      );
    }
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {payments} = props;
    const subId = props.match.params.id;

    this.setState({
      payments,
      subscriptionId: subId,
    });
  };

  getMyPayments = page => {
    const {loading, subscriptionId} = this.state;

    if (!loading) {
      this.setState({loading: true});

      const formData = new FormData();
      formData.append('page', page);
      formData.append('subscription_id', subscriptionId);

      this.props
        .getMyPayments(formData)
        .then(res => {
          const tempState = {...this.state};

          if (res.type === 'success') {
            tempState.pagging = res.pagging;
            tempState.page = res.pagging;
          }

          tempState.loading = false;

          this.setState(tempState);
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  enableDetailsModal = subItem => {
    this.setState({
      detailsModal: true,
      subDetails: subItem,
    });
  };

  cancelSubscription = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .cancelMySubscription(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            detailsModal: false,
            subDetails: {},
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  _renderItems = item => {
    // const timestamp = moment.utc(parseInt(item.end_date) * 1000);

    return (
      <tr key={`items_key_${item.id}`}>
        <td align="center">{}</td>
        <td>{item.payment_id}</td>
        <td>${item.amount}</td>
        <td>{item.region ? item.region.name : ''}</td>
        <td>{item.status}</td>
      </tr>
    );
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      detailsModal: false,
      subDetails: {},
    });
  };

  render() {
    const {alertData, loading, payments, pagging, detailsModal, subDetails} =
      this.state;
    const {location, menuState} = this.props;
    const isOpen = menuState ? 'open' : '';

    return (
      <div className={`admin-wrapper ${isOpen}`}>
        <FrontSidebar location={location} />

        <div className="admin-content">
          <Header />
          <div className="admin-body">
            <div className="container">
              {/* <Breadcrumb
                                history={this.props.history}
                                parent={{ title: "Past Contributions", link: "/past-contributions" }}
                                title="Payments"
                            /> */}

              <div className="body-card">
                <div className="heading">
                  <h2>Payments</h2>
                  <div className="filter-options-wrapper"></div>
                </div>

                <div className="body-content">
                  <div className="listing-tables mt-0">
                    {alertData && alertData.show && (
                      <div className="alerts">
                        <Alert
                          variant={alertData.type}
                          onClose={() => this.hideErrMsg()}
                          dismissible>
                          {alertData.message}
                        </Alert>
                      </div>
                    )}

                    <table className="table table-inverse table-responsive">
                      <thead className="thead-inverse">
                        <tr>
                          <th width="10" className="text-center">
                            &nbsp;
                          </th>
                          <th>Payment ID</th>
                          <th width="100">Amount</th>
                          <th width="100">Region</th>
                          <th width="100">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading && (
                          <tr>
                            <td align="center" colSpan={5}>
                              <span className="fas fa-spinner fa-spin" />
                            </td>
                          </tr>
                        )}
                        {!loading && Object.entries(payments).length === 0 && (
                          <tr>
                            <td align="center" colSpan={5}>
                              No Paymetns Available.
                            </td>
                          </tr>
                        )}
                        {!loading &&
                          Object.entries(payments).length > 0 &&
                          payments.map(item => this._renderItems(item))}
                      </tbody>
                    </table>

                    {pagging && (
                      <div className="pagging-wrapper">
                        {pagging.current &&
                          pagging.pages &&
                          pagging.totalItems && (
                            <div className="pagging-text">
                              <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                            </div>
                          )}

                        <div className="pagging-btns">
                          {pagging.previous && (
                            <span
                              className="btn btn-small"
                              onClick={() =>
                                this.getMyPayments(pagging.previous)
                              }>
                              Previous
                            </span>
                          )}
                          {pagging.next && (
                            <span
                              className="btn btn-small next-btn"
                              onClick={() => this.getMyPayments(pagging.next)}>
                              Next
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {detailsModal && (
          <SubscriptionModal
            show={detailsModal}
            data={subDetails}
            closeModal={() => this.closeModal()}
            cancelSubscription={id => this.cancelSubscription(id)}
          />
        )}
      </div>
    );
  }
}

const mapStateProps = state => ({
  menuState: state.menuState,
  isLogin: !!state.user.authToken,
  payments: state.myPayments,
});

export default connect(mapStateProps, {getMyPayments})(SubscriptionsList);
