import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';

import moment from 'moment';
import MomentDate from 'react-moment';
import {Breadcrumb, AddMannualPayment, AddUser} from '../../Components';

import {
  getSubscriptionList,
  updateSubscription,
  removeSubscription,
} from '../../Store/Actions/AdminAction';
import {EditSubscription} from '../../Components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faEdit, faPen} from '@fortawesome/free-solid-svg-icons';
import {updateUrl} from '../../Utils/Helpers';

class SubscriptionsList extends Component {
  state = {
    loading: false,
    subscriptions: {},
    pagging: {},
    page: 1,
    detailsModal: false,
    add_mannual_payment: false,
    add_user_modal: false,
    subDetails: {},
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const page = this.props.match.params.page
      ? this.props.match.params.page
      : 1;

    this.setState(
      {
        page,
      },
      () => {
        this.getSubscriptions(page);
      },
    );
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {subscriptions} = props;

    this.setState({
      subscriptions,
    });
  };

  getSubscriptions = page => {
    const {loading} = this.state;

    if (!loading) {
      this.setState({loading: true});

      this.props
        .getSubscriptionList(page)
        .then(res => {
          const tempState = {...this.state};

          console.log('Res Pagging :', res.pagging);

          if (res.type === 'success') {
            tempState.pagging = res.pagging;
            tempState.page = res.pagging;

            const CurrentPageNo =
              res.pagging && res.pagging.current
                ? parseInt(res.pagging.current)
                : 1;

            const newUrl =
              CurrentPageNo === 1
                ? `/admin/subscriptions`
                : `/admin/subscriptions/page/${page}`;
            updateUrl(newUrl, this.props.history);
          }

          tempState.loading = false;

          this.setState(tempState);
        })
        .catch(err => {
          this.setState({
            loading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  toggleModal = (modal, state) => {
    this.setState({
      [modal]: state,
    });
  };

  enableDetailsModal = subItem => {
    this.setState({
      detailsModal: true,
      subDetails: subItem,
    });
  };

  cancelSubscription = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .cancelMySubscription(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            detailsModal: false,
            subDetails: {},
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  updateSubscription = data => {
    this.props
      .updateSubscription(data)
      .then(res => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        this.setState({
          detailsModal: false,
          subDetails: {},
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  removeSubscription = id => {
    const formData = new FormData();
    formData.append('id', id);

    this.props
      .removeSubscription(formData)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  _renderItems = item => {
    const timestamp = moment.utc(parseInt(item.end_date) * 1000);

    return (
      <tr key={`items_key_${item.id}`}>
        <td align="center">&nbsp;</td>
        <td>{item.user.name ? item.user.name : ''}</td>
        <td>{item.contribution_type}</td>
        {/* <td>{item.pledge_type}</td> */}
        <td>${item.amount}</td>
        <td>{item.region ? item.region.name : ''}</td>
        <td>
          <MomentDate date={timestamp} format="MM/DD/YYYY" />
        </td>
        <td>{item.status}</td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span
              title="Check Details"
              className="actionIcon"
              onClick={() => this.enableDetailsModal(item)}>
              <FontAwesomeIcon icon={faPen} />
            </span>

            <span
              title="Payments"
              className="actionIcon danger"
              onClick={() => this.removeSubscription(item.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </span>

            {/* item.status === "Active" && (
                            <span
                                title="Cancel Subscription"
                                className="actionIcon danger"
                                onClick={() => this.cancelSubscription(item.id)}
                            >
                                <i className="fas fa-times" />
                            </span>
                        ) */}
          </div>
        </td>
      </tr>
    );
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      detailsModal: false,
      subDetails: {},
    });
  };

  render() {
    const {
      alertData,
      loading,
      subscriptions,
      pagging,
      detailsModal,
      subDetails,
      add_mannual_payment,
      add_user_modal,
    } = this.state;

    return (
      <div className="admin-body">
        <div className="container">
          <div className="body-card">
            <div className="heading">
              <h2>Donations</h2>

              <div className="filter-options-wrapper">
                <span
                  className="btn btn-primary btn-small add-new-btn"
                  onClick={() => {
                    this.toggleModal('add_mannual_payment', true);
                  }}>
                  Add Mannual Payment
                </span>
              </div>
            </div>

            <div className="body-content">
              <div className="listing-tables mt-0">
                {alertData && alertData.show && (
                  <div className="alerts">
                    <Alert
                      variant={alertData.type}
                      onClose={() => this.hideErrMsg()}
                      dismissible>
                      {alertData.message}
                    </Alert>
                  </div>
                )}

                <table className="table table-inverse table-responsive">
                  <thead className="thead-inverse">
                    <tr>
                      <th width="10" className="text-center">
                        &nbsp;
                      </th>
                      <th>User</th>
                      <th>Contribution Type</th>
                      {/* <th>Type</th> */}
                      <th>Amount</th>
                      <th>Region</th>
                      <th width="130">Expiring At</th>
                      <th width="80">Status</th>
                      <th width="130" className="text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td align="center" colSpan={9}>
                          <span className="fas fa-spinner fa-spin" />
                        </td>
                      </tr>
                    )}
                    {!loading && Object.entries(subscriptions).length === 0 && (
                      <tr>
                        <td align="center" colSpan={9}>
                          No Subscriptions Available.
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      Object.entries(subscriptions).length > 0 &&
                      subscriptions.map(item => this._renderItems(item))}
                  </tbody>
                </table>

                {pagging && (
                  <div className="pagging-wrapper">
                    {pagging.current && pagging.pages && pagging.totalItems && (
                      <div className="pagging-text">
                        <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                      </div>
                    )}

                    <div className="pagging-btns">
                      {pagging.previous && (
                        <span
                          className="btn btn-small"
                          onClick={() =>
                            this.getSubscriptions(pagging.previous)
                          }>
                          Previous
                        </span>
                      )}
                      {pagging.next && (
                        <span
                          className="btn btn-small next-btn"
                          onClick={() => this.getSubscriptions(pagging.next)}>
                          Next
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {add_user_modal && (
              <AddUser
                show={add_user_modal}
                toggleModal={() => this.toggleModal('add_user_modal', false)}
              />
            )}

            {detailsModal && (
              <EditSubscription
                data={subDetails}
                show={detailsModal}
                updateSubscription={data => this.updateSubscription(data)}
                closeModal={() => this.closeModal()}
                showAddUser={() => this.toggleModal('add_user_modal', true)}
              />
            )}
            {add_mannual_payment && (
              <AddMannualPayment
                show={add_mannual_payment}
                isAdmin={true}
                closeModal={() =>
                  this.toggleModal('add_mannual_payment', false)
                }
                showAddUser={() => this.toggleModal('add_user_modal', true)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateProps = state => ({
  menuState: state.menuState,
  subscriptions: state.adminSubscriptions,
});

export default connect(mapStateProps, {
  getSubscriptionList,
  updateSubscription,
  removeSubscription,
})(SubscriptionsList);
