import {
  GET_ADMIN_SUBSCRIPTIONS,
  ADD_ADMIN_SUBSCRIPTIONS,
  UPDATE_ADMIN_SUBSCRIPTION,
  REMOVE_ADMIN_SUBSCRIPTION,
  GET_ADMIN_PAYMENTS,
  REMOVE_ADMIN_PAYMENT,
  GET_EXPENSES,
  ADD_EXPENSE,
  UPDATE_EXPENSE,
  REMOVE_EXPENSE,
  GET_TESTIMONIALS,
  ADD_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
  REMOVE_TESTIMONIAL,
  SAVE_TERM_PAGES,
  GET_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  REMOVE_EVENT,
} from '../../Constants/Types';
import API from '../../Utils/API';

/**
 * Dashboard Data
 */
export const getDashboardData = regId => dispatch =>
  API.getDashboardData(regId);
export const approveUsers = id => dispatch => API.approveUsers(id);
export const approveCauses = id => dispatch => API.approveCauses(id);

/**
 * Save/Retrive TERM - Privacy Pages
 */

export const dispatchSaveTermPages = pages => ({
  type: SAVE_TERM_PAGES,
  pages,
});

export const saveTermPages = data => dispatch =>
  API.saveTermPages(data).then(res => {
    if (res.type === 'success' && res.pages) {
      dispatch(dispatchSaveTermPages(res.pages));
    }

    return res;
  });

export const getTermPages = id => dispatch =>
  API.getTermPages(id).then(res => {
    if (res.type === 'success' && res.pages) {
      dispatch(dispatchSaveTermPages(res.pages));
    }

    return res;
  });

/**
 * Subscription List
 **/

export const dispatchAddSubscriptions = subscription => ({
  type: ADD_ADMIN_SUBSCRIPTIONS,
  subscription,
});
export const dispatchSubscriptions = subscriptions => ({
  type: GET_ADMIN_SUBSCRIPTIONS,
  subscriptions,
});
export const dispatchUpadteSubscription = subscription => ({
  type: UPDATE_ADMIN_SUBSCRIPTION,
  subscription,
});
export const dispatchRemoveSubscription = subscription => ({
  type: REMOVE_ADMIN_SUBSCRIPTION,
  subscription,
});

export const getSubscriptionList = data => dispatch =>
  API.getSubscriptionList(data).then(res => {
    const {subscriptions} = res;

    if (res.type === 'success' && Object.entries(subscriptions).length > 0) {
      dispatch(dispatchSubscriptions(subscriptions));
    }

    return res;
  });

export const updateSubscription = data => dispatch =>
  API.updateSubscription(data).then(res => {
    const {subscription} = res;

    if (res.type === 'success' && Object.entries(subscription).length > 0) {
      dispatch(dispatchUpadteSubscription(subscription));
    }

    return res;
  });

export const removeSubscription = data => dispatch =>
  API.removeSubscription(data).then(res => {
    const {subscription} = res;

    if (res.type === 'success' && Object.entries(subscription).length > 0) {
      dispatch(dispatchRemoveSubscription(subscription));
    }

    return res;
  });

/**
 * Payments List
 **/
export const dispatchPayments = payments => ({
  type: GET_ADMIN_PAYMENTS,
  payments,
});
export const dispatchRemovePayment = payment => ({
  type: REMOVE_ADMIN_PAYMENT,
  payment,
});

export const getPaymentsList = data => dispatch =>
  API.getPaymentsList(data).then(res => {
    const {payments} = res;

    if (res.type === 'success' && Object.entries(payments).length > 0) {
      dispatch(dispatchPayments(payments));
    }

    return res;
  });

export const removePaymentItem = data => dispatch =>
  API.removePaymentItem(data).then(res => {
    const {payment} = res;

    if (res.type === 'success' && Object.entries(payment).length > 0) {
      dispatch(dispatchRemovePayment(payment));
    }

    return res;
  });

/**
 * Expenses List
 **/
export const getExpensesDispatch = expenses => ({
  type: GET_EXPENSES,
  expenses,
});
export const addExpenseDispatch = expense => ({
  type: ADD_EXPENSE,
  expense,
});
export const updateExpenseDispatch = expense => ({
  type: UPDATE_EXPENSE,
  expense,
});
export const removeExpenseDispatch = expense => ({
  type: REMOVE_EXPENSE,
  expense,
});

export const getExpensesList = page => dispatch =>
  API.getExpensesList(page).then(res => {
    if (res.type !== 'error') {
      const {expenses} = res;

      dispatch(getExpensesDispatch(expenses));
    }
    return res;
  });
export const addExpense = data => dispatch =>
  API.addExpense(data).then(res => {
    if (res.type !== 'error') {
      const {expense} = res;

      dispatch(addExpenseDispatch(expense));
    }
    return res;
  });
export const updateExpense = data => dispatch =>
  API.updateExpense(data).then(res => {
    if (res.type !== 'error') {
      const {expense} = res;

      dispatch(updateExpenseDispatch(expense));
    }
    return res;
  });
export const removeExpense = data => dispatch =>
  API.removeExpense(data).then(res => {
    if (res.type !== 'error') {
      const {expense} = res;

      dispatch(removeExpenseDispatch(expense));
    }
    return res;
  });

/**
 * Events List
 **/
export const getEventsDispatch = events => ({
  type: GET_EVENTS,
  events,
});
export const addEventsDispatch = event => ({
  type: ADD_EVENT,
  event,
});
export const updateEventsDispatch = event => ({
  type: UPDATE_EVENT,
  event,
});
export const removeEventsDispatch = event => ({
  type: REMOVE_EVENT,
  event,
});

export const getEventsList = page => dispatch =>
  API.getEventsList(page).then(res => {
    if (res.type !== 'error') {
      const {events} = res;

      dispatch(getEventsDispatch(events));
    }
    return res;
  });
export const addEvent = data => dispatch =>
  API.addEvent(data).then(res => {
    if (res.type !== 'error') {
      const {event} = res;

      dispatch(addEventsDispatch(event));
    }
    return res;
  });
export const updateEvent = data => dispatch =>
  API.updateEvent(data).then(res => {
    if (res.type !== 'error') {
      const {event} = res;

      dispatch(updateEventsDispatch(event));
    }
    return res;
  });
export const removeEvent = data => dispatch =>
  API.removeEvent(data).then(res => {
    if (res.type !== 'error') {
      const {event} = res;

      dispatch(removeEventsDispatch(event));
    }
    return res;
  });

/**
 * Testimonials List
 **/
export const getTestimonialsDispatch = testimonials => ({
  type: GET_TESTIMONIALS,
  testimonials,
});
export const addTestimonialDispatch = testimonial => ({
  type: ADD_TESTIMONIAL,
  testimonial,
});
export const updateTestimonialDispatch = testimonial => ({
  type: UPDATE_TESTIMONIAL,
  testimonial,
});
export const removeTestimonialDispatch = testimonial => ({
  type: REMOVE_TESTIMONIAL,
  testimonial,
});

export const getTestimonialsList = page => dispatch =>
  API.getTestimonialsList(page).then(res => {
    if (res.type !== 'error') {
      const {testimonials} = res;

      dispatch(getTestimonialsDispatch(testimonials));
    }
    return res;
  });
export const addTestimonial = data => dispatch =>
  API.addTestimonial(data).then(res => {
    if (res.type !== 'error') {
      const {testimonial} = res;

      dispatch(addTestimonialDispatch(testimonial));
    }
    return res;
  });
export const updateTestimonial = data => dispatch =>
  API.updateTestimonial(data).then(res => {
    if (res.type !== 'error') {
      const {testimonial} = res;

      dispatch(updateTestimonialDispatch(testimonial));
    }
    return res;
  });
export const removeTestimonial = data => dispatch =>
  API.removeTestimonial(data).then(res => {
    if (res.type !== 'error') {
      const {testimonial} = res;

      dispatch(removeTestimonialDispatch(testimonial));
    }
    return res;
  });
