import React, {Component} from 'react';
import Slider from 'react-slick';
// import ReactImageVideoLightbox from "react-image-video-lightbox";

import FsLightbox from 'fslightbox-react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {userImage, DefaultImg} from '../Constants/Images';
import {BASE_URL} from '../Utils/env';
import {getThumbnailUrl, getMediaList, getPopupList} from '../Utils/Helpers';

class TestimonialSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: [],
      lightboxPopups: {},
      mediaFiles: [],
      fullWidth: false,
    };

    // this.toggleLightbox = this.toggleLightbox.bind(this);
  }

  UNSAFE_componentWillMount = () => {
    const {lightboxPopups} = this.state;
    const {testimonials, fullWidth} = this.props;

    testimonials.forEach((item, index) => {
      lightboxPopups[`light_box_show_${item.id}`] = false;
    });

    this.setState({
      testimonials,
      lightboxPopups,
      fullWidth: fullWidth ? fullWidth : false,
    });
  };

  toggleLightBox = (show, id) => {
    this.setState({
      lightboxPopups: {
        ...this.state.lightboxPopups,
        [`light_box_show_${id}`]: show,
      },
    });
  };

  addLightbox = () => {
    const {testimonials, lightboxPopups} = this.state;

    return testimonials.map((item, index) => {
      const popupList = getPopupList(item.review_files);

      console.log('PopupList:', popupList);

      return (
        <div key={`light_box_${index}`}>
          <FsLightbox
            toggler={lightboxPopups[`light_box_show_${item.id}`]}
            sources={popupList}
            // onClose={() => this.toggleLightBox(false, item.id)}
          />
        </div>
      );
    });
  };

  render() {
    const {testimonials, fullWidth} = this.state;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      // adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div className="row">
        {testimonials.map((item, index) => {
          let thumbImg = DefaultImg;
          const mediaList = getMediaList(item.review_files);

          const mediaType =
            Object.entries(mediaList).length > 0 ? mediaList[0]['type'] : '';
          const mediaUrl =
            Object.entries(mediaList).length > 0 ? mediaList[0]['url'] : '';

          // thumbImg = mediaType === "video" ? DefaultVideo : reviewMedias.mediaList[0]["url"];
          thumbImg = getThumbnailUrl(mediaUrl);

          let userImg = userImage;

          if (item.user_img) {
            userImg = `${BASE_URL}/${item.user_img}`;
          } else if (item.user.profile_img) {
            userImg = `${BASE_URL}/${item.user.profile_img}`;
          }

          return (
            <div
              className={
                fullWidth
                  ? 'col-md-4 col-sm-4 col-xs-12'
                  : 'col-md-4 col-sm-4 col-xs-12'
              }>
              <div className="slide-item" key={`testimonial_${index}`}>
                {/* {mediaType === 'video' ||
                  (mediaType === 'photo' && (
                    <div
                      className="testimonial-thumb"
                      onClick={() => this.toggleLightBox(true, item.id)}>
                      {mediaType === 'video' && (
                        <div className="video-wrapper">
                          <span className="far fa-play-circle play-icon" />
                        </div>
                      )}
                      {mediaType === 'photo' && <img src={thumbImg} alt="" />}
                    </div>
                  ))} */}
                <div className="testimonial-content">
                  <h2>{item.title}</h2>
                  <p>{item.review}</p>

                  <div className="user-name">
                    <div className="user-img">
                      <img src={userImg} alt="" />
                    </div>
                    {item.user_name ? item.user_name : item.user.name}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {this.addLightbox()}

        {/* {showLightBox && (
                    <ReactImageVideoLightbox
                        data={mediaFiles}
                        startIndex={0}
                        showResourceCount={true}
                        onCloseCallback={() => this.toggleLightBox(false)}
                    />
                )} */}
      </div>
    );
  }
}

export default TestimonialSlider;
