import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row, Form, Button, Alert } from "react-bootstrap";

import { addCategory } from "../../../Store/Actions/CauseActions-Admin";
import { appendFormData } from "../../../Utils/Helpers";

const initialUserState = {
    name: "",
};

class AddUser extends Component {
    state = {
        data: initialUserState,
        errors: {},
        saving: false,
        alertData: {
            show: false,
            type: "",
            message: "",
        },
    };

    UNSAFE_componentWillMount = () => {
        const { show, toggleModal } = this.props;
        this.setState({
            show,
            toggleModal,
        });
    };

    onChange = (e) => {
        const data = { ...this.state.data };
        data[e.target.name] = e.target.value;

        this.setState({
            data,
        });
    };

    validateData = () => {
        const { data } = this.state;
        const errors = {};

        if (!data.name || data.name === "") {
            errors.name = "Please enter Name.";
        }

        return errors;
    };

    saveCategoryDetails = () => {
        const { data } = this.state;
        const errors = this.validateData();

        if (Object.entries(errors).length === 0) {
            this.setState({ errors, saving: true });

            const formData = appendFormData(data);

            this.props
                .addCategory(formData)
                .then((res) => {
                    console.log(res);

                    this.setState({
                        saving: false,
                        data: res.type === "success" ? initialUserState : data,
                        alertData: {
                            show: true,
                            type: res.type === "error" ? "danger" : res.type,
                            message: res.message,
                        },
                    });
                })
                .catch((err) => {
                    this.setState({
                        saving: false,
                        alertData: {
                            show: true,
                            type: "danger",
                            message: err.message,
                        },
                    });
                    console.log(err);
                });
        } else {
            this.setState({
                errors,
            });
        }
    };

    hideErrMsg = () => {
        this.setState({
            alertData: {
                show: false,
                type: "",
                message: "",
            },
        });
    };

    render() {
        const { data, errors, show, toggleModal, alertData, saving } = this.state;

        return (
            <Modal className="medium" show={show} onHide={() => toggleModal("add_category_modal", false)}>
                <Modal.Header>
                    <Modal.Title>Add Category</Modal.Title>
                    <span
                        className="close as-link"
                        onClick={() => {
                            toggleModal("add_category_modal", false);
                        }}
                    >
                        x
                    </span>
                </Modal.Header>
                <Modal.Body>
                    {alertData && alertData.show && (
                        <Alert variant={alertData.type} onClose={() => this.hideErrMsg()} dismissible>
                            {alertData.message}
                        </Alert>
                    )}
                    <div className="modal-form-wrapper">
                        <Row>
                            <Col>
                                <Form.Group className="form-group" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        name="name"
                                        type="text"
                                        placeholder=""
                                        value={data.name}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {errors.name && <span className="errorMsg">{errors.name}</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button
                        onClick={() => {
                            if (!saving) {
                                this.saveCategoryDetails();
                            }
                        }}
                    >
                        {!saving && "Save"}
                        {saving && <i className="fas fa-spinner fa-spin" />}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(null, { addCategory })(AddUser);
