import React from 'react';
import {Link} from 'react-router-dom';
import {Logo} from '../../Constants/Images';
import SignUpForm from '../../Components/SignUpForm';

function SignUp(props) {
  return (
    <div className="auth-page-wrapper">
      <div className="auth-page-logo text-center">
        <Link to="/">
          <img src={Logo} alt="" />
        </Link>
      </div>
      <div className="auth-page-temp">
        <div className="heading-area">
          <h2>
            <span>Join our Community of</span> BUILD Philanthropists
          </h2>
          {/* <p>Together, we will transparently vote, distribute monthly grants and feel the impact.</p> */}
        </div>
        <div className="auth-page-container">
          <div className="auth-logo">
            {/* <img src={Logo} alt="" /> */}
            <h3>Get Started</h3>
            <p>Add your details below</p>
          </div>

          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
