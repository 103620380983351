/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {loadStripe} from '@stripe/stripe-js';
import {Col, Modal, Row, Form, Button, Alert} from 'react-bootstrap';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import {ALLOWED_COUNTRY, CARDS_LIMIT} from '../../Utils/env';
import {appendFormData} from '../../Utils/Helpers';
import {makeDonatePayments} from '../../Store/Actions/FrontActions';
import {getMyCard, getStripeKeys} from '../../Store/Actions/CommanAction';
import PhoneInput from 'react-phone-input-2';

const ContributionForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const {cards, user, usStates} = props;
  const [useNew, setUseNew] = useState(false);

  const isPledged = props.contribution_type === 'Pledge' ? 'Yes' : 'No';
  const defaultCard = user.stripe_payment_id ? user.stripe_payment_id : '';

  const [paymentStep, updateStep] = useState('');
  const [totalPaymnet, updateTotal] = useState('');
  const [phone, setPhone] = useState('');

  const [state, setState] = useState({
    data: {
      amount: props.user.amount ? props.user.amount : 10,
      address: props.user.state,
      contribution_plan: props.contribution_type
        ? props.contribution_type
        : 'Pledge',
      pledge_plan: props.contribution_type === 'Pledge' ? 'Monthly' : '',
      payment_id: defaultCard,
      is_auto_amount: 'Yes',
      save_my_card: 'Yes',
      is_new_user: user && !user.authToken ? 'Yes' : '',
    },
    errors: {},
    saving: false,
    min_amount: props.contribution_type !== 'Pledge' ? 5 : 60,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  });

  const onChange = e => {
    const tempdata = {...state.data};
    tempdata[e.target.name] = e.target.value;

    setState({
      ...state,
      data: tempdata,
    });
  };

  const updatePhone = phone => {
    const tempdata = {...state.data};

    tempdata['phone'] = phone;
    setState({
      ...state,
      data: tempdata,
    });
  };

  const onStateChange = e => {
    const {data} = state;
    const value = e.target.value;

    const stateData = getRegion(value);
    const region = stateData.region.name;

    setState({
      ...state,
      data: {
        ...data,
        ['state']: value,
        ['region']: region,
      },
    });
  };

  const getRegion = stateId => {
    const state = usStates.filter(item => item.id === stateId);

    return state ? state[0] : {};
  };

  const onCheckChange = e => {
    const tempdata = {...state.data};
    const value = e.target.value;
    let min_amount = 5;

    tempdata[e.target.name] = value;

    switch (value) {
      case 'Pledge':
        min_amount = 60;
        break;
      case 'Annually':
        min_amount = 60;
        break;
      case 'One-Time':
        min_amount = 5;
        break;
      case 'Monthly':
        min_amount = 5;
        break;

      default:
        break;
    }

    setState({
      ...state,
      data: tempdata,
      min_amount,
    });
  };

  const changePaymentId = e => {
    const payment_id = e.target.value;

    if (payment_id === 'New Card') {
      setUseNew(true);
    } else {
      setUseNew(false);
    }

    setState({
      ...state,
      data: {
        ...state.data,
        payment_id,
      },
    });
  };

  const validateData = () => {
    const {data, min_amount} = state;
    const errors = {};

    if (data.is_new_user === 'Yes') {
      if (!data.name || data.name === '') {
        errors.name = `Please enter Your Name`;
      }
      if (!data.phone || data.phone === '') {
        errors.phone = `Please enter valid Phone Number`;
      }
      if (!data.state || data.state === '') {
        errors.state = `Please select State`;
      }
    }

    if (!data.amount || data.amount === '') {
      errors.amount = `Please enter any Amount`;
    } else if (!parseInt(data.amount)) {
      errors.amount = `Please enter valid Amount`;
    } else if (data.amount < min_amount) {
      errors.amount = `Minimum contribution amount must be $${min_amount}`;
    }

    return errors;
  };

  const makePayment = event => {
    event.preventDefault();

    const errors = validateData();
    const {data} = state;

    if (Object.entries(errors).length === 0) {
      if (useNew) {
        payWithNewCard();
      } else {
        setState({...state, errors, saving: true});

        const formData = appendFormData(data);
        formData.append('use_new_card', 'No');

        donateNow(formData);
      }
    } else {
      setState({
        ...state,
        errors,
      });
    }
  };

  const payWithNewCard = async () => {
    try {
      if (elements == null) {
        return;
      }

      setState({...state, errors, saving: true});

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (!error) {
        const formData = appendFormData(data);
        formData.append('paynemt_details', JSON.stringify(paymentMethod));
        formData.append('use_new_card', 'Yes');

        donateNow(formData);
      } else {
        const tempErr = {
          carderr: error.message,
        };

        setState({...state, errors: tempErr, saving: false});
      }
    } catch (err) {
      setState({
        ...state,
        saving: false,
        alertData: {
          show: true,
          type: 'danger',
          message: err.message,
        },
      });
    }
  };

  const donateNow = formData => {
    props
      .donateNow(formData)
      .then(res => {
        if (res.type === 'success' && useNew) {
          elements.getElement(CardElement).clear();
        }

        if (res.type === 'success' && state.data.is_new_user === 'Yes') {
          updateStep('');
        }

        setState({
          ...state,
          data: {
            ...state.data,
            amount: res.type === 'success' ? 0 : state.data.amount,
          },
          errors: {},
          saving: false,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        setState({
          ...state,
          errors: {},
          saving: false,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const hideErrMsg = () => {
    setState({
      ...state,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  const ShowCardsOption = () => {
    const paymentId = state.data.payment_id;
    return (
      <>
        {cards.map((item, index) => (
          <Form.Group
            key={`payment_id_${item.payment_id}`}
            className="form-group"
            controlId="name">
            <Form.Check
              id={item.payment_id}
              name="payment_id"
              type={'radio'}
              checked={paymentId === item.payment_id ? 'checked' : ''}
              label={
                item.card_info &&
                `**** **** **** ${item.card_info.last4} - ${item.card_info.exp_month}/${item.card_info.exp_year}`
              }
              value={item.payment_id}
              onChange={e => changePaymentId(e)}
            />
          </Form.Group>
        ))}

        {cards &&
          Object.entries(cards).length !== 0 &&
          Object.entries(cards).length < CARDS_LIMIT && (
            <Form.Group className="form-group" controlId="name">
              <Form.Check
                id="New Card"
                name="payment_id"
                type={'radio'}
                label="Add New Card"
                value={'New Card'}
                checked={paymentId === 'New Card' ? 'checked' : ''}
                onChange={e => changePaymentId(e)}
              />
            </Form.Group>
          )}
      </>
    );
  };

  const updateFrequency = plan => {
    const innitialAmount = plan === 'Monthly' ? 10 : 60;
    const innitialMinAmount = plan === 'Monthly' ? 5 : 60;

    setState({
      ...state,
      data: {
        ...state.data,
        pledge_plan: plan,
        amount: innitialAmount,
      },
      min_amount: innitialMinAmount,
    });
  };

  const toggleAutoCheckbox = e => {
    const status = e.target.checked ? 'Yes' : '';
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: status,
      },
    });
  };

  const changeAmount = amount => {
    setState({
      ...state,
      data: {
        ...state.data,
        amount,
      },
    });
  };

  useEffect(() => {
    if (cards.length === 0) {
      setUseNew(true);
      setState({
        ...state,
        data: {
          ...state.data,
        },
      });
    } else {
      setUseNew(false);
    }
  }, [cards]);

  useEffect(() => {
    if (state.data.amount > 0) {
      let tempCalc = state.data.amount;
      tempCalc = parseFloat(tempCalc * 0.022 + 0.3).toFixed(2);

      updateTotal(tempCalc);
    } else {
      updateTotal(0);
    }
  }, [state.data.amount]);

  useEffect(() => {
    const initialTitle =
      props.contribution_type === 'One-Time'
        ? 'One Time Payment'
        : 'Contribution Setup';

    const modalTitle = paymentStep === '' ? initialTitle : 'Payment Details';
    props.updateTitle(modalTitle);
  }, [paymentStep]);

  const {data, errors, alertData, saving, min_amount} = state;

  const sampleAmount =
    data.pledge_plan !== 'Annually' || data.contribution_plan === 'One-Time'
      ? [5, 10, 15, 25]
      : [60, 120, 180, 300];

  return (
    <>
      {alertData && alertData.show && (
        <Alert
          variant={alertData.type}
          onClose={() => hideErrMsg()}
          dismissible>
          {alertData.message}
        </Alert>
      )}

      <form onSubmit={makePayment}>
        {paymentStep === '' && (
          <>
            {user && !user.authToken && (
              <>
                <Row>
                  <Col md={12}>
                    <Form.Group className="form-group" controlId="name">
                      {/* <Form.Label>Your Name</Form.Label> */}
                      <Form.Control
                        type="text"
                        name="name"
                        id={`name`}
                        placeholder="Your Name"
                        value={data.name}
                        className="form-control"
                        onChange={e => onChange(e)}
                      />
                      {errors.name && (
                        <span className="errorMsg">{errors.name}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="form-group" controlId="phone">
                      {/* <Form.Label>Your Phone</Form.Label> */}
                      <PhoneInput
                        className="form-control"
                        onlyCountries={ALLOWED_COUNTRY}
                        country={'us'}
                        value={data.phone}
                        placeholder="Phone"
                        onChange={phone => updatePhone(phone)}
                      />
                      {errors.phone && (
                        <span className="errorMsg">{errors.phone}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group" controlId="state">
                      <div className="select-dropdown">
                        <select
                          name="state"
                          value={data.state ? data.state : ''}
                          onChange={e => onStateChange(e)}
                          className="form-control">
                          <option value="">Choose State</option>
                          {Object.entries(usStates).length > 0 &&
                            usStates.map((item, index) => (
                              <option
                                value={item.id}
                                key={`state_index_${index}`}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.state && (
                        <span className="errorMsg">{errors.state}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {data.contribution_plan === 'Pledge' && (
              <Row>
                <Col>
                  <div className="modal-headings mt-1">
                    <h2>Contribution Frequency</h2>
                    <p>How often would you like to pay?</p>

                    <Form.Group
                      className="form-group example-amounts mb-2"
                      controlId="name">
                      <span
                        className={`btn btn-small btn-primary btn-outline ${
                          data.pledge_plan === 'Monthly' && 'active'
                        }`}
                        onClick={() => updateFrequency('Monthly')}>
                        Monthly
                      </span>

                      <span
                        className={`btn btn-small btn-primary btn-outline ${
                          data.pledge_plan === 'Annually' && 'active'
                        }`}
                        onClick={() => updateFrequency('Annually')}>
                        Annually
                      </span>

                      {errors.pledge_plan && (
                        <span className="errorMsg">{errors.pledge_plan}</span>
                      )}
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div className="modal-headings mt-0 mb-1">
                  {data.contribution_plan === 'Pledge' && (
                    <>
                      <h2>Recurring Contribution Amount</h2>
                      <p>How much would you like to give?</p>
                    </>
                  )}

                  {data.contribution_plan === 'One-Time' && (
                    <h2 className="mb-3">How much would you like to give?</h2>
                  )}

                  <div className="example-amounts">
                    {sampleAmount.map((item, index) => {
                      const activeClass = item === data.amount ? 'active' : '';
                      return (
                        <span
                          key={`example_amount_${index}`}
                          className={`btn btn-small btn-primary btn-outline ${activeClass}`}
                          onClick={() => changeAmount(item)}>
                          ${item}
                        </span>
                      );
                    })}
                  </div>

                  <Form.Group
                    className="form-group mt-4 mb-4"
                    controlId="amount">
                    <Form.Label>
                      Or you can add a custom Amount below
                    </Form.Label>
                    <div className="inputWithIcon">
                      <i className="fas fa-dollar-sign" />
                      <Form.Control
                        name="amount"
                        type="text"
                        placeholder={`Amount must be at least $${min_amount}`}
                        value={data.amount ? data.amount : ''}
                        onChange={e => onChange(e)}
                      />
                    </div>
                    <span className="info-text mt-3">
                      Total including Stripe processing fees : $
                      {parseFloat(totalPaymnet) + data.amount}
                    </span>
                    {errors.amount && (
                      <span className="errorMsg">{errors.amount}</span>
                    )}
                    {/* <span className="info-text">
                  * Minimum pledge donation is $5 monthly or $60 annually.
                </span> */}
                  </Form.Group>
                </div>
              </Col>
            </Row>
          </>
        )}

        {paymentStep === 'payment' && (
          <>
            {cards && Object.entries(cards).length !== 0 && (
              <>
                <Form.Group className="form-group" controlId="name">
                  <Form.Label>
                    Pay ${parseFloat(totalPaymnet) + data.amount} with
                  </Form.Label>
                </Form.Group>

                <ShowCardsOption />
              </>
            )}

            {useNew && (
              <Row>
                <Col>
                  <Form.Group className="form-group" controlId="name">
                    <Form.Label>Card Details</Form.Label>
                    <CardElement />
                    <span className="info-text">
                      All payments and cards are securely managed by industry
                      leader{' '}
                      <a href="https://www.stripe.com/" target="_blank">
                        Stripe.
                      </a>
                    </span>
                    {errors.carderr && (
                      <span className="errorMsg">{errors.carderr}</span>
                    )}
                  </Form.Group>

                  <Form.Group
                    className="form-group mt-4 mb-3"
                    controlId="save_my_card">
                    <span className="d-flex align-items-center">
                      <Form.Check
                        custom
                        type="checkbox"
                        name="save_my_card"
                        id={`save-my-card`}
                        label="Save My Card"
                        checked={data.save_my_card === 'Yes' ? 'checked' : ''}
                        value="Yes"
                        className="mr-3"
                        onChange={e => toggleAutoCheckbox(e)}
                      />
                    </span>
                  </Form.Group>

                  <Form.Group
                    className="form-group mt-0 mb-4"
                    controlId="is_auto_amount">
                    <span className="d-flex align-items-center">
                      <Form.Check
                        custom
                        type="checkbox"
                        name="is_auto_amount"
                        id={`is-auto-amount`}
                        label={`Automatically pull this amount ${data.pledge_plan}`}
                        checked={data.is_auto_amount === 'Yes' ? 'checked' : ''}
                        value="Yes"
                        className="mr-3"
                        onChange={e => toggleAutoCheckbox(e)}
                      />
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            )}
          </>
        )}

        <Row>
          <Col>
            {paymentStep === 'payment' && (
              <Button
                type="submit"
                className="back-btn btn-dark"
                onClick={() => updateStep('')}>
                Back
              </Button>
            )}
            {paymentStep === '' && (
              <>
                <Button
                  type="submit"
                  onClick={() => {
                    const errors = validateData();
                    if (Object.entries(errors).length === 0) {
                      updateStep('payment');
                    } else {
                      setState({
                        ...state,
                        errors,
                      });
                    }
                  }}>
                  Next
                </Button>
              </>
            )}
            {!saving && paymentStep === 'payment' && (
              <Button type="submit" onClick={() => makePayment}>
                Submit Payment
              </Button>
            )}
            {saving && (
              <Button>
                <i className="fas fa-spinner fa-spin" />
              </Button>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
};

const AddContribution = props => {
  const {
    show,
    type,
    user,
    myCards,
    makeDonatePayments,
    getMyCard,
    getStripeKeys,
    usStates,
  } = props;
  let stripePromise = '';
  const [stripe_public_key, setStripeKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  if (stripe_public_key) {
    stripePromise = loadStripe(stripe_public_key);
  }

  const [cardsList, setMyCards] = useState([]);

  const closeModal = () => {
    //if (user.isSubscribed !== 'No') {
    props.closeModal();
    // }
  };

  const getCards = () => {
    getMyCard()
      .then(res => {})
      .catch(err => {});
  };

  useEffect(() => {
    setLoading(true);
    getStripeKeys()
      .then(res => {
        setLoading(false);
        setStripeKey(res.stripe_key);
      })
      .catch(e => {
        setLoading(false);
        console.log('Err :', e);
      });

    getCards();
  }, []);

  useEffect(() => {
    setMyCards(myCards);
  }, [myCards]);

  useEffect(() => {
    const modalTitle =
      type === 'One-Time' ? 'One Time Payment' : 'Contribution Setup';

    setModalTitle(modalTitle);
  }, [type]);

  //console.log('type :', type);

  return (
    <Modal
      id="contribution-modal"
      className="small"
      show={show}
      onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>

        <span className="close as-link" onClick={() => closeModal()}>
          x
        </span>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className={`modal-form-wrapper `}>
          {loading && (
            <div className="mt-5 mb-5 text-center">
              <span className="fas fa-spinner fa-spin" />
            </div>
          )}

          {!loading && stripe_public_key && (
            <Elements stripe={stripePromise}>
              <ContributionForm
                contribution_type={type}
                user={user}
                cards={cardsList}
                donateNow={data => makeDonatePayments(data)}
                updateTitle={title => setModalTitle(title)}
                usStates={usStates}
              />
            </Elements>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateProps = state => ({
  categories: state.allCauseCategories,
  regions: state.allCauseRegions,
  user: state.user,
  myCards: state.myCards,
  usStates: state.usStates,
});

export default connect(mapStateProps, {
  makeDonatePayments,
  getMyCard,
  getStripeKeys,
})(AddContribution);
