const Logo = require('../Assets/images/logo.png');
const LogoDark = require('../Assets/images/logo_black.png');
const Favicon = require('../Assets/images/favicon.png');
const DefaultImg = require('../Assets/images/default-img.jpg');
const DefaultVideo = require('../Assets/images/default-video.jpg');
const userImage = require('../Assets/images/user-img.png');
const footerLogo = require('../Assets/images/logo.png');
const ProcessIcon1 = require('../Assets/images/our-process-icon-1.png');
const ProcessIcon2 = require('../Assets/images/our-process-icon-2.png');
const ProcessIcon3 = require('../Assets/images/our-process-icon-3.png');
const MobilePhone = require('../Assets/images/phone-img.png');
const PlaystoreImg = require('../Assets/images/playstore.png');
const ApplestoreImg = require('../Assets/images/apple-store.png');

/* New Homepage */
const girlImg = require('../Assets/NewHome/images/girl-img.jpg');
const PhoneImg = require('../Assets/NewHome/images/phone-img.png');
const christinaImg = require('../Assets/NewHome/images/christina-wocintechchat.jpg');
const processIcon1 = require('../Assets/NewHome/images/our-process-icon-1.png');
const processIcon2 = require('../Assets/NewHome/images/our-process-icon-2.png');
const processIcon3 = require('../Assets/NewHome/images/our-process-icon-3.png');
const sideImg = require('../Assets/NewHome/images/side-img.png');
const laptopMobile = require('../Assets/NewHome/images/laptop-mobile.png');
const logoVector = require('../Assets/NewHome/images/logo-vector.png');
const bannerImg = require('../Assets/NewHome/images/banner-img.png');
const mrFrankSr = require('../Assets/NewHome/images/mr_frank_sr.jpeg');

export {
  Logo,
  logoVector,
  bannerImg,
  LogoDark,
  Favicon,
  DefaultImg,
  DefaultVideo,
  ProcessIcon1,
  ProcessIcon2,
  ProcessIcon3,
  MobilePhone,
  PlaystoreImg,
  ApplestoreImg,
  userImage,
  footerLogo,
  girlImg,
  PhoneImg,
  christinaImg,
  processIcon1,
  processIcon2,
  processIcon3,
  sideImg,
  laptopMobile,
  mrFrankSr,
};
