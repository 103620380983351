import {
    PAGE_LOADED,
    UPADATE_WINDOW_WIDTH,
    SIDEBAR_MENU_STATE,
    GET_SETTINGS,
    GET_STATES,
    GET_MY_CARD,
    ADD_MY_CARD,
    UPDATE_MY_CARD,
    REMOVE_MY_CARD,
    GET_NOTIFICATIONS,
} from "../../Constants/Types";

export const pageLoaded = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case PAGE_LOADED:
            return action.status;
        default:
            return state;
    }
};
export const windowWidth = (state = 1200, action = {}) => {
    switch (action.type) {
        // Projects API
        case UPADATE_WINDOW_WIDTH:
            return action.width;
        default:
            return state;
    }
};

export const usStates = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case GET_STATES:
            return action.states;
        default:
            return state;
    }
};

export const menuState = (state = false, action = {}) => {
    switch (action.type) {
        case SIDEBAR_MENU_STATE:
            return action.status;
        default:
            return state;
    }
};
export const settings = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_SETTINGS:
            return action.settings;
        default:
            return state;
    }
};

export const notifications = (state = {}, action = {}) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return action.notifications;
        default:
            return state;
    }
};

export const myCards = (state = [], action = {}) => {
    let cards;
    switch (action.type) {
        case GET_MY_CARD:
            return action.cards;
        case ADD_MY_CARD:
            cards = state;
            if (Object.entries(cards).length > 0) {
                cards.unshift(action.card);
            } else {
                cards = cards.push(action.card);
            }
            console.log("Cards", cards);
            return cards;
        case UPDATE_MY_CARD:
            cards = state.map((card) => {
                if (card.id === action.card.id) return { ...card, ...action.card };
                return card;
            });
            return cards;
        case REMOVE_MY_CARD:
            cards = state.filter((card) => {
                return card.id !== action.card.id;
            });
            return cards;
        default:
            return state;
    }
};
