import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';

import API from '../Utils/API';
import {convertNumber} from '../Utils/Helpers';
import FrontRemainingAmount from './Modals/FrontRemainingAmount';
import {YearMonths} from '../Constants';

class FrontDashboard extends Component {
  state = {
    loading: true,
    headingData: {
      globalFunds: 0,
      emergencyFunds: 0,
      distributedAmount: 0,
      carryOver: 0,
    },
    region: '',
    availableFundsModal: false,
  };

  UNSAFE_componentWillMount = () => {
    const {region} = this.props;

    this.setState(
      {
        region,
      },
      () => {
        this.getHeadingData();
      },
    );
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {region} = props;

    this.setState(
      {
        region,
      },
      () => {
        this.getHeadingData();
      },
    );
  };

  toggleModal = (modal, status) => {
    this.setState({
      [modal]: status,
    });
  };

  getHeadingData = () => {
    const {region} = this.state;

    const formData = new FormData();
    formData.append('region', region);

    API.getHomeHeadingData(formData)
      .then(res => {
        const newState = {
          loading: false,
          alertData: {
            show: true,
            type: 'danger',
            message: res.message,
          },
        };

        if (res.type === 'success') {
          const {data} = res;

          newState.headingData = {
            globalFunds: data.globalFunds,
            emergencyFunds: data.emergencyFunds,
            distributedAmount: data.distributedAmount,
            carryOver: data.carryOver,
          };
        }

        this.setState(newState);
      })
      .catch(err => {
        this.setState({
          loading: false,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  render() {
    const {headingData, loading, availableFundsModal} = this.state;
    const monthText = new Date();

    const carryOverAmount = headingData.carryOver
      ? convertNumber(headingData.carryOver)
      : 0;

    return (
      <div className="front-dashboard">
        <Row>
          <Col>
            <div
              className="card as-link"
              onClick={() => this.toggleModal('availableFundsModal', true)}>
              <div className="top-card">
                <span className="icon fas fa-dollar-sign"></span>
                <div className="top-card-content">
                  {loading && (
                    <h2 className="text-center">
                      <i
                        class="fas fa-spinner fa-spin"
                        style={{fontSize: '16px'}}></i>
                    </h2>
                  )}
                  {!loading && (
                    <>
                      <h2>${convertNumber(headingData.distributedAmount)}</h2>
                      {
                        <p>
                          {YearMonths[monthText.getMonth()]}
                          {` `} Total Contributions
                        </p>
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card">
              <div className="top-card">
                <span className="icon fas fa-dollar-sign"></span>
                <div className="top-card-content">
                  <h2>${convertNumber(headingData.globalFunds)}</h2>
                  <p>Available Global Funds</p>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card">
              <div className="top-card">
                <span className="icon fas fa-dollar-sign"></span>
                <div className="top-card-content">
                  <h2>${convertNumber(headingData.emergencyFunds)}</h2>
                  <p>Available Emergency Funds</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {availableFundsModal && (
          <FrontRemainingAmount
            show={availableFundsModal}
            closeModal={() => this.toggleModal('availableFundsModal', false)}
          />
        )}
      </div>
    );
  }
}

export default FrontDashboard;
