/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Accordion, Button} from 'react-bootstrap';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../../Assets/NewHome/css/style.css';
import '../../Assets/NewHome/css/responsive.css';

import {
  Logo,
  logoVector,
  girlImg,
  PhoneImg,
  christinaImg,
  processIcon1,
  processIcon2,
  processIcon3,
  sideImg,
  laptopMobile,
  bannerImg,
  mrFrankSr,
} from '../../Constants/Images';

import {faqs} from '../../Constants';
import {getTestimonials} from '../../Store/Actions/FrontActions';

import {TestimonialSlider, AddContribution} from '../../Components';
import {scrollTo} from '../../Utils/Helpers';

class Home extends Component {
  state = {
    testimonials: [],
    menuOpen: false,
    currentUser: {},
    donateModal: {
      show: false,
      type: 'Pledge',
    },
  };

  UNSAFE_componentWillMount = () => {
    const {currentUser} = this.props;

    this.setState({
      currentUser,
    });

    this.props
      .getTestimonials()
      .then(res => {
        const {testimonials} = res;
        this.setState({testimonials});
      })
      .catch(err => {
        console.log('Err :', err);
      });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {currentUser} = props;

    this.setState({
      currentUser,
    });
  };

  mobileToggle = menuState => {
    this.setState({
      menuOpen: menuState,
    });
  };

  contributeBtns = () => {
    const {currentUser} = this.state;
    return (
      <>
        {currentUser && !currentUser.authToken && (
          <Link to="/register" className="btn btn-primary btn-outline">
            Start Contributing
          </Link>
        )}
        {currentUser && currentUser.authToken && (
          <Link
            to="/causes#start-contribute"
            className="btn btn-primary btn-outline">
            Start Contributing
          </Link>
        )}
      </>
    );
  };

  render() {
    const {testimonials, menuOpen, currentUser, donateModal} = this.state;
    const menuClass = menuOpen ? 'open' : '';
    const year = new Date();
    const dateYear = year.getFullYear();

    const regsisterLink =
      currentUser && !currentUser.authToken ? '/register' : '/causes';

    return (
      <>
        {/* Header */}
        <div className="site-header">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="logo">
                  <img src={logoVector} alt="" className="logo-vector-img" />
                  <Link to="/">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className={`navigation-wrapper ${menuClass}`}>
                  <div
                    className="hambergar"
                    onClick={() => this.mobileToggle(!menuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="navigation">
                    <div className="top-menu-wrapper">
                      <div className="login-links">
                        <ul>
                          {currentUser && !currentUser.authToken && (
                            <li>
                              <Link to="/login">Login</Link>
                            </li>
                          )}
                          {currentUser && currentUser.authToken && (
                            <li>
                              <Link to="/causes">Dashboard</Link>
                            </li>
                          )}
                          <li>
                            {/* {currentUser && !currentUser.authToken && (
                              <Link to="/register">
                                <Button className="btn btn-small btn-outline">
                                  Give a One-Time Donation
                                </Button>
                              </Link>
                            )}
                            {currentUser && currentUser.authToken && ( */}
                            <Button
                              onClick={() =>
                                this.setState({
                                  donateModal: {
                                    show: true,
                                    type: 'One-Time',
                                  },
                                })
                              }
                              className="btn btn-small btn-outline">
                              Give a One-Time Donation
                            </Button>
                            {/* )} */}
                          </li>
                        </ul>
                      </div>
                      {/* <div className="social-icons text-right">
                        <ul>
                          <li className="social">
                            <a href="#" target="_blank">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li className="social">
                            <a
                              href="https://instagram.com/trying.to.build"
                              target="_blank">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                    <ul>
                      <li>
                        <a href="#home">Home</a>
                      </li>
                      <li>
                        <a href="#about-us">About</a>
                      </li>
                      <li>
                        <a href="#how-it-works">How It Works</a>
                      </li>
                      <li>
                        <a href="#faq">FAQs</a>
                      </li>
                      <li>
                        {currentUser && !currentUser.authToken && (
                          <Link to={regsisterLink}>Become A Pledger</Link>
                        )}
                        {currentUser && currentUser.authToken && (
                          <Link to="/causes#start-contribute">
                            Become A Pledger
                          </Link>
                        )}
                      </li>
                      {/* <li><a href="#" className="one-time-donation">Make One-Time Donation</a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Banner */}
        <div id="home" className="banner-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="banner-content">
                  <h2>
                    <span>Give As One</span>
                    The transparent path to impact
                  </h2>
                  {this.contributeBtns()}
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="banner-img">
                  <img src={bannerImg} alt="" />
                </div>
              </div>
            </div>

            <div className="banner-content-wrapper">
              <h2>BUILD Foundation is a 501c(3) nonprofit organization</h2>
            </div>
          </div>
        </div>

        <div className="about-wrapper">
          <div className="container">
            <div className="about-content">
              <h2>Become A BUILD Philanthropist</h2>
              <p>
                Through group economics, our community of philanthropists
                distribute monthly grants to dreams, hardships and community
                causes.
              </p>
              {this.contributeBtns()}
            </div>
            <div className="about-bottom-content">
              <p>Contribute as Low as $5 Monthly</p>
            </div>
          </div>
        </div>

        {testimonials && Object.entries(testimonials).length > 0 && (
          <div className="testimonial-wrapper mt-4">
            <div className="container">
              <div className="impect-greenbox-wrapper">
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="greenbox-item">
                      <h3>Year Founded</h3>
                      <p>2022</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="greenbox-item">
                      <h3>Toal People Impacted</h3>
                      <p>Over 300</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="greenbox-item">
                      <h3>Active Philanthropists</h3>
                      <p>Over 50</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="headings text-center">
                <h2>Recent Impact</h2>
                {/* <p>
                  When we come together as one, our donations can truly change
                  lives.
                </p> */}
              </div>
              <div className="testimonial-slider">
                <TestimonialSlider testimonials={testimonials} />
                <a href="#" className="view-all-btns">
                  View More
                </a>
              </div>

              <div className="content-btn">{this.contributeBtns()}</div>
            </div>
          </div>
        )}

        <div id="how-it-works" className="our-process-wrapepr">
          <div className="container">
            <div className="headings text-center">
              <h2>How It Works</h2>
              <p>
                Every dollar is tracked and transparently distributed through
                our dashboard available after making your initial contribution
              </p>
            </div>
            <div className="process-wrap">
              <div className="row">
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="process-item">
                    <div className="process-item-img">
                      <img src={processIcon1} alt="" />
                    </div>
                    <div className="process-item-content">
                      <h2>Give</h2>
                      <p>Choose monthly or annually</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="process-item">
                    <div className="process-item-img">
                      <img src={processIcon2} alt="" />
                    </div>
                    <div className="process-item-content">
                      <h2>Vote</h2>
                      <p>Nominate and Explore causes</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="process-item">
                    <div className="process-item-img">
                      <img src={processIcon3} alt="" />
                    </div>
                    <div className="process-item-content">
                      <h2>Inspire</h2>
                      <p>View history of support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-btn">{this.contributeBtns()}</div>
          </div>
        </div>

        <div className="mobile-content-wrapper fg-testimonial-wrapper no-bg-img">
          <div className="container">
            <div className="mobile-content">
              <div className="user-img">
                <img src={mrFrankSr} alt="" />
              </div>
              <h2>“There is only one way to BUILD, and that’s together”</h2>
              <div className="auther-info">
                <h4>Frank Goodman Sr.</h4>
                <p>Co-Founder / CEO of BUILD Foundation</p>
              </div>
            </div>
          </div>
        </div>

        <div id="faq" className="faq-wrapper">
          <div className="container">
            <div className="faq">
              <div
                className="headings text-center animate"
                data-animation="fadeInUp">
                <h2>FAQs</h2>
              </div>

              <Accordion defaultActiveKey="0">
                {Object.entries(faqs).length > 0 &&
                  faqs.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>

        {/* <!-- Footer --> */}
        <div className="footer-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-12">
                <div className="mobile-footer-icons">
                  <div className="social-icons">
                    <ul>
                      <li>
                        <a href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/trying.to.build"
                          target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="copyright">
                  <p>BUILD Foundation Inc. {dateYear} All Rights Reserved</p>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="footer-links">
                  <ul></ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span class="scrollToTop" onClick={() => scrollTo()}>
          <i class="fas fa-angle-up"></i>
        </span>

        {donateModal.show && (
          <AddContribution
            show={donateModal.show}
            type={donateModal.type}
            closeModal={() => {
              this.setState({
                donateModal: {
                  show: false,
                  type: '',
                },
              });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateProps = state => ({
  currentUser: state.user,
});

export default connect(mapStateProps, {getTestimonials})(Home);
