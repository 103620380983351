import React, {useEffect, useState} from 'react';
import {Accordion, Button, Form, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';

import {getSettings} from '../../../Store/Actions/CommanAction';
import {convertNumber} from '../../../Utils/Helpers';

const allInitial = {
  total: 0,
  expenses: 0,
  available: 0,
  globalFunds: 0,
  globalFundsMax: 0,
  globalPercentage: 0,
  emergencyFunds: 0,
  emergencyFundsMax: 0,
  emergencyPercentage: 0,
  recepientAvailable: 0,
  recepientAvailableAmount: 0,
  carryOver: 0,
};

const NominationFundDistribution = props => {
  const [nominationsMonths, setNominationMonths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, toggleModal] = useState(true);
  const [showNominations, toggleShowNomination] = useState(true);
  const [data, setData] = useState([]);

  const [errors, setErrors] = useState({});
  const [allFunds, setAllFunds] = useState(allInitial);

  const [selectedMonth, setSelectedMonth] = useState({});
  const [monthIndex, setMonthIndex] = useState('');

  const [selectedRegion, setSelectedRegion] = useState({});
  const [regionIndex, setRegionIndex] = useState('');

  const changeMonth = e => {
    const index = e.target.value;
    setMonthIndex(index);
    setSelectedMonth(nominationsMonths[index]);
  };

  const changeRegion = e => {
    const index = e.target.value;
    const regionData = index ? data[index]['data'] : {};
    setRegionIndex(index);

    setSelectedRegion(regionData);
  };

  const validateErrors = () => {
    const err = {};

    setErrors(err);
  };

  const updateData = (e, index) => {
    const err = {};
    const tempData = [...data];
    const fieldVal = e.target.value ? parseFloat(e.target.value) : 0;

    tempData[index]['data'][e.target.name] = fieldVal;

    if (e.target.name === 'globalFunds') {
      tempData[index]['data']['globalPercentage'] = getPercentage(
        fieldVal,
        tempData[index]['data']['available'],
      );

      tempData[index]['data']['recepientAvailable'] =
        tempData[index]['data']['available'] -
        fieldVal -
        tempData[index]['data']['emergencyFunds'];

      tempData[index]['data']['recepientAvailableAmount'] =
        tempData[index]['data']['recepientAvailable'];

      tempData[index]['data']['carryOver'] =
        tempData[index]['data']['available'] -
        fieldVal -
        tempData[index]['data']['emergencyFunds'] -
        tempData[index]['data']['recepientAvailable'];

      // Updating Max Values
      tempData[index]['data']['emergencyFundsMax'] =
        tempData[index]['data']['available'] - fieldVal;

      if (fieldVal > tempData[index]['data']['globalFundsMax']) {
        err.globalFunds = `Global amount can not exist $${tempData[index]['data']['globalFundsMax']}`;
      }
    } else if (e.target.name === 'emergencyFunds') {
      // New Percentage value
      tempData[index]['data']['emergencyPercentage'] = getPercentage(
        fieldVal,
        tempData[index]['data']['available'],
      );

      tempData[index]['data']['recepientAvailable'] =
        tempData[index]['data']['available'] -
        tempData[index]['data']['globalFunds'] -
        fieldVal;

      tempData[index]['data']['recepientAvailableAmount'] =
        tempData[index]['data']['recepientAvailable'];

      tempData[index]['data']['carryOver'] =
        tempData[index]['data']['available'] -
        tempData[index]['data']['globalFunds'] -
        tempData[index]['data']['recepientAvailable'] -
        fieldVal;

      if (fieldVal > tempData[index]['data']['emergencyFundsMax']) {
        err.emergencyFunds = `Emergency amount can not exist $${tempData[index]['data']['emergencyFundsMax']}`;
      }
    } else if (e.target.name === 'recepientAvailableAmount') {
      tempData[index]['data']['carryOver'] =
        tempData[index]['data']['available'] -
        tempData[index]['data']['globalFunds'] -
        tempData[index]['data']['emergencyFunds'] -
        fieldVal;

      if (fieldVal > tempData[index]['data']['recepientAvailable']) {
        err.recepientAvailableAmount = `Recipient amount can not exist $${tempData[index]['data']['recepientAvailable']}`;
      }
    }

    setErrors(err);
    setData(tempData);
  };

  const calculateAllData = data => {
    const tempData = {...allInitial};

    for (let i = 0; i < data.length; i++) {
      tempData['total'] = tempData['total'] + data[i]['data']['total'];
      tempData['expenses'] = tempData['expenses'] + data[i]['data']['expenses'];
      tempData['available'] =
        tempData['available'] + data[i]['data']['available'];

      tempData['globalFunds'] =
        parseFloat(tempData['globalFunds']) +
        parseFloat(data[i]['data']['globalFunds']);

      tempData['emergencyFunds'] =
        parseFloat(tempData['emergencyFunds']) +
        parseFloat(data[i]['data']['emergencyFunds']);

      tempData['recepientAvailable'] =
        parseFloat(tempData['recepientAvailable']) +
        parseFloat(data[i]['data']['recepientAvailable']);
      tempData['recepientAvailableAmount'] =
        parseFloat(tempData['recepientAvailableAmount']) +
        parseFloat(data[i]['data']['recepientAvailableAmount']);

      tempData['carryOver'] =
        parseFloat(tempData['carryOver']) +
        parseFloat(data[i]['data']['carryOver']);
    }

    tempData['emergencyPercentage'] = getPercentage(
      tempData['emergencyFunds'],
      tempData['available'],
    );

    tempData['globalPercentage'] = getPercentage(
      tempData['globalFunds'],
      tempData['available'],
    );

    setAllFunds(tempData);
  };

  const getPercentage = (amount, TotalAmount) => {
    return convertNumber((100 * parseFloat(amount)) / parseFloat(TotalAmount));
  };

  const updateNomidationAmount = (e, regionIndex, causeIndex) => {
    const fieldVal = e.target.value ? parseFloat(e.target.value) : 0;
    const tempData = [...data];

    tempData[regionIndex]['data']['nominattedCauses'][causeIndex][
      'distributedAmount'
    ] = fieldVal;

    setData(tempData);
  };

  useEffect(() => {
    calculateAllData(data);
  }, [data]);

  useEffect(() => {
    if (selectedMonth && selectedMonth.regions) {
      const regions = [...selectedMonth.regions];
      setData(regions);
    }
  }, [selectedMonth]);

  useEffect(() => {
    setNominationMonths(props.nominations);
    setData(props.nominations[0]['regions']);
  }, [props.nominations]);

  return (
    <>
      <Modal
        className="medium"
        show={props.show}
        onHide={() => props.hideModal(false)}>
        <Modal.Header>
          <Modal.Title>Distribute Fund</Modal.Title>
          <span
            className="close as-link"
            onClick={() => props.hideModal(false)}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="remaining-amount-wrapper distribution-amount-wrapper">
            <div className="heading">
              <div className="months-wrapper">
                <div className="select-wrapper">
                  <Form.Control as="select" onChange={e => changeRegion(e)}>
                    <option value="">All</option>
                    {data &&
                      Object.entries(data).length > 0 &&
                      data.map((item, index) => {
                        return (
                          <option
                            key={`region-select-${index}-${item.index}`}
                            value={index}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </div>
              </div>

              <div className="months-wrapper">
                <div className="select-wrapper">
                  <Form.Control as="select" onChange={e => changeMonth(e)}>
                    {/* <option value="">All</option> */}
                    {nominationsMonths &&
                      Object.entries(nominationsMonths).length > 0 &&
                      nominationsMonths.map((item, index) => (
                        <option value={index}>{item.title}</option>
                      ))}
                  </Form.Control>
                </div>
              </div>
            </div>

            <div className="remaining-amount-data-wrapper ">
              {loading && (
                <div className="mt-5 mb-5 text-center">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              )}

              {!loading && (
                <div className="listing-tables mt-0">
                  <table className="table table-responsive">
                    <tbody>
                      <tr>
                        <th>Total Collected</th>
                        <td align="right">
                          $
                          {Object.entries(selectedRegion).length > 0
                            ? selectedRegion['total']
                            : allFunds['total']}
                        </td>
                      </tr>
                      <tr>
                        <th>Expenses</th>
                        <td align="right">
                          $
                          {Object.entries(selectedRegion).length > 0
                            ? selectedRegion['expenses']
                            : allFunds['expenses']}
                        </td>
                      </tr>
                      <tr>
                        <th>Total Available</th>
                        <td align="right">
                          $
                          {Object.entries(selectedRegion).length > 0
                            ? selectedRegion['available']
                            : allFunds['available']}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Global{' '}
                          <span className="info-text">
                            (
                            {Object.entries(selectedRegion).length > 0
                              ? selectedRegion.globalPercentage
                              : allFunds.globalPercentage}
                            % of Total Availble)
                          </span>
                        </th>
                        <td align="right">
                          {Object.entries(selectedRegion).length > 0 ? (
                            <>
                              <div className="inputWithIcon">
                                <i className="fas fa-dollar-sign" />
                                <input
                                  type="text"
                                  name="globalFunds"
                                  value={selectedRegion['globalFunds']}
                                  className="text-right"
                                  onChange={e => updateData(e, regionIndex)}
                                />
                              </div>
                              {errors && errors.globalFunds && (
                                <span className="info-text error">
                                  {errors.globalFunds}
                                </span>
                              )}
                            </>
                          ) : (
                            `$${allFunds['globalFunds']}`
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Emergency{' '}
                          <span className="info-text">
                            (
                            {Object.entries(selectedRegion).length > 0
                              ? selectedRegion.emergencyPercentage
                              : allFunds.emergencyPercentage}
                            % of Total Availble)
                          </span>
                        </th>

                        <td align="right">
                          {Object.entries(selectedRegion).length > 0 ? (
                            <>
                              <div className="inputWithIcon">
                                <i className="fas fa-dollar-sign" />
                                <input
                                  type="text"
                                  name="emergencyFunds"
                                  value={selectedRegion['emergencyFunds']}
                                  className="text-right"
                                  onChange={e => updateData(e, regionIndex)}
                                />
                              </div>
                              {errors && errors.emergencyFunds && (
                                <span className="info-text error">
                                  {errors.emergencyFunds}
                                </span>
                              )}
                            </>
                          ) : (
                            `$${allFunds['emergencyFunds']}`
                          )}
                        </td>
                      </tr>

                      <tr>
                        <th>
                          Recipient Distribution{' '}
                          <span className="info-text">
                            ($
                            {Object.entries(selectedRegion).length > 0
                              ? selectedRegion.recepientAvailable
                              : allFunds.recepientAvailable}{' '}
                            Available)
                          </span>
                        </th>

                        <td align="right">
                          {Object.entries(selectedRegion).length > 0 ? (
                            <>
                              <div className="inputWithIcon">
                                <i className="fas fa-dollar-sign" />
                                <input
                                  type="text"
                                  name="recepientAvailableAmount"
                                  value={
                                    selectedRegion['recepientAvailableAmount']
                                  }
                                  className="text-right"
                                  onChange={e => updateData(e, regionIndex)}
                                />
                              </div>
                              {errors && errors.recepientAvailableAmount && (
                                <span className="info-text error">
                                  {errors.recepientAvailableAmount}
                                </span>
                              )}
                            </>
                          ) : (
                            `$${allFunds['recepientAvailableAmount']}`
                          )}
                        </td>
                      </tr>

                      {selectedRegion.nominattedCauses &&
                        Object.entries(selectedRegion.nominattedCauses).length >
                          0 && (
                          <tr>
                            <td colSpan={2}>
                              <div className="distribute-nominations-list">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item>
                                    <Accordion.Header>
                                      Nominations
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <table className="table table-responsive">
                                        {selectedRegion.nominattedCauses.map(
                                          (causeItem, causeIndex) => (
                                            <tr key={`cause-key-${causeIndex}`}>
                                              <th>
                                                {causeItem.title}
                                                <span className="info-text">
                                                  (
                                                  {getPercentage(
                                                    causeItem.distributedAmount,
                                                    selectedRegion[
                                                      'recepientAvailableAmount'
                                                    ],
                                                  )}
                                                  % of Recipient Distribution)
                                                </span>
                                              </th>
                                              <td align="right">
                                                <div className="inputWithIcon">
                                                  <i className="fas fa-dollar-sign" />
                                                  <input
                                                    type="text"
                                                    name="recepientAvailableAmount"
                                                    value={
                                                      causeItem.distributedAmount
                                                    }
                                                    className="text-right"
                                                    onChange={e =>
                                                      updateNomidationAmount(
                                                        e,
                                                        regionIndex,
                                                        causeIndex,
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {errors &&
                                                  errors.recepientAvailableAmount && (
                                                    <span className="info-text error">
                                                      {
                                                        errors.recepientAvailableAmount
                                                      }
                                                    </span>
                                                  )}
                                              </td>
                                            </tr>
                                          ),
                                        )}
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </td>
                          </tr>
                        )}

                      <tr>
                        <th>&nbsp;</th>
                        <td align="right">&nbsp;</td>
                      </tr>
                      <tr>
                        <th>Carryover Amount</th>
                        <td align="right">
                          $
                          {Object.entries(selectedRegion).length > 0
                            ? selectedRegion.carryOver
                            : allFunds.carryOver}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              {monthIndex &&
                nominationsMonths[monthIndex]['isDistributed'] === false &&
                nominationsMonths[monthIndex]['title'] !== 'Current Month' && (
                  <Button onClick={() => {}}>Distribute Funds</Button>
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(null, {})(NominationFundDistribution);
