import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Modal, Row, Form, Button, Alert} from 'react-bootstrap';

import {addEvent} from '../../../Store/Actions/AdminAction';
import {getAllRegions} from '../../../Store/Actions/FrontActions';
import {appendFormData} from '../../../Utils/Helpers';

const initialUserState = {
  title: '',
  type: '',
  notes: '',
  collected_amount: '',
  expences: '',
  region: '',
  event_date: '',
  add_to: 'Global Funds',
};

class AddEvent extends Component {
  state = {
    data: initialUserState,
    errors: {},
    saving: false,
    regions: [],
    alertData: {
      show: false,
      type: '',
      message: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const {show, toggleModal} = this.props;

    this.props.getAllRegions().catch(err => {
      console.log('Err :', err);
    });

    this.setState({
      show,
      toggleModal,
    });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {regions} = props;
    this.setState({
      regions,
    });
  };

  onChange = e => {
    const data = {...this.state.data};
    data[e.target.name] = e.target.value;

    this.setState({
      data,
    });
  };

  validateData = () => {
    const {data} = this.state;
    const errors = {};

    if (!data.title || data.title === '') {
      errors.title = 'Please enter Title.';
    }
    if (!data.type || data.type === '') {
      errors.type = 'Please select Type.';
    }

    if (!data.notes || data.notes === '') {
      errors.notes = 'Please enter Notes.';
    }

    if (!data.collected_amount || data.collected_amount === '') {
      errors.collected_amount = 'Please enter Collected Amount.';
    }

    if (!data.expences || data.expences === '') {
      errors.expences = 'Please enter Expences.';
    }

    if (!data.add_to || data.add_to === '') {
      errors.add_to = 'Please enter Add To Option.';
    }

    if (
      data.add_to === 'Regional Funds' &&
      (!data.region || data.region === '')
    ) {
      errors.region = 'Please select Region.';
    }

    if (!data.event_date || data.event_date === '') {
      errors.event_date = 'Please select Event Date.';
    }

    return errors;
  };

  saveEvent = () => {
    const {data} = this.state;
    const errors = this.validateData();

    if (Object.entries(errors).length === 0) {
      this.setState({errors, saving: true});

      const formData = appendFormData(data);

      this.props
        .addEvent(formData)
        .then(res => {
          console.log(res);

          this.setState({
            saving: false,
            data: res.type === 'success' ? initialUserState : data,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
          });
        })
        .catch(err => {
          this.setState({
            saving: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
          console.log(err);
        });
    } else {
      this.setState({
        errors,
      });
    }
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  addAmontField = () => {
    const {data, errors} = this.state;
    return (
      <Form.Group className="form-group" controlId="amount">
        <Form.Label>Amount</Form.Label>
        <div className="inputWithIcon">
          <i className="fas fa-dollar-sign" />
          <Form.Control
            name="amount"
            type="text"
            placeholder="Enter Amount"
            value={data.amount}
            onChange={e => this.onChange(e)}
          />
        </div>
        {errors.amount && <span className="errorMsg">{errors.amount}</span>}
      </Form.Group>
    );
  };

  render() {
    const {regions, data, errors, show, alertData, saving} = this.state;

    return (
      <Modal
        className="medium"
        show={show}
        onHide={() => this.props.closeModal()}>
        <Modal.Header>
          <Modal.Title>Add Event</Modal.Title>
          <span
            className="close as-link"
            onClick={() => this.props.closeModal()}>
            x
          </span>
        </Modal.Header>
        <Modal.Body>
          {alertData && alertData.show && (
            <Alert
              variant={alertData.type}
              onClose={() => this.hideErrMsg()}
              dismissible>
              {alertData.message}
            </Alert>
          )}
          <div className="modal-form-wrapper">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Title"
                    value={data.title}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.title && (
                    <span className="errorMsg">{errors.title}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-group" controlId="type">
                  <Form.Label>Event Type</Form.Label>
                  <div className="select-dropdown">
                    <select
                      name="type"
                      value={data.type}
                      onChange={e => this.onChange(e)}
                      className="form-control">
                      <option value="">Select</option>
                      <option>Fundraising</option>
                      <option>Marketing</option>
                    </select>
                  </div>
                  {errors.type && (
                    <span className="errorMsg">{errors.type}</span>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="form-group mb-0" controlId="event_date">
                  <Form.Label>Event Date</Form.Label>
                  <Form.Control
                    name="event_date"
                    type="date"
                    placeholder=""
                    value={data.event_date}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.event_date && (
                    <span className="errorMsg">{errors.event_date}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group" controlId="collected_amount">
                  <Form.Label>Collected Amount</Form.Label>
                  <div className="inputWithIcon">
                    <i className="fas fa-dollar-sign" />
                    <Form.Control
                      name="collected_amount"
                      type="text"
                      placeholder="Collected Amount"
                      value={data.collected_amount}
                      onChange={e => this.onChange(e)}
                    />
                  </div>
                  {errors.collected_amount && (
                    <span className="errorMsg">{errors.collected_amount}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6} xs={12}>
                <Form.Group className="form-group" controlId="expences">
                  <Form.Label>Expenses</Form.Label>
                  <div className="inputWithIcon">
                    <i className="fas fa-dollar-sign" />
                    <Form.Control
                      name="expences"
                      type="text"
                      placeholder="expences"
                      value={data.expences}
                      onChange={e => this.onChange(e)}
                    />
                  </div>
                  {errors.expences && (
                    <span className="errorMsg">{errors.expences}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="form-group" controlId="add_to">
                  <Form.Label>Add to</Form.Label>
                  <div className="select-dropdown">
                    <select
                      name="add_to"
                      value={data.add_to}
                      onChange={e => this.onChange(e)}
                      className="form-control">
                      <option value="">Select</option>
                      <option>Regional Funds</option>
                      <option>Global Funds</option>
                      <option>Emergency Funds</option>
                    </select>
                  </div>
                  {errors.add_to && (
                    <span className="errorMsg">{errors.add_to}</span>
                  )}
                </Form.Group>
              </Col>

              {data.add_to === 'Regional Funds' && (
                <Col md={6} sm={6} xs={12}>
                  <Form.Group className="form-group" controlId="region">
                    <Form.Label>Region</Form.Label>
                    <div className="select-dropdown">
                      <select
                        name="region"
                        value={data.region}
                        onChange={e => this.onChange(e)}
                        className="form-control">
                        <option value="">Select Region</option>
                        {regions &&
                          Object.entries(regions).length > 0 &&
                          regions.map((item, index) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                    {errors.region && (
                      <span className="errorMsg">{errors.region}</span>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="form-group" controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    placeholder="Notes"
                    value={data.notes}
                    onChange={e => this.onChange(e)}
                  />
                  {errors.notes && (
                    <span className="errorMsg">{errors.notes}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button
            onClick={() => {
              if (!saving) {
                this.saveEvent();
              }
            }}>
            {!saving && 'Save'}
            {saving && <i className="fas fa-spinner fa-spin" />}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateProps = state => ({
  regions: state.allCauseRegions,
});

export default connect(mapStateProps, {addEvent, getAllRegions})(AddEvent);
