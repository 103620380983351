import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

import Dashboard from './Dashboard';
import CausesList from './CausesList';
import CauseCategories from './CauseCategories';
import CauseRegions from './CauseRegions';
import Users from './Users';
import SubscriptionList from './SubscriptionList';
import PaymentsList from './PaymentsList';
import Settings from './Settings';
import Expenses from './ExpencesList';
import EventsList from './EventsList';
import Testimonials from './TestimonialList';
import PagesContent from './PagesContent';
import Notifications from './Notifications';

// import PageNotFound from "../404";

import {Header, SidebarMenu} from '../../Components';

const AdminPage = ({location, menuState}) => {
  const isOpen = menuState ? 'open' : '';
  return (
    <div className={`admin-wrapper ${isOpen}`}>
      <SidebarMenu location={location} />
      <div className="admin-content">
        <Header />

        <Route exact path="/admin" component={Dashboard} />
        <Route exact path="/admin/dashboard" component={Dashboard} />
        <Route exact path="/admin/causes" component={CausesList} />
        <Route exact path="/admin/causes/:params" component={CausesList} />
        <Route exact path="/admin/categories" component={CauseCategories} />
        <Route exact path="/admin/regions" component={CauseRegions} />
        <Route
          exact
          path="/admin/regions/page/:page"
          component={CauseRegions}
        />
        <Route exact path="/admin/expenses" component={Expenses} />
        <Route exact path="/admin/expenses/page/:page" component={Expenses} />

        <Route exact path="/admin/events" component={EventsList} />
        <Route exact path="/admin/events/page/:page" component={EventsList} />

        <Route exact path="/admin/testimonials" component={Testimonials} />
        <Route
          exact
          path="/admin/testimonials/page/:page"
          component={Testimonials}
        />

        <Route exact path="/admin/subscriptions" component={SubscriptionList} />
        <Route
          exact
          path="/admin/subscriptions/page/:page"
          component={SubscriptionList}
        />
        <Route exact path="/admin/payments" component={PaymentsList} />
        <Route
          exact
          path="/admin/payments/page/:page"
          component={PaymentsList}
        />

        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/users/page/:page" component={Users} />
        <Route exact path="/admin/pages-content" component={PagesContent} />
        <Route
          exact
          path="/admin/pages-content/:params"
          component={PagesContent}
        />

        <Route exact path="/admin/notificatoins" component={Notifications} />
        <Route
          exact
          path="/admin/notificatoins/:params"
          component={Notifications}
        />
        <Route exact path="/admin/settings" component={Settings} />
        <Route exact path="/admin/settings/:param" component={Settings} />
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {})(AdminPage);
