import axios from 'axios';
import {API_URL} from './env';

const postData = (url, data) => {
  return axios({
    method: 'post',
    url: url,
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  }).then(res => res.data);
};

const getData = url => axios(url).then(res => res.data);

const API = {
  // Auth
  requestOtp: data => postData(`${API_URL}/login`, data),
  registerOtpRequest: data => postData(`${API_URL}/register-otp-request`, data),
  verifyOtp: data => postData(`${API_URL}/verify-otp`, data),
  getUserProfile: (id = '') =>
    getData(`${API_URL}/get-user-profile/?userId=${id}`),
  saveUserProfile: data => postData(`${API_URL}/save-user-profile`, data),

  // Admin
  createAuthClient: redirect =>
    getData(`${API_URL}/create-youtube-client?redirect=${redirect}`),
  verifyAuthCode: data => postData(`${API_URL}/verify-youtube-auth-code`, data),
  resetAuthToken: () => getData(`${API_URL}/reset-youtube-auth-token`),

  getDashboardData: regId =>
    getData(`${API_URL}/get-dashboard-data?region=${regId}`),
  approveUsers: id => getData(`${API_URL}/approve-user/${id}`),
  approveCauses: id => getData(`${API_URL}/approve-cause/${id}`),
  getSettings: () => getData(`${API_URL}/get-settings`),
  saveSettings: data => postData(`${API_URL}/save-settings`, data),
  getNotifications: () => getData(`${API_URL}/get-notifications`),
  saveNotifications: data => postData(`${API_URL}/save-notifications`, data),

  getAllUsers: () => getData(`${API_URL}/get-all-users`),
  getUsers: page => getData(`${API_URL}/get-users/page/${page}`),
  addUser: data => postData(`${API_URL}/add-user`, data),
  updateUser: data => postData(`${API_URL}/update-user`, data),
  removeUser: data => postData(`${API_URL}/remove-user`, data),

  getAdminPastCauses: page =>
    getData(`${API_URL}/get-causes/page/${page}?nomination_type=past`),
  getAdminCauses: page => getData(`${API_URL}/get-causes/page/${page}`),
  addAdminCause: data => postData(`${API_URL}/add-cause`, data),
  updateAdminCause: data => postData(`${API_URL}/update-cause`, data),
  removeAdminCause: data => postData(`${API_URL}/remove-cause`, data),

  getCategories: page => getData(`${API_URL}/get-categories/page/${page}`),
  addCategory: data => postData(`${API_URL}/add-category`, data),
  updateCategory: data => postData(`${API_URL}/update-category`, data),
  removeCategory: data => postData(`${API_URL}/remove-category`, data),

  getRegions: page => getData(`${API_URL}/get-regions/page/${page}`),
  addRegion: data => postData(`${API_URL}/add-region`, data),
  updateRegion: data => postData(`${API_URL}/update-region`, data),
  removeRegion: data => postData(`${API_URL}/remove-region`, data),

  getExpensesList: page => getData(`${API_URL}/get-expenses/page/${page}`),
  addExpense: data => postData(`${API_URL}/add-expense`, data),
  updateExpense: data => postData(`${API_URL}/update-expense`, data),
  removeExpense: data => postData(`${API_URL}/remove-expense`, data),

  getEventsList: page => getData(`${API_URL}/get-events/page/${page}`),
  addEvent: data => postData(`${API_URL}/add-event`, data),
  updateEvent: data => postData(`${API_URL}/update-event`, data),
  removeEvent: data => postData(`${API_URL}/remove-event`, data),

  getTestimonialsList: page =>
    getData(`${API_URL}/get-testimonials/page/${page}`),
  addTestimonial: data => postData(`${API_URL}/add-testimonial`, data),
  updateTestimonial: data => postData(`${API_URL}/update-testimonial`, data),
  removeTestimonial: data => postData(`${API_URL}/remove-testimonial`, data),

  getSubscriptionList: page =>
    getData(`${API_URL}/get-subscriptions/page/${page}`),
  updateSubscription: data => postData(`${API_URL}/update-subscription`, data),
  removeSubscription: data => postData(`${API_URL}/remove-subscription`, data),

  getPaymentsList: page => getData(`${API_URL}/get-payments/page/${page}`),
  removePaymentItem: data => postData(`${API_URL}/remove-payment`, data),

  getTermPages: () => getData(`${API_URL}/get-term-pages`),
  saveTermPages: data => postData(`${API_URL}/save-term-pages`, data),
  importData: data => postData(`${API_URL}/import-donation-data`, data),

  resetDonationData: () => getData(`${API_URL}/reset-donation-data`),

  // Front
  getAvailableFunds: data => postData(`${API_URL}/front-available-funds`, data),
  getHomeHeadingData: data => postData(`${API_URL}/home-dashboard-data`, data),

  updateCauseVote: data => postData(`${API_URL}/update-vote`, data),
  getCauseRequests: page =>
    getData(`${API_URL}/get-causes-requests/page/${page}`),
  getCauseDatails: causeId => getData(`${API_URL}/get-cause/${causeId}`),
  getSimilarCauses: data => postData(`${API_URL}/get-similar-data`, data),
  getCategoryCauses: data => postData(`${API_URL}/get-category-causes`, data),
  getAllStates: () => getData(`${API_URL}/get-states`),
  getAllCategories: () => getData(`${API_URL}/get-all-categories`),
  getAllRegions: () => getData(`${API_URL}/get-all-regions`),
  saveUserCause: data => postData(`${API_URL}/save-user-cause`, data),

  getMyCauses: page => getData(`${API_URL}/get-my-causes/page/${page}`),
  updateMyCause: data => postData(`${API_URL}/update-my-cause`, data),
  removeMyCause: data => postData(`${API_URL}/remove-my-cause`, data),
  saveCard: data => postData(`${API_URL}/save-my-card`, data),
  getMyCard: () => getData(`${API_URL}/get-my-card`),
  assignPrimary: id => postData(`${API_URL}/assing-primary-card?id=${id}`),
  deleteCard: id => postData(`${API_URL}/remove-my-card?id=${id}`),

  getMySubscriptions: page =>
    getData(`${API_URL}/get-my-subscriptions/page/${page}`),
  cancelMySubscription: data =>
    postData(`${API_URL}/cancel-my-subscription`, data),
  getMyPayments: data => postData(`${API_URL}/get-my-payments`, data),

  getCurrentContribution: () => getData(`${API_URL}/get-contribution`),
  updateContribution: data => postData(`${API_URL}/update-contribution`, data),

  makeDonation: data => postData(`${API_URL}/make-donation`, data),
  saveRatting: data => postData(`${API_URL}/save-review`, data),
  getTestimonials: () => getData(`${API_URL}/get-testimonials`),
  getStripeKeys: () => getData(`${API_URL}/get-stripe-key`),
};

export default API;
