/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Alert, Tab, Tabs} from 'react-bootstrap';
import {connect} from 'react-redux';

import moment from 'moment';
import MomentDate from 'react-moment';

import {AddCauseFront, EditCauseFront} from '../../Components';
import TestimonialList from './TestimonialList';
import {
  getAdminCuasesList,
  getAdminPastCuasesList,
  removeAdminCuase,
} from '../../Store/Actions/CauseActions-Admin';
import {updateUrl} from '../../Utils/Helpers';

const CausesList = props => {
  const page = props.match.params.page ? props.match.params.page : 1;
  const {
    adminCausesList,
    adminPastCauses,
    getAdminPastCuasesList,
    getAdminCuasesList,
    removeAdminCuase,
    usStates,
  } = props;

  const [states, setState] = useState({
    bulk_action_id: [],
    listLoading: false,
    add_cause_modal: false,
  });

  const [pastCauseList, setPastData] = useState({
    loading: false,
    pagging: {},
    page: 1,
  });
  const [pastCausesItems, setPastCausesItems] = useState([]);

  const [causesList, setCauses] = useState([]);
  const [alertData, setAlert] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [activeTab, setActiveTab] = useState('current-nominations');

  const toggleModal = (modal, value) => {
    setState({
      ...states,
      [modal]: value,
    });
  };

  const fetchPastCauses = page => {
    setPastData({
      ...pastCauseList,
      pagging: {},
      loading: true,
    });

    getAdminPastCuasesList(page)
      .then(res => {
        const tempUpdate = {
          ...pastCauseList,
          loading: false,
        };
        if (res.type === 'success') {
          const CurrentPageNo =
            res.pagging && res.pagging.current
              ? parseInt(res.pagging.current)
              : 1;

          tempUpdate.pagging = res.pagging;
          tempUpdate.page = CurrentPageNo;
        }
        setPastData(tempUpdate);
      })
      .catch(err => {
        console.log(err);
        setPastData({
          ...pastCauseList,
          loading: false,
        });
        setAlert({
          show: true,
          type: 'danger',
          message: err.message,
        });
      });
  };

  const fetchCauses = page => {
    setState({
      ...states,
      listLoading: true,
    });

    getAdminCuasesList(page)
      .then(res => {
        const newState = {
          ...states,
          listLoading: false,
        };
        if (res.type === 'success') {
          const CurrentPageNo =
            res.pagging && res.pagging.current
              ? parseInt(res.pagging.current)
              : 1;

          const newUrl =
            parseInt(CurrentPageNo) === 1
              ? `/admin/causes`
              : `/admin/causes/page/${page}`;
          updateUrl(newUrl, props.history);

          newState.pagging = res.pagging;
          newState.page = CurrentPageNo;
        }
        setState(newState);
      })
      .catch(err => {
        console.log(err);
        setState({
          ...states,
          listLoading: false,
        });
        setAlert({
          show: true,
          type: 'danger',
          message: err.message,
        });
      });
  };

  const deleteCause = id => {
    const formData = new FormData();
    formData.append('id', id);
    removeAdminCuase(formData)
      .then(res => {
        setAlert({
          show: true,
          type: res.type === 'error' ? 'danger' : res.type,
          message: res.message,
        });
      })
      .catch(err => {
        setAlert({
          show: true,
          type: 'danger',
          message: err.message,
        });
      });
  };

  const toggleAllBulk = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      causesList.forEach(item => {
        if (bulk_action_id.indexOf(`${item.id}`) === -1) {
          bulk_action_id.push(`${item.id}`);
        }
      });
    } else {
      bulk_action_id = [];
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const toggleBulkItems = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      if (bulk_action_id.indexOf(`${e.target.value}`) === -1) {
        bulk_action_id.push(`${e.target.value}`);
      }
    } else {
      bulk_action_id = bulk_action_id.filter(
        (item, index) => `${item}` !== `${e.target.value}`,
      );
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const enableEditModal = cause => {
    const tempCause = {...cause};
    setState({
      ...states,
      edit_cause_modal: true,
      edit_cause: tempCause,
    });
  };

  const getState = id => {
    const state =
      Object.entries(usStates).length > 0
        ? usStates.filter(item => item.id === id)
        : [];
    return Object.entries(state).length > 0 ? state[0] : [];
  };

  const _renderItems = cause => {
    const stateId = getState(cause.state);
    const timestamp = moment.utc(parseInt(cause.add_time) * 1000);
    return (
      <tr key={`causes_key_${cause.id}`}>
        <td align="center">
          {/* <input
            type="checkbox"
            name="bulk_action[]"
            value={cause.id}
            checked={
              bulk_action_id.indexOf(`${cause.id}`) !== -1 ? true : false
            }
            onChange={e => toggleBulkItems(e)}
          /> */}
          &nbsp;
        </td>
        <td>{cause.title}</td>
        <td>${cause.fund_amount}</td>
        <td>{cause.category ? cause.category.name : ''}</td>
        <td>{stateId.name}</td>
        <td>
          <MomentDate date={timestamp} format="MM/DD/YYYY" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span className="actionIcon" onClick={() => enableEditModal(cause)}>
              <i className="fas fa-pen" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => deleteCause(cause.id)}>
              <i className="fas fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  const setUrlHash = activeTab => {
    setActiveTab(activeTab);

    if (activeTab === 'past-nominations') {
      fetchPastCauses(pastCauseList.page);
    }

    // const newUrl = `/admin/causes/#${activeTab}`;
    // updateUrl(newUrl, props.history);
  };

  useEffect(() => {
    if (page) {
      fetchCauses(page);
    }
  }, [page]);

  useEffect(() => {
    if (Object.entries(adminCausesList).length > 0) {
      setCauses(adminCausesList);
    } else {
      setCauses([]);
    }
  }, [adminCausesList]);

  useEffect(() => {
    if (Object.entries(adminPastCauses).length > 0) {
      setPastCausesItems(adminPastCauses);
    } else {
      setPastCausesItems([]);
    }
  }, [adminPastCauses]);

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      setActiveTab(hash);
    }
  }, []);

  const {
    add_cause_modal,
    edit_cause_modal,
    edit_cause,
    bulk_action_id,
    pagging,
    listLoading,
  } = states;

  return (
    <div className="admin-body">
      <div className="container">
        <div className="body-card">
          <div className="heading">
            <h2>Causes Nomination</h2>
            <div className="filter-options-wrapper">
              <span
                className="btn btn-primary btn-small add-new-btn"
                onClick={() => {
                  toggleModal('add_cause_modal', true);
                }}>
                Add New
              </span>
            </div>
          </div>

          <div className="body-content">
            <div className="listing-tables mt-0">
              {alertData && alertData.show && (
                <div className="alerts">
                  <Alert
                    variant={alertData.type}
                    onClose={() =>
                      setAlert({
                        show: false,
                        type: '',
                        message: '',
                      })
                    }
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}

              <Tabs
                defaultActiveKey={activeTab}
                id="uncontrolled-tab-example"
                onSelect={k => {
                  setUrlHash(k);
                }}>
                <Tab
                  eventKey="current-nominations"
                  title="Current Nominations"
                  tabAttrs={{link: '#current-nominations'}}>
                  <table className="table table-inverse table-responsive mt-4">
                    <thead className="thead-inverse">
                      <tr>
                        <th width="10" className="text-center">
                          &nbsp;
                          {/* <input                          
                            type="checkbox"
                            id="chkAll"
                            onChange={e => {
                              toggleAllBulk(e);
                            }}
                          /> */}
                        </th>
                        <th>Title</th>
                        <th>Fund Amount</th>
                        <th>Category</th>
                        <th>State</th>
                        <th width="130">Add Time</th>

                        {/* <th width="150">Date</th> */}
                        <th width="130" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listLoading && (
                        <tr>
                          <td align="center" colSpan={7}>
                            <span className="fas fa-spinner fa-spin" />
                          </td>
                        </tr>
                      )}
                      {!listLoading &&
                        Object.entries(causesList).length === 0 && (
                          <tr>
                            <td align="center" colSpan={7}>
                              No Causes Available.
                            </td>
                          </tr>
                        )}
                      {!listLoading &&
                        Object.entries(causesList).length > 0 &&
                        causesList.map(cause => _renderItems(cause))}
                    </tbody>
                  </table>

                  {pagging && Object.entries(pagging).length > 0 && (
                    <div className="pagging-wrapper">
                      {pagging &&
                        pagging.current != '' &&
                        pagging.pages != '' &&
                        pagging.totalItems != '' && (
                          <div className="pagging-text">
                            <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                          </div>
                        )}

                      <div className="pagging-btns">
                        {pagging.previous && (
                          <span
                            className="btn btn-small"
                            onClick={() => fetchCauses(pagging.previous)}>
                            Previous
                          </span>
                        )}
                        {pagging.next && (
                          <span
                            className="btn btn-small next-btn"
                            onClick={() => fetchCauses(pagging.next)}>
                            Next
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="past-nominations"
                  title="Past Nominations"
                  tabAttrs={{link: '#past-nominations'}}>
                  {console.log('pastCauseList :', pastCauseList)}

                  <table className="table table-inverse table-responsive mt-4">
                    <thead className="thead-inverse">
                      <tr>
                        <th width="10" className="text-center">
                          &nbsp;
                          {/* <input
                            type="checkbox"
                            id="chkAll"
                            onChange={e => {
                              toggleAllBulk(e);
                            }}
                          /> */}
                        </th>
                        <th>Title</th>
                        <th>Fund Amount</th>
                        <th>Category</th>
                        <th>State</th>
                        <th width="130">Add Time</th>

                        {/* <th width="150">Date</th> */}
                        <th width="130" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pastCauseList.loading && (
                        <tr>
                          <td align="center" colSpan={7}>
                            <span className="fas fa-spinner fa-spin" />
                          </td>
                        </tr>
                      )}
                      {!pastCauseList.loading &&
                        Object.entries(pastCausesItems).length === 0 && (
                          <tr>
                            <td align="center" colSpan={7}>
                              No Causes Available.
                            </td>
                          </tr>
                        )}
                      {!pastCauseList.loading &&
                        Object.entries(pastCausesItems).length > 0 &&
                        pastCausesItems.map(cause => _renderItems(cause))}
                    </tbody>
                  </table>
                  {pastCauseList.pagging && (
                    <div className="pagging-wrapper">
                      {pastCauseList.pagging.current &&
                        pastCauseList.pagging.pages &&
                        pastCauseList.pagging.totalItems && (
                          <div className="pagging-text">
                            <p>{`Page : ${pastCauseList.pagging.current} of ${pastCauseList.pagging.pages} - Total ${pastCauseList.pagging.totalItems} Items`}</p>
                          </div>
                        )}

                      <div className="pagging-btns">
                        {pastCauseList.pagging.previous && (
                          <span
                            className="btn btn-small"
                            onClick={() =>
                              fetchPastCauses(pastCauseList.pagging.previous)
                            }>
                            Previous
                          </span>
                        )}
                        {pastCauseList.pagging.next && (
                          <span
                            className="btn btn-small next-btn"
                            onClick={() => fetchPastCauses(pagging.next)}>
                            Next
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="responses"
                  title="Awarded"
                  tabAttrs={{link: '#responses'}}>
                  <TestimonialList />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      {add_cause_modal && (
        <AddCauseFront
          show={add_cause_modal}
          closeModal={() => toggleModal('add_cause_modal', false)}
        />
      )}

      {edit_cause_modal && (
        <EditCauseFront
          isAdmin={true}
          show={edit_cause_modal}
          cause={edit_cause}
          closeModal={() => toggleModal('edit_cause_modal', false)}
        />
      )}
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  adminCausesList: state.adminCauses,
  adminPastCauses: state.adminPastCauses,
  usStates: state.usStates,
});

export default connect(mapStateProps, {
  getAdminCuasesList,
  getAdminPastCuasesList,
  removeAdminCuase,
})(CausesList);
