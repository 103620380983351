import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {menuItems} from '../../../Constants/Menu';

import Profile from './Profile';

import {logout} from '../../../Store/Actions/AuthActions';

const SidebarMenu = props => {
  const {location, menuState, logout} = props;

  const _activeCheck = item => {
    let isActive = false;
    const currentPage = location.pathname;

    if (currentPage === item.link) {
      isActive = true;
    }

    if (item.submenu && Object.entries(item.submenu).length > 0) {
      for (let i = 0; i < Object.entries(item.submenu).length; i++) {
        const submenuItem = item.submenu[i];

        if (currentPage === submenuItem.link) {
          isActive = true;
        }
      }
    }

    return isActive;
  };

  const _renderSubMenuItem = (item, index) => {
    const isActive = _activeCheck(item) ? 'active' : '';
    return (
      <li key={`submenu_key_${index}`} className={isActive}>
        <Link to={item.link} title={item.label}>
          {item.icon && (
            <>
              <i className={item.icon} />
              {/* <FontAwesomeIcon icon={item.icon} className="icon" /> */}
            </>
          )}
          <span className="menu-label">{item.label}</span>
        </Link>
      </li>
    );
  };

  const _toggleSubmenu = index => {
    const submenuLi = document.getElementById(`parent_menu_${index}`);
    if (submenuLi.classList.contains('open')) {
      submenuLi.classList.remove('open');
    } else {
      submenuLi.classList.add('open');
    }
  };

  const _renderMenuItem = (item, index) => {
    let isActive = _activeCheck(item) ? 'active' : '';
    isActive += item.link === '' ? ' withPadding' : '';
    isActive +=
      Object.entries(item.submenu).length > 0 ? ' has-child as-link' : '';
    return (
      <li
        id={`parent_menu_${index}`}
        key={`menu_key_${index}`}
        className={isActive}>
        <span className="menu-text" onClick={() => _toggleSubmenu(index)}>
          {item.link !== '' && (
            <Link to={item.link} title={item.label}>
              {item.icon && (
                <>
                  <i className={item.icon} />
                  {/* <FontAwesomeIcon icon={item.icon} className="icon" /> */}
                </>
              )}
              <span className="menu-label">{item.label}</span>
            </Link>
          )}
          {item.link === '' && (
            <>
              {item.icon && (
                <>
                  <i className={item.icon} />
                  {/* <FontAwesomeIcon icon={item.icon} className="icon" /> */}
                </>
              )}
              <span className="menu-label">{item.label}</span>
            </>
          )}
          {Object.entries(item.submenu).length > 0 && (
            <span className="submenu-icon" />
          )}
        </span>
        {Object.entries(item.submenu).length > 0 && (
          <ul className="sub-menu">
            {item.submenu.map((subItem, index) =>
              _renderSubMenuItem(subItem, index),
            )}
          </ul>
        )}
      </li>
    );
  };

  const d = new Date();
  const currentYear = d.getFullYear();
  const menuStatus = menuState ? 'open' : '';

  return (
    <div className={`sidebar-menu-wrapper ${menuStatus}`}>
      <Profile />
      <div className="sidebar-navigation">
        {Object.entries(menuItems).length > 0 && (
          <ul>
            {menuItems.map((item, index) => _renderMenuItem(item, index))}
          </ul>
        )}
      </div>
      <div className="sidebar-footer">
        <span className="menu-text">
          {/* <Link to="/admin/settings">
            <span className="fas fa-cog" /> Settings
          </Link> */}

          <Link to="/admin/settings">
            <span className="as-link">
              <span className="fas fa-cog" />
              <span className="menu-label">{`  `}Settings</span>
            </span>
          </Link>

          {/* <Link to="/admin/settings">
                        <span className="fas fa-cog" />
                    </Link> */}
          <div className="copyright-text mt-0">
            <p>Copyright &copy;{currentYear}</p>
          </div>
        </span>
      </div>
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {logout})(SidebarMenu);
