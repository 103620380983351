/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Alert} from 'react-bootstrap';
import {connect} from 'react-redux';

import moment from 'moment';
import MomentDate from 'react-moment';

import {AddEvent, EditEvent} from '../../Components';
import {getEventsList, removeEvent} from '../../Store/Actions/AdminAction';
import {updateUrl} from '../../Utils/Helpers';

const EventsList = props => {
  const page = props.match.params.page ? props.match.params.page : 1;
  const {eventsList, getEventsList, removeEvent} = props;

  const [events, setEvents] = useState([]);

  const [states, setState] = useState({
    bulk_action_id: [],
    listLoading: false,
    add_event_modal: false,
    alertData: {},
  });

  const toggleModal = (modal, value) => {
    setState({
      ...states,
      [modal]: value,
    });
  };

  const hideErrMsg = () => {
    setState({
      ...states,
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  const fetchExpenses = page => {
    setState({
      ...states,
      listLoading: true,
    });

    getEventsList(page)
      .then(res => {
        const newState = {
          ...states,
          listLoading: false,
        };
        if (res.type === 'success') {
          const CurrentPageNo =
            res.pagging && res.pagging.current
              ? parseInt(res.pagging.current)
              : 1;

          const newUrl =
            parseInt(CurrentPageNo) === 1
              ? `/admin/events`
              : `/admin/events/page/${page}`;
          updateUrl(newUrl, props.history);

          newState.pagging = res.pagging;
          newState.page = CurrentPageNo;
        }
        setState(newState);
      })
      .catch(err => {
        console.log(err);
        setState({
          ...states,
          listLoading: false,
          alertData: {
            show: false,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const deleteEvent = id => {
    const formData = new FormData();
    formData.append('id', id);

    removeEvent(formData)
      .then(res => {
        setState({
          ...states,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      })
      .catch(err => {
        setState({
          ...states,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  const toggleAllBulk = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      events.forEach(item => {
        if (bulk_action_id.indexOf(`${item.id}`) === -1) {
          bulk_action_id.push(`${item.id}`);
        }
      });
    } else {
      bulk_action_id = [];
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const toggleBulkItems = e => {
    let {bulk_action_id} = states;

    if (e.target.checked) {
      if (bulk_action_id.indexOf(`${e.target.value}`) === -1) {
        bulk_action_id.push(`${e.target.value}`);
      }
    } else {
      bulk_action_id = bulk_action_id.filter(
        (item, index) => `${item}` !== `${e.target.value}`,
      );
    }

    setState({
      ...states,
      bulk_action_id,
    });
  };

  const enableEditModal = event => {
    const tempEvent = {...event};
    setState({
      ...states,
      edit_event_modal: true,
      edit_event: tempEvent,
    });
  };

  const _renderItems = event => {
    const timestamp = moment.utc(parseInt(event.event_date) * 1000);
    return (
      <tr key={`causes_key_${event.id}`}>
        <td align="center">
          {/* <input
            type="checkbox"
            name="bulk_action[]"
            value={event.id}
            checked={
              bulk_action_id.indexOf(`${event.id}`) !== -1 ? true : false
            }
            onChange={e => toggleBulkItems(e)}
          /> */}
          &nbsp;
        </td>
        <td>{event.title}</td>
        <td>{event.type}</td>
        <td>${event.collected_amount}</td>
        <td>${event.expences}</td>
        <td>{event.add_to ? event.add_to : '-'}</td>
        <td>{event.region ? event.region.name : '-'}</td>

        <td>
          <MomentDate date={timestamp} format="MM/DD/YYYY" />
        </td>
        <td className="text-center" valign="middle">
          <div className="action-wrapper">
            <span className="actionIcon" onClick={() => enableEditModal(event)}>
              <i className="fas fa-pen" />
            </span>
            <span
              className="actionIcon danger"
              onClick={() => deleteEvent(event.id)}>
              <i className="fas fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (page) {
      fetchExpenses(page);
    }
  }, [page]);

  useEffect(() => {
    if (Object.entries(eventsList).length > 0) {
      setEvents(eventsList);
    } else {
      setEvents([]);
    }
  }, [eventsList]);

  const {
    add_event_modal,
    edit_event_modal,
    edit_event,
    bulk_action_id,
    pagging,
    alertData,
    listLoading,
  } = states;

  return (
    <div className="admin-body">
      <div className="container">
        {/* <Breadcrumb title="Expenses" /> */}

        <div className="body-card">
          <div className="heading">
            <h2>Events</h2>
            <div className="filter-options-wrapper">
              <span
                className="btn btn-primary btn-small add-new-btn"
                onClick={() => {
                  toggleModal('add_event_modal', true);
                }}>
                Add New
              </span>
            </div>
          </div>

          <div className="body-content">
            <div className="listing-tables mt-0">
              {alertData && alertData.show && (
                <div className="alerts">
                  <Alert
                    variant={alertData.type}
                    onClose={() => hideErrMsg()}
                    dismissible>
                    {alertData.message}
                  </Alert>
                </div>
              )}

              <table className="table table-inverse table-responsive">
                <thead className="thead-inverse">
                  <tr>
                    <th width="10" className="text-center">
                      {/* <input
                        type="checkbox"
                        id="chkAll"
                        onChange={e => {
                          toggleAllBulk(e);
                        }}
                      /> */}
                      &nbsp;
                    </th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Collected</th>
                    <th>Expenses</th>
                    <th>Distribution</th>
                    <th>Region</th>
                    <th width="130">Event Date</th>
                    <th width="130" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listLoading && (
                    <tr>
                      <td align="center" colSpan={9}>
                        <span className="fas fa-spinner fa-spin" />
                      </td>
                    </tr>
                  )}
                  {!listLoading && Object.entries(events).length === 0 && (
                    <tr>
                      <td align="center" colSpan={9}>
                        No Events Available.
                      </td>
                    </tr>
                  )}
                  {!listLoading &&
                    Object.entries(events).length > 0 &&
                    events.map(cause => _renderItems(cause))}
                </tbody>
              </table>

              {pagging && (
                <div className="pagging-wrapper">
                  {pagging.current != '' &&
                    pagging.pages !== 0 &&
                    pagging.totalItems > 0 && (
                      <div className="pagging-text">
                        <p>{`Page : ${pagging.current} of ${pagging.pages} - Total ${pagging.totalItems} Items`}</p>
                      </div>
                    )}

                  <div className="pagging-btns">
                    {pagging.previous && (
                      <span
                        className="btn btn-small"
                        onClick={() => fetchExpenses(pagging.previous)}>
                        Previous
                      </span>
                    )}
                    {pagging.next && (
                      <span
                        className="btn btn-small next-btn"
                        onClick={() => fetchExpenses(pagging.next)}>
                        Next
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {add_event_modal && (
        <AddEvent
          show={add_event_modal}
          closeModal={() => toggleModal('add_event_modal', false)}
        />
      )}

      {edit_event_modal && (
        <EditEvent
          isAdmin={true}
          show={edit_event_modal}
          event={edit_event}
          closeModal={() => toggleModal('edit_event_modal', false)}
        />
      )}
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  eventsList: state.eventsList,
  usStates: state.usStates,
});

export default connect(mapStateProps, {getEventsList, removeEvent})(EventsList);
