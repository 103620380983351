import React, {useState} from 'react';

import {SettingIntegration} from '../../Components';
import TermsPages from './PagesContent';
import Notifications from './Notifications';
import {Tab, Tabs} from 'react-bootstrap';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('integration');

  return (
    <div className="admin-body">
      <div className="container">
        <div className="body-card">
          <div className="heading">
            <h2>Settings</h2>
          </div>

          <div className="body-content">
            <Tabs
              defaultActiveKey={activeTab}
              id="uncontrolled-tab-example"
              onSelect={k => setActiveTab(k)}>
              <Tab
                eventKey="integration"
                title="Integration"
                tabAttrs={{link: '#integration'}}>
                <div className="mt-4">
                  <SettingIntegration />
                </div>
              </Tab>
              <Tab eventKey="terms" title="Terms" tabAttrs={{link: '#terms'}}>
                <div className="mt-4">
                  <TermsPages />
                </div>
              </Tab>
              <Tab
                eventKey="notifications"
                title="Notifications"
                tabAttrs={{link: '#notifications'}}>
                <div className="mt-4">
                  <Notifications />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
