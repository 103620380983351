export const appName = 'BUILD Foundation';
const environment = 'Live'; // Local | Dev | Live

const URLs = {
  Local: 'http://localhost/buildFoundationGit/admin',
  Dev: 'https://build-foundation.bleedingbulbtest.com/api',
  Live: 'https://build-foundation.org/api',
};

const site_url = {
  Local: `http://localhost:3000`,
  Dev: 'https://build-foundation.bleedingbulbtest.com',
  Live: 'https://build-foundation.org/',
};

export const SITE_URL = site_url[environment];
export const BASE_URL = URLs[environment];
export const API_URL = `${BASE_URL}`;

export const CARDS_LIMIT = 5;
export const ALLOWED_COUNTRY = ['in', 'us'];
