import React, {Component} from 'react';

import {connect} from 'react-redux';
import {Alert, Button, Col, Form, Row, Tab, Tabs} from 'react-bootstrap';

import {
  saveSettings,
  getSettings,
  createAuthClient,
  verifyAuthCode,
} from '../../Store/Actions/CommanAction';
import {appendFormData, updateUrl} from '../../Utils/Helpers';
import {API_URL, SITE_URL} from '../../Utils/env';
import API from '../../Utils/API';

class SettingIntegration extends Component {
  state = {
    data: {
      admin_email: '',
      app_name: '',
      app_description: '',
      twilio_token: '',
      twilio_sid: '',
      twilio_serviceID: '',
      youtube_client_id: '',
      youtube_client_secret: '',
      youtubeAuthToken: null,
      stripe_public_key: '',
      stripe_secret_key: '',
      stripe_webhook_key: '',
      global_fund: '',
      emergency_fund: '',
      most_voted: '',
    },
    donationData: {
      donation_file: '',
      expenses_file: '',
      distribute_amount: false,
    },
    errors: {},
    is_saving: false,
    is_importing: false,
    is_reseting: false,
    alertData: {
      show: false,
      type: '',
      message: '',
    },
    authLoading: false,
    isAuthentication: false,
    activeTab: 'general',
  };

  UNSAFE_componentWillMount = () => {
    this.getSettings();

    const {settings} = this.props;

    const code = new URLSearchParams(window.location.search).get('code');
    const scope = new URLSearchParams(window.location.search).get('scope');

    if (code) {
      this.verifyAuthCode(code, scope);
    }

    const isAuthentication =
      settings.youtubeAuthToken && settings.youtubeAuthToken !== null
        ? true
        : false;

    const newState = {
      data: settings,
      isAuthentication,
    };

    if (window.location.hash) {
      const hash = window.location.hash.substring(1);

      newState.activeTab = hash;
    }

    this.setState(newState);
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {settings} = props;

    const isAuthentication =
      settings.youtubeAuthToken && settings.youtubeAuthToken !== null
        ? true
        : false;

    settings.youtubeAuthToken =
      settings.youtubeAuthToken &&
      typeof settings.youtubeAuthToken === 'string' &&
      settings.youtubeAuthToken !== ''
        ? JSON.parse(settings.youtubeAuthToken)
        : null;

    this.setState({
      data: settings,
      isAuthentication,
    });
  };

  setUrlHash = activeTab => {
    this.setState({
      activeTab,
    });

    // const newUrl = `/admin/settings/#${activeTab}`;
    // updateUrl(newUrl, this.props.history);
  };

  onChange = e => {
    const data = {...this.state.data};

    if (e.target.type === 'file') {
      data[e.target.name] = e.target.files[0];
    } else if (e.target.name === 'enable_youtube') {
      data[e.target.name] = e.target.checked ? 'Yes' : '';
    } else {
      data[e.target.name] = e.target.value;
    }

    this.setState({
      data,
    });
  };

  hideErrMsg = () => {
    this.setState({
      alertData: {
        show: false,
        type: '',
        message: '',
      },
    });
  };

  getSettings = () => {
    this.props
      .getSettings()
      .then(res => {})
      .catch(err => {
        this.setState({
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  saveSettings = () => {
    const {data, is_saving} = this.state;

    if (!is_saving) {
      const formData = appendFormData(data);

      this.props.saveSettings(formData).then(res => {
        this.setState({
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
        });
      });
    }
  };

  authenticateGoogle = () => {
    const {data, authLoading} = this.state;
    const {youtube_client_id, youtube_client_secret} = data;

    if (!authLoading && youtube_client_id && youtube_client_secret) {
      this.setState({authLoading: true});

      const redirect = `${SITE_URL}/admin/settings`;

      console.log('Redirect:', redirect);

      this.props
        .createAuthClient(redirect)
        .then(res => {
          if (res.type === 'success') {
            const {authUrl} = res;
            console.log('Res :', res);
            window.location.href = authUrl;
          } else {
            this.setState({
              authLoading: false,
              alertData: {
                show: true,
                type: res.type === 'error' ? 'danger' : res.type,
                message: res.message,
              },
            });
          }
        })
        .catch(err => {
          this.setState({
            authLoading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  verifyAuthCode = (code, scope) => {
    const {authLoading} = this.state;

    if (!authLoading) {
      this.setState({authLoading: true});

      const redirect = `${SITE_URL}/admin/settings`;

      const formData = new FormData();
      formData.append('code', code);
      formData.append('scope', scope);
      formData.append('redirect', redirect);

      this.props
        .verifyAuthCode(formData)
        .then(res => {
          const {settings} = res;
          console.log(res);

          if (res.type === 'success') {
            // updateUrl('/admin/settings/#youtube', this.props.history);
          }

          this.setState({
            authLoading: false,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : res.type,
              message: res.message,
            },
            data: settings,
          });
        })
        .catch(err => {
          // updateUrl('/admin/settings/#youtubes', this.props.history);

          this.setState({
            authLoading: false,
            alertData: {
              show: true,
              type: 'danger',
              message: err.message,
            },
          });
        });
    }
  };

  resetAuthToken = () => {
    this.setState({authLoading: true});
    API.resetAuthToken()
      .then(res => {
        const {settings} = res;
        this.setState({
          authLoading: false,
          alertData: {
            show: true,
            type: res.type === 'error' ? 'danger' : res.type,
            message: res.message,
          },
          data: settings,
        });
      })
      .catch(err => {
        this.setState({
          authLoading: false,
          alertData: {
            show: true,
            type: 'danger',
            message: err.message,
          },
        });
      });
  };

  changeImportFile = e => {
    if (e.target.name === 'distribute_amount') {
      this.setState({
        donationData: {
          ...this.state.donationData,
          [e.target.name]: e.target.checked ? 'Yes' : 'No',
        },
      });
    } else {
      this.setState({
        donationData: {
          ...this.state.donationData,
          [e.target.name]: e.target.files[0],
        },
      });
    }
  };

  resetDonationData = () => {
    const {is_reseting} = this.state;

    if (!is_reseting) {
      this.setState({is_reseting: true});
      const result = window.confirm(
        'Are you sure? You want to all remove Donation data. After execution you may not able to get this data.',
      );

      if (result) {
        API.resetDonationData()
          .then(res => {
            this.setState({
              is_reseting: false,
              alertData: {
                show: true,
                type: res.type === 'error' ? 'danger' : 'success',
                message: res.message,
              },
            });
          })
          .catch(err => {
            this.setState({is_reseting: false});
          });
      }
    }
  };

  importData = () => {
    const {is_importing, donationData} = this.state;

    if (!is_importing) {
      const formData = appendFormData(donationData);
      this.setState({
        is_importing: true,
      });

      API.importData(formData)
        .then(res => {
          this.setState({
            is_importing: false,
            alertData: {
              show: true,
              type: res.type === 'error' ? 'danger' : 'success',
              message: res.message,
            },
          });
        })
        .catch(err => {
          console.log('Err :', err);
        });
    }
  };

  render() {
    const {
      data,
      errors,
      is_saving,
      is_importing,
      alertData,
      donationData,
      authLoading,
      activeTab,
    } = this.state;

    return (
      <>
        {alertData && alertData.show && (
          <div className="alerts p-0">
            <Alert
              variant={alertData.type}
              onClose={() => this.hideErrMsg()}
              dismissible>
              {alertData.message}
            </Alert>
          </div>
        )}

        <div className="verticle-tabs mt-3 mb-2">
          <Tabs
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            onSelect={k => this.setUrlHash(k)}>
            <Tab
              eventKey="general"
              title="App Info"
              tabAttrs={{link: '#general'}}>
              <div className="tab-heading">
                <h2>App Info</h2>

                {/* <span className="btn btn-primary btn-small" onClick={() => {}}>
                                    {!is_saving && "Save"}
                                    {is_saving && <i className="fas fa-spinner fa-spin" />}
                                </span> */}
              </div>

              <Row>
                <Col md={6}>
                  <Form.Group className="form-group" controlId="app_name">
                    <label>App Name</label>
                    <Form.Control
                      name="app_name"
                      type="text"
                      placeholder=""
                      value={data.app_name}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.app_name && (
                      <span className="errorMsg">{errors.app_name}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="admin_email">
                    <label>Admin Email</label>
                    <Form.Control
                      name="admin_email"
                      type="text"
                      placeholder=""
                      value={data.admin_email}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.admin_email && (
                      <span className="errorMsg">{errors.admin_email}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="form-group"
                    controlId="app_description">
                    <label>App Description</label>
                    <Form.Control
                      name="app_description"
                      as="textarea"
                      rows={5}
                      value={data.app_description}
                      onChange={e => this.onChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.saveSettings()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>

            <Tab eventKey="twilio-sms" title="Twilio SMS">
              <div className="tab-heading">
                <h2>Twilio Auth</h2>
              </div>

              <Row>
                <Col md={6}>
                  <Form.Group className="form-group" controlId="twilio_token">
                    <label>Token</label>
                    <Form.Control
                      name="twilio_token"
                      type="text"
                      placeholder=""
                      value={data.twilio_token}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.twilio_token && (
                      <span className="errorMsg">{errors.twilio_token}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="twilio_sid">
                    <label>SID</label>
                    <Form.Control
                      name="twilio_sid"
                      type="text"
                      placeholder=""
                      value={data.twilio_sid}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.twilio_sid && (
                      <span className="errorMsg">{errors.twilio_sid}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="twilio_serviceID">
                    <label>Service ID</label>
                    <Form.Control
                      name="twilio_serviceID"
                      type="text"
                      placeholder=""
                      value={data.twilio_serviceID}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.twilio_serviceID && (
                      <span className="errorMsg">
                        {errors.twilio_serviceID}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.saveSettings()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>

            <Tab eventKey="youtube" title="Youtube API">
              <div className="tab-heading">
                <h2>Youtube API key</h2>
              </div>

              <Row>
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="youtube_client_id">
                    <label>Enable Youtube</label>
                    <Form.Check
                      name="enable_youtube"
                      type="checkbox"
                      id="custom-switch"
                      label="Enable Youtube Upload"
                      checked={data.enable_youtube === 'Yes' ? true : false}
                      value="Yes"
                      onChange={e => this.onChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="youtube_client_id">
                    <label>Client ID</label>
                    <Form.Control
                      name="youtube_client_id"
                      type="text"
                      placeholder=""
                      value={data.youtube_client_id}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.youtube_client_id && (
                      <span className="errorMsg">
                        {errors.youtube_client_id}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="form-group"
                    controlId="youtube_client_secret">
                    <label>Client Secret</label>
                    <Form.Control
                      name="youtube_client_secret"
                      type="text"
                      placeholder=""
                      value={data.youtube_client_secret}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.youtube_client_secret && (
                      <span className="errorMsg">
                        {errors.youtube_client_secret}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {data.youtube_client_id &&
                data.youtube_client_secret &&
                (data.youtubeAuthToken === null ||
                  data.youtubeAuthToken === '') && (
                  <Row>
                    <Col className="text-right">
                      <Button
                        className="btn btn-small btn-outline"
                        onClick={() => this.authenticateGoogle()}>
                        {authLoading && (
                          <i className="fas fa-spinner fa-spin" />
                        )}
                        {!authLoading && 'Add AuthToken'}
                      </Button>
                    </Col>
                  </Row>
                )}

              {data.youtube_client_id &&
                data.youtube_client_secret &&
                data.youtubeAuthToken && (
                  <Row>
                    <Col className="text-right">
                      <Button
                        className="btn btn-small btn-outline"
                        onClick={() => this.resetAuthToken()}>
                        {authLoading && (
                          <i className="fas fa-spinner fa-spin" />
                        )}
                        {!authLoading && 'Reset AuthToken'}
                      </Button>
                    </Col>
                  </Row>
                )}

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.saveSettings()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>

            <Tab eventKey="stripe-info" title="Stripe">
              <div className="tab-heading">
                <h2>Stripe Credentials</h2>
              </div>

              <Row>
                <Col md={6}>
                  <Form.Group
                    className="form-group"
                    controlId="stripe_public_key">
                    <label>Public Key</label>
                    <Form.Control
                      name="stripe_public_key"
                      type="text"
                      placeholder=""
                      value={data.stripe_public_key}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.stripe_public_key && (
                      <span className="errorMsg">
                        {errors.stripe_public_key}
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="form-group"
                    controlId="stripe_secret_key">
                    <label>Secret Key</label>
                    <Form.Control
                      name="stripe_secret_key"
                      type="text"
                      placeholder=""
                      value={data.stripe_secret_key}
                      onChange={e => this.onChange(e)}
                    />
                    {errors.stripe_secret_key && (
                      <span className="errorMsg">
                        {errors.stripe_secret_key}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    className="form-group"
                    controlId="stripe_webhook_key">
                    <label>Webhook Key</label>
                    <Form.Control
                      name="stripe_webhook_key"
                      type="text"
                      placeholder=""
                      value={data.stripe_webhook_key}
                      onChange={e => this.onChange(e)}
                    />
                    <label className="mt-2 mb-0">
                      Webhook URL : {API_URL}/stripe-webhook
                    </label>
                    {errors.stripe_webhook_key && (
                      <span className="errorMsg">
                        {errors.stripe_webhook_key}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.saveSettings()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>

            <Tab eventKey="fund-sharing" title="Fund Sharing">
              <div className="tab-heading">
                <h2>Sharing Percentages</h2>
              </div>

              <Row>
                <Col md={6}>
                  <Form.Group className="form-group" controlId="global_fund">
                    <label>Global Fund (% of Total collected Amount)</label>
                    <div className="inputWithIcon right-side">
                      <i className="fas fa-percent" />
                      <Form.Control
                        name="global_fund"
                        type="text"
                        placeholder="Percentage (%)"
                        value={data.global_fund}
                        onChange={e => this.onChange(e)}
                      />
                    </div>
                    {errors.global_fund && (
                      <span className="errorMsg">{errors.global_fund}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="emergency_fund">
                    <label>Emergency Fund (% of Global Fund)</label>
                    <div className="inputWithIcon right-side">
                      <i className="fas fa-percent" />
                      <Form.Control
                        name="emergency_fund"
                        type="text"
                        placeholder="Percentage (%)"
                        value={data.emergency_fund}
                        onChange={e => this.onChange(e)}
                      />
                    </div>
                    {errors.emergency_fund && (
                      <span className="errorMsg">{errors.emergency_fund}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="form-group" controlId="most_voted">
                    <label>Most Voted</label>
                    <div className="inputWithIcon right-side">
                      <i className="fas fa-percent" />
                      <Form.Control
                        name="most_voted"
                        type="text"
                        placeholder="Percentage (%)"
                        value={data.most_voted}
                        onChange={e => this.onChange(e)}
                      />
                    </div>
                    {errors.most_voted && (
                      <span className="errorMsg">{errors.most_voted}</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.saveSettings()}>
                  {!is_saving && 'Save'}
                  {is_saving && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>

            <Tab
              eventKey="import-donations"
              title="Import Nominations / Expenses">
              <div className="tab-heading">
                <h2>Import Data</h2>
                <span
                  className="btn btn-primary"
                  onClick={() => this.resetDonationData()}>
                  Reset Donation Data
                </span>
              </div>

              <Row>
                <Col md={12}>
                  <Form.Group
                    className="form-group"
                    controlId="import-donation">
                    <Form.Check
                      custom
                      id="distribute_amount"
                      name="distribute_amount"
                      type="checkbox"
                      label="Run Distribution"
                      checked={
                        donationData.distribute_amount === 'Yes' ? true : false
                      }
                      onChange={e => this.changeImportFile(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group
                    className="form-group"
                    controlId="import-donation">
                    <label>Nominations csv</label>
                    <Form.Control
                      name="donation_file"
                      type="file"
                      onChange={e => this.changeImportFile(e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="form-group"
                    controlId="import-expenses">
                    <label>Expenses csv</label>
                    <Form.Control
                      name="expenses_file"
                      type="file"
                      onChange={e => this.changeImportFile(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="tab-footer">
                <span
                  className="btn btn-primary btn-small"
                  onClick={() => this.importData()}>
                  {!is_importing && 'Import'}
                  {is_importing && <i className="fas fa-spinner fa-spin" />}
                </span>
              </div>
            </Tab>

            {/* <Tab eventKey="notifications" title="Notifications">
                  <div className="tab-heading">
                      <h2>Notifications</h2>
                  </div>

                  <div className="tab-footer">
                      <span
                          className="btn btn-primary btn-small"
                          onClick={() => this.saveSettings()}
                      >
                          {!is_saving && "Save"}
                          {is_saving && <i className="fas fa-spinner fa-spin" />}
                      </span>
                  </div>
              </Tab> */}
          </Tabs>
        </div>
      </>
    );
  }
}

const mapStateProps = state => ({
  settings: state.settings,
});

export default connect(mapStateProps, {
  saveSettings,
  getSettings,
  createAuthClient,
  verifyAuthCode,
})(SettingIntegration);
