import React, {useEffect, useState} from 'react';
import CauseListItem from './CauseListItem';
import {Button} from 'react-bootstrap';
import {Scrollbars} from 'react-custom-scrollbars';

const MyNominations = ({items, updateCauseVote, showDetails}) => {
  const [causes, setCauses] = useState([]);

  const updateVotes = $causeId => {
    const voteData = new FormData();
    voteData.append('cause_id', $causeId);

    updateCauseVote(voteData)
      .then(res => {
        console.log('Res', res);
      })
      .catch(err => {
        console.log('Err :', err);
      });
  };

  useEffect(() => {
    setCauses(items);
  }, [items]);

  return (
    <div className="pannel-inner-content">
      <Scrollbars style={{height: '85vh'}}>
        <div className="list-wrapper">
          {Object.entries(causes).length === 0 && (
            <div className="my-impact-headings appretiation-text">
              <p>No nominations have been submitted this month.</p>
              <Button
                className="btn btn-primary btn-small add-new-btn"
                onClick={() => {}}>
                Add Nomination
              </Button>
            </div>
          )}

          {Object.entries(causes).length > 0 &&
            causes.map((item, index) => (
              <CauseListItem
                key={`cause_item-${item.id}`}
                item={item}
                updateCauseVote={id => updateVotes(id)}
                showDetails={id => showDetails(id)}
              />
            ))}
        </div>
      </Scrollbars>
    </div>
  );
};

export default MyNominations;
